import { createStore } from 'vuex'
import gameStore from './gameStore';
import vpn from './vpn';
import userHubStore from './userHubStore';
import text from './text';
import common from '../utils/common';

export default createStore({
  //定義所需要的狀態
  //數據共享，包括一般組件和路由組件每個組件都能使用
  state: {
    appErrorLogShowTag: false,//是否显示错误log在页面上
    appErrorLog: [], //错误log
    canLogin_v2: true,
    needVerityCode: true,
    apiReadyTag: false,
    userInfoReadyTag: false,
    cdnUrl: '',//cdn的动态地址
    apiUrl: null, // 採用的接口地址
    apiUrlArray: null, //接口地址數組
    fastUrlIndex: null, //採用的接口地址在數組中的索引
    lang: "en", //語言環境：en | zh_cn
    sysConfig: null, //系統配置
    uid: null,//登錄後取得的uid以及未登錄僅連接錢包綁定的uid
    loginBtnClickTag: false, //標誌登錄按鈕是用戶手動點擊，還是代碼調用
    menuIndex: 0,
    userAccount: '',//錢包地址或賬號的略稱
    userGameAccount: '', //錢包地址或賬號的全稱
    walletLoginName: '', //錢包方式登錄，有綁定賬號，則為錢包綁定賬戶的手機號或郵箱
    email: null,
    phone_num: null,
    showPageTop: true,
    showPageTopNav: true,
    showLoadingTag: 0,
    userInfo: null,
    loginRes: null,
    loginGameType: null,//賬號密碼方式為1；metaMask為2
    bindState: null,//是否賬號和錢包關聯。1為已關聯
    chain_tp: 2,//1:wax，默認；2:bsc
    confirm: {
      showConfirmTag: false,
      type: 'info',
      title: 'Confirm',
      text: 'confirm text',
      btnTxt: 'OK',
      showCancleBtn: true,
      confirmFn: function (r) {
        store.commit("set_showConfirmTag", false);
      },
      cancelFn: function (r) {
        store.commit("set_showConfirmTag", false);
      }
    },
    key: null,//錢包解密服務器key後的值 
    MarketUserLevelLimit: null, //交易中心用戶等級限制
    MoralisUser: null, //用Moralis方式登录钱包返回的用户信息
  },
  //同步修改stata，mutations裡只能放方法，不能異步、定時操作
  //mutations的每個方法有兩個參數，第1個是state，第2個是需要修改的值
  //mutations的方法使用時，state參數可以不傳
  mutations: {
    set_MoralisUser(state, playlod) {
      state.MoralisUser = playlod;
    },
    set_appErrorLogShowTag(state, playlod) {
      state.appErrorLogShowTag = playlod;
    },
    set_appErrorLog(state, playlod) {
      state.appErrorLog.push(`------${new Date()}------`);
      state.appErrorLog.push(playlod);
    },
    set_canLogin_v2(state, playlod) {
      state.canLogin_v2 = playlod;
    },
    set_apiReadyTag(state, payload) {
      state.apiReadyTag = payload;
    },
    set_userInfoReadyTag(state, payload) {
      state.userInfoReadyTag = payload;
    },
    set_cdnUrl(state, payload) {
      state.cdnUrl = payload;
    },
    set_apiUrl(state, payload) {
      state.apiUrl = payload;
    },
    set_apiUrlArray(state, payload) {
      state.apiUrlArray = payload;
    },
    set_fastUrlIndex(state, payload) {
      state.fastUrlIndex = payload;
    },
    set_lang(state, payload) {
      state.lang = payload;
    },
    set_sysConfig(state, payload) {
      state.sysConfig = payload;
    },
    set_uid(state, payload) {
      state.uid = payload;
    },
    set_loginBtnClickTag(state, payload) {
      state.loginBtnClickTag = payload;
    },
    showLoading(state, payload) {
      state.showLoadingTag += payload;
    },
    set_menuIndex(state, payload) {
      state.menuIndex = payload;
    },
    set_userAccount(state, payload) {
      state.userAccount = payload;
    },
    set_userGameAccount(state, payload) {
      state.userGameAccount = payload;
    },
    set_walletLoginName(state, payload) {
      state.walletLoginName = payload;
    },
    set_email(state, payload) {
      state.email = payload;
    },
    set_phone_num(state, payload) {
      state.phone_num = payload;
    },
    set_showPageTop(state, payload) {
      state.showPageTop = payload;
    },
    set_showPageTopNav(state, payload) {
      state.showPageTop = true;
      state.showPageTopNav = payload;
    },
    set_userInfo(state, payload) {
      state.userInfo = payload;
    },
    set_loginRes(state, payload) {
      state.loginRes = payload;
    },
    set_loginGameType(state, d) {
      state.loginGameType = d;
    },
    set_bindState(state, d) {
      state.bindState = d;
    },
    set_chain_tp(state, d) {
      state.chain_tp = d;
    },
    set_showConfirmTag(state, payload) {
      state.confirm.showConfirmTag = payload;
    },
    set_confirm(state, payload) {
      state.confirm = payload;
    },
    set_key(state, payload) {
      state.key = payload;
    },
    set_MarketUserLevelLimit(state, payload) {
      state.MarketUserLevelLimit = payload;
    },
  },
  //action異步提交mutation
  //第1個參數是store對象，第2個參數是修改的值
  //commit用來提交mutation,調用mutation的方法
  actions: {

  },
  // 模塊化
  modules: {
    gameStore, userHubStore, vpn, text
  }
})
