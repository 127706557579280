<template>
  <Teleport to="#app">
  <div class="ui_confirm disselect" v-if="showTag">
    <div class="contain">
      <div class="title">
        <i class="info" v-if="type == 'info'">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
            <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
            <circle cx="13.5" cy="7.5" r="1.5" fill="white" />
          </svg></i>
        <i class="info" v-else-if="type == 'success'">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
            <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
            <circle cx="13.5" cy="7.5" r="1.5" fill="white" />
          </svg></i>
        <i class="info" v-else-if="type == 'error'">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#F93A20" />
            <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="#F93A20" />
            <circle cx="13.5" cy="7.5" r="1.5" fill="#F93A20" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 9.73684L9.73684 8L13.5 11.7632L17.2632 8L19 9.73684L15.2368 13.5L19 17.2632L17.2632 19L13.5 15.2368L9.73684 19L8 17.2632L11.7632 13.5L8 9.73684Z"
              fill="white" />
          </svg>
        </i>
        <i class="info" v-else-if="type == 'warn'">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#F7B500" />
          </svg>
        </i>
        <i class="info" v-else-if="type == 'null'"></i>
        <i class="info" v-else><svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
          <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
          <circle cx="13.5" cy="7.5" r="1.5" fill="white" />
        </svg></i>
        {{ title }}
      </div>
      <div class="txt" id="confirm_txt" v-html="text"></div>
      <div class="btn">
        <span v-if="showCancleBtn" @click="cancel">{{ langTxt[lang].confirm.btnTxt[0] }}</span>
        <span @click="confirm" class="confirm">{{ btnTxt }}</span>
      </div>
      <span class="close" @click="cancel"></span>
    </div>
  </div></Teleport>
</template>
<style lang="scss" scoped>
.ui_confirm {
  position: fixed;
  z-index: 8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .contain {
    width: 4.5rem;
    min-height: 2.27rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.3rem 0;
    position: relative;

    .title {
      font-weight: bold;
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      color: #333;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 0 0.3rem;
      padding-bottom: 0.1rem;
      margin-bottom: 0.2rem;

      i {
        margin-right: 0.1rem;

        svg {
          widows: 0.27rem;
          height: 0.27rem;
          vertical-align: middle;
        }
      }
    }

    .txt {
      font-size: 0.18rem;
      color: #5c5c5c;
      font-weight: 500;
      line-height: 0.25rem;
      padding: 0 0.3rem;
      min-height: 0.76rem;
      margin-bottom: 0.17rem;
      text-transform: capitalize;

    }

    .btn {
      padding: 0 0.3rem;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        display: inline-block;
        width: 0.86rem;
        height: 0.38rem;
        background-color: #bdbdbd;
        border-radius: 5px;
        font-size: 0.18rem;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #828282;
        }

        &.confirm {
          background: var(--linearGradientRed);
          margin-left: 0.2rem;
          color: #fff;

          &:hover {
            background: var(--linearGradientRedHover);
          }
        }
      }
    }
  }
}

.close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
  background: url("https://cdn.blockwar.io/web_assets/img/ico_close.png") no-repeat center center / 100% auto;

  &:hover {
    background-image: url("https://cdn.blockwar.io/web_assets/img/ico_close_hover.png");
  }
}
</style>
<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref
} from "vue";
import {
  useStore
} from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_confirm",
  props: {},
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    // let wobble = ref(false);
    let showTag = computed(() => {
      return store.state.confirm.showConfirmTag;
    });
    let type = computed(() => {
      return store.state.confirm.type;
    });
    let title = computed(() => {
      return store.state.confirm.title;
    });
    let text = computed(() => {
      return store.state.confirm.text;
    });
    let btnTxt = computed(() => {
      return store.state.confirm.btnTxt;
    });
    let showCancleBtn = computed(() => {
      return store.state.confirm.showCancleBtn;
    });
    const confirm = () => {
      if (store.state.confirm.confirmFn)
      store.state.confirm.confirmFn();
      store.commit("set_showConfirmTag", false);
    };
    const cancel = () => {
      if (store.state.confirm.cancelFn)
      store.state.confirm.cancelFn();
      store.commit("set_showConfirmTag", false);
    };
    onMounted(() => {
      // wobble.value = true;
    });
    let lang = computed(() => {
      return store.state.lang;
    });
    let langTxt = store.state.text;

    return {
      langTxt,
      lang,
      confirm,
      cancel,
      showTag,
      type,
      title,
      text,
      btnTxt,
      showCancleBtn,
      // wobble,
    };
  },
});
</script>