<template>
  <div class="introduction">
    <div class="layout">
      <div class="content">
        <h2 class="mortina h2">{{langTxt[lang].index.intro[0]}}</h2>
        <p>{{langTxt[lang].index.intro[1]}}</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.introduction {
    height: 11.48rem;

    .layout {
      position: relative;
      text-align: center;
      color: #fff;
      height: 100%;
      background: url("https://cdn.blockwar.io/web_assets/img/introduction_bg2.png") no-repeat center center;
      background-size: 100% auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .content {
        position: absolute;
        width: 4.5rem;
        top: 5rem;
        left: 9.7rem;
        text-align: left;
      }

      h2 {
        margin-bottom: 0.3rem;
        font-size: 0.32rem;
      }

      .h2 {
        &::before {
          left: 0;
        }
      }

      p {
        width: 3.7rem;
        line-height: 0.22rem;
        font-size: 0.18rem;
      }
    }
  }
@media screen and (max-width:1200px){
  .introduction{
    .layout{ 
      .content{
        left: auto;
        right: 0.2rem
      }
    }
  }
}
</style>
<script>
  import {
    defineComponent,
    computed
  } from "vue";
  import {
    useStore
  } from "vuex";
  // import { useRouter } from "vue-router";

  import ui_btn_1 from "../ui/ui_btn_1.vue";

  export default defineComponent({
    name: "introduction",
    props: {},
    components: {
      ui_btn_1
    },
    setup(props, ctx) {
      // let router = useRouter();
      let store = useStore();

      let lang = computed(() => {
        return store.state.lang;
      });
      let langTxt = store.state.text;

      return {
        langTxt,
        lang,
      };
    },
  });
</script>