<template>
  <div class="userlogin">
    <h1 class="mortina" v-if="lang == 'en'">
      set<br />
      new password
    </h1>
    <h1 v-else>創建新密碼</h1>
    <ui_input
      v-model:val="formVal.telmail"
      :class="{ err: checkErr.check_te_err }"
      :placeholder="lang == 'en' ? 'E-mail' : '郵箱地址'"
      @keyup.enter="resetPW"
    ></ui_input>
    <div class="errtxt" v-if="checkErr.check_te_err">{{ errTxt.tErrTxt }}</div>
    <div class="vcbox">
      <ui_input
        class="vc"
        v-model:val="formVal.verify_code"
        :class="{ err: checkErr.check_vc_err }"
        :width="'90%'"
        :placeholder="(lang = 'en' ? 'VERIFY CODE' : '驗證碼')"
        @keyup.enter="resetPW"
      ></ui_input>
      <ui_btn_1
        :width="'3rem'"
        @click="sendResetPwSMS"
        :disclick="!formVal.telmail || checkErr.check_te_err"
        >{{ sendSmsBtnTxt }}</ui_btn_1
      >
    </div>
    <div class="errtxt" v-if="checkErr.check_vc_err">
      {{ errTxt.vcErrTxt }}
    </div>
    <ui_input
      :type="'password'"
      v-model:val="formVal.pw1"
      :placeholder="lang == 'en' ? 'PASSWORD' : '密碼'"
      @keyup.enter="resetPW"
    ></ui_input>
    <ui_input
      :type="'password'"
      v-model:val="formVal.pw2"
      :placeholder="lang == 'en' ? 'CONFIRM PASSWORD' : '確認密碼'"
      @keyup.enter="resetPW"
    ></ui_input>
    <div class="errtxt" v-if="checkErr.check_pw_err">
      {{ errTxt.pwErrTxt }}
    </div>
    <ui_btn_1 :width="'100%'" :disclick="!canResetTag" @click="resetPW">{{
      lang == "en" ? "submit" : "提交"
    }}</ui_btn_1>
    <div>
      <span class="create" @click="showLoginUI">{{
        lang == "en" ? "Back to login" : "回到登錄"
      }}</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.switch_forgot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
  span {
    cursor: pointer;
    &:hover {
      background: var(--linearGradientRed);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}
</style>
<script src="./userResetPW.js"></script>
