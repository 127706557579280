<template>
  <div class="userreg" v-if="ui_model == 1">
    <div class="login">
      <h1 class="mortina">{{ lang == "en" ? "Sign Up" : "創建賬號" }}</h1>
      <p>
        {{ lang == "en" ? "Welcome To Sign Up Block WAR." : "歡迎創建 Block War 賬號" }}
      </p>
    </div>
    <ui_input
      @blur="telmailBlur"
      @focus="telmailFocus"
      v-model:val="telmail"
      :placeholder="lang == 'en' ? 'E-Mail' : '郵箱地址'"
      :err="checkTelMailErrTag"
      @keyup.enter="reg"
    ></ui_input>
    <div
      class="errtxtbox"
      :style="{
        width: checkTelMailErrTag ? '100%' : 0,
        height: checkTelMailErrTag ? 'auto' : 0,
      }"
    >
      <div
        class="errtxt"
        v-if="checkTelMailErrTag"
        :style="{ opacity: checkTelMailErrTag ? 1 : 0 }"
      >
        {{ lang == "en" ? "Please enter your email" : "請輸入郵箱" }}
      </div>
    </div>

    <ui_input
      :type="'password'"
      v-model:val="pw1"
      :placeholder="lang == 'en' ? 'Password' : '密碼'"
      @keyup.enter="reg"
      :err="pwDiffTag || pwLengTag"
    ></ui_input>
    <ui_input
      :type="'password'"
      v-model:val="pw2"
      :placeholder="lang == 'en' ? langTxt[lang].register[10] : '確認密碼'"
      @keyup.enter="reg"
      :err="pw2 && (pwLengTag || pwDiffTag || (pw1 && checkPW_special_res))"
    ></ui_input>
    <div
      class="errtxtbox"
      :style="{
        width: pwLengTag || pwDiffTag || (pw1 && checkPW_special_res) ? '100%' : 0,
        height: pwLengTag || pwDiffTag || (pw1 && checkPW_special_res) ? 'auto' : 0,
      }"
    >
      <div class="errtxt" v-if="pwDiffTag">
        {{ lang == "en" ? "The two inputs are different" : "两次输入的信息不一致" }}
      </div>
      <div class="errtxt" v-else-if="pwLengTag">
        {{
          lang == "en" ? "The Length Must Be Between 6 And 12" : "字符數量需要在6至12之間"
        }}
      </div>
      <div class="errtxt" v-else-if="pw1 && checkPW_special_res">
        {{ specialErrTxt }}
      </div>
    </div>

    <div class="vcbox">
      <getVRC :codeType="2" @sendPhone="sendPhone" @sendVRC="sendVRC" />
    </div>
    <div :data-ui_model="ui_model">
      <ui_btn_1 :width="'100%'" :disclick="!regTag" @click="reg">{{
        lang == "en" ? "SIGN UP" : "創建賬號"
      }}</ui_btn_1>
      <div class="to_login">
        {{ lang == "en" ? "Have an account?" : "已有賬號?" }}
        <span class="create" @click="showLoginUI">{{
          lang == "en" ? "Login" : "現在去登錄"
        }}</span>
      </div>
    </div>
  </div>
  <!-- 綁定錢包 -->
  <div class="userreg" v-if="ui_model == 4">
    <div class="login">
      <h1 class="mortina">Supplementary account password</h1>
      <p>
        You can choose one more login method, which will make your game experience better
      </p>
    </div>
    <ui_input
      @blur="telmailBlur"
      @focus="telmailFocus"
      v-model:val="telmail"
      :placeholder="'E-Mail'"
      :err="checkTelMailErrTag"
      @keyup.enter="completeWallet"
    ></ui_input>
    <div
      class="errtxtbox"
      :style="{
        width: checkTelMailErrTag ? '100%' : 0,
        height: checkTelMailErrTag ? 'auto' : 0,
      }"
    >
      <div
        class="errtxt"
        v-if="checkTelMailErrTag"
        :style="{ opacity: checkTelMailErrTag ? 1 : 0 }"
      >
        {{ lang == "en" ? "Please enter your email" : "請輸入郵箱" }}
      </div>
    </div>

    <!-- <div class="vcbox" v-if="needVerityCode">
      <ui_input class="vc" v-model:val="verify_code" :width="'1.5rem'" :placeholder="'VERIFY CODE'"
        @keyup.enter="completeWallet"></ui_input>
      <ui_btn_1 :width="'2rem'" :disclick="sendSmsBtnTxt != btnTxt" @click="sendRegSMS">{{ sendSmsBtnTxt }}</ui_btn_1>
    </div> -->
    <ui_input
      :type="'password'"
      v-model:val="pw1"
      :placeholder="'Password'"
      @keyup.enter="completeWallet"
      :err="pwDiffTag || pwLengTag"
    ></ui_input>
    <ui_input
      :type="'password'"
      v-model:val="pw2"
      :placeholder="'Confirm Password'"
      @keyup.enter="completeWallet"
      :err="pw2 && (pwLengTag || pwDiffTag || (pw1 && checkPW_special_res))"
    ></ui_input>
    <div
      class="errtxtbox"
      :style="{
        width: pwLengTag || pwDiffTag || (pw1 && checkPW_special_res) ? '100%' : 0,
        height: pwLengTag || pwDiffTag || (pw1 && checkPW_special_res) ? 'auto' : 0,
      }"
    >
      <div class="errtxt" v-if="pwDiffTag">{{ pwDiffErrTxt }}</div>
      <div class="errtxt" v-else-if="pwLengTag">
        {{
          lang == "en" ? "The Length Must Be Between 6 And 12" : "字符數量需要在6至12之間"
        }}
      </div>
      <div class="errtxt" v-else-if="pw1 && checkPW_special_res">
        {{ specialErrTxt }}
      </div>
    </div>
    <div v-if="ui_model == 4" :data-ui_model="ui_model">
      <ui_btn_1 :width="'100%'" :disclick="!regTag" @click="completeWallet">OK</ui_btn_1>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.errtxt {
  text-align: left;
  font-size: 0.16rem;
  line-height: 1;
  color: #ea3b42 !important;
  margin-bottom: 0.24rem;
  white-space: nowrap;
}

.errtxtbox {
  overflow: hidden;
  transition: all 1s 0.1s;
}

.to_login {
  text-align: center;
}

.btn1 {
  margin-top: 0.3rem;
}
</style>
<script src="./userReg.js"></script>
