<template>
  <div class="template big">
    <div class="bg">
      <div class="main">
        <img
          :src="cdnUrl + '/web_assets/img/syspopup/20221101_2.png?t=lse32'"
          alt=""
          class="bgimg"
        />
        <div class="text en" v-if="lang == 'en'">
          <h1>Block War public</h1>
          <h2>Beta begins on Sunday midnight</h2>
          <h3>with limited <span>NFT</span> sale</h3>
          <p>Milestone Reached<br />Pet Run public release</p>
        </div>
        <div class="text zh_cn" v-else>
          <h1>週日0點</h1>
          <h2>戰遊正式開啟測試</h2>
          <h3><span>NFT</span>限量搶購</h3>
          <p>新裡程碑達成<br /><span>萌寵賽跑正式上線</span></p>
        </div>
        <div v-if="props.remain_secs && props.remain_secs > 0">
          <div class="cd en" v-if="lang == 'en'">
            <span class="day">{{ tweenedDay.number.toFixed() }}</span
            >Days<span class="hour">{{ tweenedHour.number.toFixed() }}</span
            >Hours<span class="m">{{ tweenedMin.number.toFixed() }}</span
            >Minutes
          </div>
          <div class="cd zh_cn" v-else>
            <span class="day">{{ tweenedDay.number.toFixed() }}</span
            >天<span class="hour">{{ tweenedHour.number.toFixed() }}</span
            >小時<span class="m">{{ tweenedMin.number.toFixed() }}</span
            >分鐘
          </div>
        </div>
        <div v-else>
          <div class="cd en" v-if="lang == 'en'">
            <p class="ad">Public Beta is out now!</p>
          </div>
          <div class="cd zh_cn" v-else>
            <p class="ad">正式測試已經開啟！</p>
          </div>
        </div>
        <frame
          class="angel"
          :img-count="60"
          :img-width="'9.88rem'"
          :img-height="'6rem'"
          :img-src="cdnUrl + '/web_assets/img/angel/angel_'"
        />
        <IconClose class="close" @click="close" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, reactive, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import IconClose from "../../components/ui/icon/icon_close.vue";
import gsap from "gsap";
import frame from "@/components/sysPopup/frame";

let emits = defineEmits(["close"]);
let props = defineProps({
  remain_secs: {
    type: Number,
    default: 0,
  },
});

//格式化剩餘時間
const D = 24 * 60 * 60;
const H = 60 * 60;
const M = 60;
let CD_day = computed(() => {
  return Math.floor(props.remain_secs / D);
});
let CD_hour = computed(() => {
  return Math.floor((props.remain_secs - CD_day.value * D) / H);
});
let CD_min = computed(() => {
  return Math.floor((props.remain_secs - CD_day.value * D - CD_hour.value * H) / M);
});

//語言跟隨全局設置
let store = useStore();
var lang = computed(() => {
  return store.state.lang;
});
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});
//關閉大彈窗，顯示小橫幅
const close = () => {
  //把關閉動作寫入緩存
  localStorage.setItem("closeSysPopup", 1);
  emits("close");
};

const tweenedDay = reactive({
  number: CD_day.value,
});
const tweenedHour = reactive({
  number: CD_hour.value,
});
const tweenedMin = reactive({
  number: CD_min.value,
});
function AnimateToValue() {
  gsap.from(tweenedDay, { duration: 1, number: 9 });
  gsap.from(tweenedHour, { duration: 1, number: 24 });
  gsap.from(tweenedMin, { duration: 1, number: 60 });
}
AnimateToValue();
watch(CD_min, () => {
  tweenedDay.number = CD_day.value;
  tweenedHour.number = CD_hour.value;
  tweenedMin.number = CD_min.value;
});

onUnmounted(() => {
  // clearInterval(SI);
});
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: top;

  .main {
    width: 12rem;
    height: 6.5rem;
    position: relative;

    .bgimg {
      position: absolute;
      top: 2.3rem;
      left: 0;
      width: 11.18rem;
      height: auto;
    }

    .close {
      position: absolute;
      right: 1.2rem;
      top: 2rem;
      &:hover {
        path {
          fill: #fff;
        }
      }
    }

    .text {
      color: #fff;
      position: absolute;
      top: 3.2rem;
      left: 6.5rem;
      font-family: "Nexa";

      h1 {
        font-size: 0.45rem;
      }

      h2 {
        font-size: 0.23rem;
      }

      h3 {
        font-size: 0.33rem;
        margin-top: 0.1rem;

        span {
          color: #ef4f55;
          font-size: 0.45rem;
        }
      }

      p {
        font-size: 0.3rem;
        margin-top: 0.65rem;
        margin-left: 0.4rem;
      }
    }

    .text.zh_cn {
      top: 3.1rem;

      h1 {
        margin-bottom: 0.2rem;
      }

      h2 {
        font-weight: normal;
        font-size: 0.4rem;
      }

      h3 {
        font-size: 0.45rem;

        span {
          font-size: 0.45rem;
        }
      }

      p {
        margin-top: 0.35rem;
        text-align: right;
        font-weight: bold;

        span {
          font-weight: normal;
          display: inline-block;
          margin-top: 0.1rem;
        }
      }
    }

    .angel {
      position: absolute;
      top: 0.95rem;
      left: -2.52rem;
      width: 9.8rem;
      height: 6rem;
    }
  }
}

.cd {
  font-family: "Nexa";
  position: absolute;
  top: 6.55rem;
  right: 1rem;
  color: #fff;
  font-size: 0.22rem;
  font-weight: normal;
  .ad {
    font-size: 0.4rem;
    font-weight: bold;
    line-height: 0.8rem;
    letter-spacing: 0.02rem;
  }
  span {
    font-size: 0.64rem;
    display: inline-block;
    width: 0.9rem;
    text-align: right;
  }

  .hour {
    margin-left: 0.2rem;
  }

  .m {
    margin-left: 0.1rem;
  }

  &.zh_cn {
    right: 1.3rem;
    font-size: 0.3rem;

    span {
      margin: 0 0.1rem;
    }
  }
}
</style>
