<template>
  <div v-if="!mobileMenuShowTag" :class="{ com_user: true, account: loginGameType,mobile:mobileMenuShowTag }" @click="loginGameType ? false : showLoginUI(true)" :data-mobile="mobileMenuShowTag" :data-uid="userInfo && userInfo.uid ? userInfo.uid : ''" :data-level="userInfo && userInfo.level ? userInfo.level : ''" :data-nick="userInfo && userInfo.nick ? userInfo.nick : ''">
    <span :class="{
      ico: true,
      ico_wallet: true,
      on: loginGameType == 2 || walletLoginName
    }" :alt="UserAccount"><svg width="42" height="42"
        viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.2692 20.9999C28.2692 21.8921 27.546 22.6153 26.6538 22.6153C25.7617 22.6153 25.0385 21.8921 25.0385 20.9999C25.0385 20.1078 25.7617 19.3845 26.6538 19.3845C27.546 19.3845 28.2692 20.1078 28.2692 20.9999Z"
          fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M31.5 16.1538C32.3922 16.1538 33.1154 16.877 33.1154 17.7692V24.2307C33.1154 25.1228 32.3922 25.8461 31.5 25.8461V29.0768C31.5 29.969 30.7768 30.6922 29.8846 30.6922H12.1154C11.2232 30.6922 10.5 29.969 10.5 29.0768V12.923C10.5 12.0308 11.2232 11.3076 12.1154 11.3076H29.8846C30.7768 11.3076 31.5 12.0308 31.5 12.923V16.1538ZM12.9231 13.7307V28.2692H29.0769V25.8461H21.8077C20.9155 25.8461 20.1923 25.1228 20.1923 24.2307V17.7692C20.1923 16.877 20.9155 16.1538 21.8077 16.1538H29.0769V13.7307H12.9231ZM22.6154 18.5768V23.423H30.6923V18.5768H22.6154Z"
          fill="white" />
      </svg>
    </span>
    <span :class="{
      ico: true,
      ico_user: true,
      on: loginGameType == 1 || ( loginGameType == 2 &&(phone_num || email) && uid ),
      orange: loginGameType == 2 && uid && ( !phone_num && !email)
    }" :alt="UserAccount"><svg width="42" height="42"
        viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_602_9113)">
          <path
            d="M13.2058 30.231C13.9957 28.8628 15.1318 27.7266 16.5 26.9367C17.8682 26.1468 19.4202 25.731 21 25.731C22.5798 25.731 24.1318 26.1468 25.5 26.9367C26.8682 27.7266 28.0043 28.8628 28.7942 30.231"
            stroke="white" stroke-width="3" />
          <path
            d="M23.9372 14.0243C24.323 14.41 24.6289 14.8679 24.8377 15.3719C25.0464 15.8758 25.1539 16.416 25.1539 16.9615C25.1539 17.507 25.0464 18.0471 24.8377 18.5511C24.6289 19.055 24.323 19.513 23.9373 19.8987C23.5515 20.2844 23.0936 20.5904 22.5896 20.7991C22.0857 21.0079 21.5455 21.1153 21 21.1153C20.4545 21.1153 19.9144 21.0079 19.4104 20.7991C18.9065 20.5904 18.4485 20.2844 18.0628 19.8987C17.6771 19.513 17.3711 19.055 17.1624 18.5511C16.9536 18.0471 16.8462 17.507 16.8462 16.9615C16.8462 16.416 16.9536 15.8758 17.1624 15.3719C17.3711 14.8679 17.6771 14.41 18.0628 14.0243C18.4485 13.6385 18.9065 13.3326 19.4104 13.1238C19.9144 12.9151 20.4545 12.8076 21 12.8076C21.5455 12.8076 22.0857 12.9151 22.5896 13.1238C23.0936 13.3326 23.5515 13.6385 23.9372 14.0243L23.9372 14.0243Z"
            stroke="white" stroke-width="3" />
        </g>
        <defs>
          <clipPath id="clip0_602_9113">
            <path
              d="M0 21C0 9.40202 9.40202 0 21 0V0C32.598 0 42 9.40202 42 21V21C42 32.598 32.598 42 21 42V42C9.40202 42 0 32.598 0 21V21Z"
              fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
    <div v-if="loginGameType" class="drop">
      <div :class="{ 'center': true, 'on': currentRouteName == '/center' }" @click="toCenter">{{langTxt[lang].user[0]}}</div>
      <div :class="{ 'mywallet': true, 'on': currentRouteName == '/u' }" @click="toMyWallet">{{ lang =='en'?'My Wallet':'我的錢包'}}</div>
      <div @click="clearUserData" class="btn logout">{{langTxt[lang].user[1]}}</div>
    </div>
  </div>
  <div v-else :class="{'loginout':true, account: loginGameType,mobile:mobileMenuShowTag }" :data-uid="userInfo && userInfo.uid ? userInfo.uid : ''" :data-level="userInfo && userInfo.level ? userInfo.level : ''" :data-nick="userInfo && userInfo.nick ? userInfo.nick : ''">
    <ui_btn_1 v-if="!uid" @click="loginGameType ? false : showLoginUI(true)" :data-mobile="mobileMenuShowTag" class="xbd login_v2" :width="'6.47rem'" :height="'0.96rem'" :fontsize="'0.36rem'">{{lang=='en'?'LOG IN':'登錄'}}</ui_btn_1>
    <ui_btn_1 @click="clearUserData" v-else :width="'6.47rem'" :height="'0.96rem'" :fontsize="'0.36rem'" :class="{'border':mobileMenuShowTag}">{{lang=='en'?'LOG OUT':'退出'}}</ui_btn_1>
  </div>

  <userHub v-if="showUserHubTag"></userHub>
</template>
<script src="./user"></script>
<style lang="scss" scoped src="./user.scss">

</style>