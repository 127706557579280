<template>
    <span :class="{ btn3: true, white: white, drak: drak, disselect: true, disclick: disclick }" :style="{
        width: width,
        height: height,
        lineHeight: height,
        fontSize: fontsize,
    }">
        <span class="text">
            <slot></slot>
        </span>
    </span>
</template>
<style lang="scss" scoped>
.btn3 {
    position: relative;
    display: inline-block;
    background: var(--linearGradientRed);
    border-radius: 0.25rem;
    text-align: center;
    color: #fff;
    cursor: pointer;
    background: #EF4F55;
    box-sizing: border-box;
    box-shadow: 0px 4px 9px rgba(111, 63, 214, 0.15);

    svg {
        display: none;
        margin-left: 0.8rem;
    }

    &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #EF4F55;
    }

    .text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.btn3.white {
    color: #EF4F55;
    background-color: #fff;
    background-image: none;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;
    }
}

.btn3.drak {
    color: #fff;
    background-color: #26254C;
    border: #EF4F55 0.02rem solid;
    background-image: none;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #EF4F55;
    }
}

.btn3.disclick {
    background-color: #bbb;
    background-image: none;
    cursor: url("https://cdn.blockwar.io/web_assets/img/ico_cursor.png"), auto;
    opacity: 0.5;

    &:hover {
        background: #bbb;
    }
    pointer-events: none;
}
</style>
<script>
import {
    defineComponent
} from "vue";

// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
    name: "ui_btn_3",
    props: {
        width: {
            default: "1.6rem",
        },
        height: {
            default: "0.45rem",
        },
        // 白底
        white: {
            default: false,
        },
        // 暗底
        drak: {
            default: false,
        },
        disclick: {
            default: false,
        },
        fontsize: {
            default: "0.18rem",
        },
    },
    components: {},
    setup(props, ctx) {
        // let router = useRouter();
        //   let store = useStore();
        return {};
    },
});
</script>