<template>
    <div class="minbanner">
        <div v-if="props.remain_secs">
            <div class="cd en" v-if="lang == 'en'">
                <span class="day">{{ tweenedDay.number.toFixed() }}</span>Days<span class="hour">{{ tweenedHour.number.toFixed() }}</span>Hours<span class="m">{{ tweenedMin.number.toFixed() }}</span>Minutes
            </div>
            <div class="cd zh_cn" v-else>
                <span class="day">{{ tweenedDay.number.toFixed() }}</span>天<span class="hour">{{ tweenedHour.number.toFixed() }}</span>小時<span class="m">{{ tweenedMin.number.toFixed() }}</span>分鐘
            </div>
        </div>
        <div v-else>
            <div class="cd en" v-if="lang == 'en'">
                <p class="ad">Public Beta is out now !</p>
            </div>
            <div class="cd zh_cn" v-else>
                <p class="ad">正式測試已經開啟！</p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, reactive, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap'

//语言跟随全局设置
let store = useStore();
var lang = computed(() => {
    return store.state.lang;
});

let props = defineProps({
    remain_secs: {
        type: Number,
        default: 0,
    }
});


//格式化剩余时间
const D = 24 * 60 * 60;
const H = 60 * 60;
const M = 60;
let CD_day = computed(() => {
    return Math.floor(props.remain_secs / D);
})
let CD_hour = computed(() => {
    return Math.floor((props.remain_secs - CD_day.value * D) / H);
})
let CD_min = computed(() => {
    return Math.floor((props.remain_secs - CD_day.value * D - CD_hour.value * H) / M);
})


const tweenedDay = reactive({
    number: CD_day.value
})
const tweenedHour = reactive({
    number: CD_hour.value
})
const tweenedMin = reactive({
    number: CD_min.value
})
function AnimateToValue() {
    gsap.from(tweenedDay, { duration: 1, number: 9 })
    gsap.from(tweenedHour, { duration: 1, number: 24 })
    gsap.from(tweenedMin, { duration: 1, number: 60 })
}
AnimateToValue();
watch(() => CD_min.value, () => {
    tweenedDay.number = CD_day.value;
    tweenedHour.number = CD_hour.value;
    tweenedMin.number = CD_min.value;
});

onUnmounted(() => {
    // clearInterval(SI);
});

</script>
<style lang="scss" scoped>
* {
     margin: 0;
     padding: 0;
 }


 .cd {
     font-family: 'Nexa';
     position: absolute;
     top: 7.3rem;
     right: 1rem;
     color: #fff;
     font-size: 0.22rem;
     font-weight: normal;

    .ad{
        font-size: 0.45rem;
        font-weight: bold;
        line-height: 0.8rem;
        text-indent: 0.05rem;
    }
     span {
         font-size: 0.64rem;
         display: inline-block;
         width: 0.9rem;
         text-align: right;
     }

     .hour {
         margin-left: 0.5rem;
     }

     .m {
         margin-left: 0.1rem;
     }

     &.zh_cn {
         font-size: 0.3rem;
        .ad{
            text-indent: 0.15rem;
            letter-spacing: 0.05rem;
        }
         span {
             margin: 0 0.1rem;
         }
     }
 }

 .minbanner {
     cursor: pointer;
     width: 9rem;
     height: 1.2rem;
     background: url('//cdn.blockwar.io/web_assets/img/syspopup/20221101_mini.png') no-repeat top center;
     background-size: 100% auto;
     position: absolute;
     top: 0.75rem;
     left: 50%;
     margin-left: -4.5rem;

     .cd {
         top: 0.35rem;
         width: 100%;
         text-align: center;
         text-indent: -0.6rem;
         right: 0;

         .hour,
         span {
             margin: 0 0.051rem 0 0.25rem;
         }
     }
 }
</style>