<template>
  <div :class="['vue_app', fromClient]">
    <pageTop v-if="canShowStart"></pageTop>
    <div id="router" :class="'router_' + routerName">
      <router-view v-if="isHome"></router-view>
      <router-view v-else-if="canShowStart"></router-view>
      <loading v-if="showLoadingTag > 0"></loading>
      <ui_confirm></ui_confirm>
    </div>
  </div>
  <ul id="appErrorLog" v-if="appErrorLogShowTag">
    <li v-for="(item, ind) in appErrorLog" :key="ind">
      <p v-html="item"></p>
    </li>
  </ul>
</template>
<style lang="scss" src="./assets/css/app.scss"></style>
<style lang="scss" src="./assets/css/mobile_home.scss"></style>
<script setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import pageTop from "./components/head/pageTop.vue";
import loading from "./components/loading/loading.vue";
import ui_confirm from "./components/ui/ui_confirm.vue";
import "./assets/css/normalize.css";
import axios from "axios";
import common from "@/utils/common";
import useClipboard from "vue-clipboard3";
let canShowStart = ref(false);
let router = useRouter();
let store = useStore();
let appErrorLog = computed(() => {
  return store.state.appErrorLog;
});
let appErrorLogShowTag = computed(() => {
  return store.state.appErrorLogShowTag;
});
window.onerror = function (sMsg, scriptURI, lineNumber, columnNumber, errorObj) {
  store.commit(
    "set_appErrorLog",
    `onerror MSG: ${sMsg}；scriptURI:${scriptURI}；lineNumber:${lineNumber}；columnNumber：${columnNumber}；errorObj：${errorObj}`
  );
};
let routerName = computed(() => {
  console.log("router.currentRoute.value", router.currentRoute.value);
  if (!router.currentRoute.value.name) {
    return "null";
  } else if (router.currentRoute.value.name.indexOf("_") < 0) {
    return router.currentRoute.value.name;
  } else {
    return router.currentRoute.value.name.split("_")[0];
  }
});
store.commit("set_showPageTopNav", true);
window.addEventListener(
  "error",
  (error) => {
    store.commit(
      "set_appErrorLog",
      `MSG: window.addEventListener error<br /> ERROR: : ${typeof error == "string" ? error : JSON.stringify(error)
      }`
    );
  },
  true
);
let isHome = router.currentRoute.value.path == "/";
console.log("--lb", "isHome", isHome);

let fromClient = common.getQueryVariable("from");

common.setStore(store);
common.setRouter(router);
const { toClipboard } = useClipboard();
common.toClipboard = toClipboard;

let ethereumIsMetaMask = window.ethereum && window.ethereum.isMetaMask;
let showLoadingTag = computed(() => {
  return store.state.showLoadingTag;
});
let showWarn = ref(true);
const openTab = () => {
  window.open(
    "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
  );
};
onMounted(() => {
  // 連接到廣播頻道
  if (!top.bc) {
    top.bc = new BroadcastChannel("blockwar");
  }
  if (top.bc) {
    top.bc.onmessage = function (d) {
      console.log("onmessage", d);
      // 如果收到了退出的廣播且有登錄信息，消息接收頁面執行退出操作
      if (d.data == "logout") {
        if (localStorage.getItem("block-war-loginRes") || store.state.userInfo) {
          common.loginout();
        }
      }
      // 如果收到了登錄的廣播，且uid和token不一致，更新登錄信息
      else if (d.data && typeof d.data == "string") {
        const D = JSON.parse(d.data);
        console.log(D);
        if (!store.state.userInfo) {
          common.setUserInfo(D);
        } else if (
          D.data &&
          D.data.uinfo &&
          D.data.uinfo.uid != store.state.userInfo.uid &&
          D.data.uinfo.token != store.state.userInfo.token
        ) {
          common.setUserInfo(D);
        }
      }
    };
  }
});
onUnmounted(() => {
  top.bc.close();
});
/* ----------------------------------- */
let cdnArray = ["https://cdn.blockwar.io"];
if (!common.isProduction) {
  //cdnArray = ["https://cdn.war91.vip"];
  cdnArray = ["https://cdn.bigfun4u.com"];
}
let cdnUrl = ref(); //把cdn地址給scss用
let apiUrlArray = ["https://api.blockwar.io"];
if (!common.isProduction) {
  //apiUrlArray = ["https://hk.war91.vip"];
  apiUrlArray = ["https://hk.bigfun4u.com"];
}
//從取CDN地址
function loadCdnXML() {
  var xmlhttp;
  var x;
  if (window.XMLHttpRequest) {
    // code for IE7+, Firefox, Chrome, Opera, Safari
    xmlhttp = new XMLHttpRequest();
  } else {
    // code for IE6, IE5
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      cdnArray = [];
      x = xmlhttp.responseXML.documentElement.getElementsByTagName("url");
      for (let i = 0; i < x.length; i++) {
        let itemHost = x[i].childNodes[0].nodeValue;
        cdnArray.push(itemHost);
      }
      console.warn("CDN 地址：", cdnArray);
      common.cdnArray = cdnArray;
      // 獲取了cdn地址，再去獲得接口地址
      // let apiXmlUrl = 'http://files.wargamedev.com/new_game/misc/server_config.xml';
      let apiXmlUrl = cdnArray[0] + "/new_game/misc/server_config.xml";
      cdnUrl.value = cdnArray[0]; //把cdn地址給scss用
      store.commit("set_cdnUrl", cdnUrl.value);
      loadApiXML(apiXmlUrl, (x) => {
        buildAjaxUrl(apiUrlArray);
      });
    }
  };
  // xmlhttp.open("GET", "https://notice.blockwar.io/cdn-domains.xml", true);
  if (common.isProduction) {
    xmlhttp.open(
      "GET",
      "https://notice.blockwar.io/cdn-domains.xml?v=" + new Date().getTime(),
      true
    );
  } else {
    xmlhttp.open(
      "GET",
      "https://notice.bigfun4u.com/cdn-domains.xml?v=" + new Date().getTime(),
      true
    );
  }
  xmlhttp.send();
}
loadCdnXML();

//從cdn取xml，處理後拿到接口地址。完成後再路由到各組件
function loadApiXML(url, cb) {
  var xmlhttp;
  var x;
  if (window.XMLHttpRequest) {
    // code for IE7+, Firefox, Chrome, Opera, Safari
    xmlhttp = new XMLHttpRequest();
  } else {
    // code for IE6, IE5
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      apiUrlArray = [];
      x = xmlhttp.responseXML.documentElement.getElementsByTagName("proxy");
      console.log("x", x);
      for (let i = 0; i < x.length; i++) {
        let itemHost = x[i].getAttribute("host");
        let itemPort = x[i].getAttribute("https_port");
        if (!itemPort) {
          itemPort = x[i].getAttribute("http_port");
        }
        if (itemPort == "443") {
          itemPort = "https://";
        } else {
          itemPort = "http://";
        }
        apiUrlArray.push(itemPort + itemHost);
      }
      if (cb) {
        cb(x);
      }
    }
  };
  xmlhttp.open("GET", url, true);
  xmlhttp.send();
}
let apiUrlTimeArray = [];
//ping網絡延遲
function pingUrl(url) {
  const startTime = new Date().getTime();
  axios.get(url).then((r) => {
    const dis = new Date().getTime() - startTime;
    apiUrlTimeArray.push({ dis: dis, url: url.replace("/misc_api/test", "") });
    console.log(startTime, dis, apiUrlTimeArray);
    canShowStart.value = true;
    // if (apiUrlTimeArray.length == apiUrlArray.length) {
    apiUrlTimeArray.sort((a, b) => {
      return a.dis - b.dis;
    });
    for (let i = 0; i < apiUrlArray.length; i++) {
      if (apiUrlTimeArray[i] && apiUrlTimeArray[i].url) {
        apiUrlArray[i] = apiUrlTimeArray[i].url;
      }
    }
    store.commit("set_apiUrlArray", apiUrlArray);
    store.commit("set_apiUrl", apiUrlArray[0]);
    store.commit("set_apiReadyTag", true);
    console.log("apiUrlArray", apiUrlArray);
    console.warn("apiUrlArray[0]", apiUrlArray[0]);
    store.commit("set_fastUrlIndex", 0);
    localStorage.setItem("apiUrl", apiUrlArray[0]);
    common.userApi.requestUrl = apiUrlArray[0];
    // }
  });
}
function buildAjaxUrl(apiUrlArray) {
  console.warn("apiUrlArray", apiUrlArray);
  console.log("--lb", "apiUrlArray", apiUrlArray);
  store.commit("set_apiUrl", apiUrlArray[0]);
  for (let i = 0; i < apiUrlArray.length; i++) {
    pingUrl(apiUrlArray[i] + "/misc_api/test");
  }
}
</script>
