import common from "./common";
import Web3 from "web3";

// 初始化云登录环境
const initMoralis = function (cb) {
    //生产环境
    let __App_Id = "b82HpzIcgwS7kgQXymaeixkFkEC190Lm3g5ynkB7";
    let __Aerver_Url = "https://zkpbawq7utwn.usemoralis.com:2053/server";
    // 测试环境
    if (!common.isProduction) {
        __App_Id = "yMYX8wzD0V1ZjOWhXur4ZAjQJWT6BkWGo8mBJxEy";
        __Aerver_Url = "https://csg0cf4e3zw2.usemoralis.com:2053/server";
    }
    // console.log(__App_Id, __Aerver_Url);
    //启动Moralis，调用登录登出之前，必需先调用此接口
    const startMoralis = async (cb) => {
        let store = common.getStore();
        try {
            // if(!store.state.canLogin_v2){
                common.showLoading('startMoralis');
                let MoralisStart = await Moralis.start({
                    appId: __App_Id,
                    serverUrl: __Aerver_Url
                });
                console.log('MoralisStart',MoralisStart)
                common.hideLoading('initMoralis');
                store.commit('set_canLogin_v2', true);
            // }
            if(cb){
                cb();
            }
            // 监控账号和链的切换
            window.ethereum.on('accountsChanged', (accounts) => {
                console.log(accounts);
                // common.loginout(27);
            });
            window.ethereum.on('chainChanged', (chainId) => {
                console.log(chainId);
                // common.loginout(31);//不能退出，不然登录过程切换对应链时会中断登录
            });
        } catch (error) {
            //失败，给用户提示
            // toastr['error'](error);
            store.commit('set_canLogin_v2', false);
            common.hideLoading('initMoralis');
            console.error('startMoralis Moralis.start ERROR!!!')
        }
    }
    startMoralis(cb);
    
}

// 判断是否目标链
let chainId;
if (common.isProduction) {
    chainId = 56;
}
else {
    chainId = 97;
}
let swithChain = async function () {
    console.log('--lb swithChain');
    try {
        await (window.ethereum).request({
            method: 'wallet_switchEthereumChain',
            params: [{
                chainId: Web3.utils.numberToHex(chainId) // 目标链ID
            }]
        })
    } catch (e) {
        console.error(e);
    }
}

// 获取目标链的用户钱包地址
const getUserWalletAddres = async function () {
    await swithChain().then(async () => {
        let getAccountRes;
        let store = common.getStore();
        common.showLoading('getWalletAccount');
        await ethereum
            .request({
                method: "eth_requestAccounts",
            })
            .catch(function (reason) {
                common.hideLoading('getWalletAccount');
                console.log('--lb', reason);
                getAccountRes = reason;
            })
            .then(function (account) {
                common.hideLoading('getWalletAccount');
                if (account) {
                    console.log('--lb', 'eth_requestAccounts', account)
                    common.LOGIN_SESSION.auth.actor = account[0];
                    getAccountRes = account;
                }
                else {
                    common.runLoginCallback('account is null', 'error');
                    getAccountRes = 'account is null';
                }
                store.commit('set_moralisWalletAddress', getAccountRes[0]);
            });
    })
}


//登录Moralis并获取云用户信息
const getMoralisUser = async (MoralisUser) => {
    common.showLoading('getMoralisUser');
    // 判断是否装有小狐狸钱包
    if (typeof window.web3 === 'undefined' || !window.web3.currentProvider.isMetaMask) {
        toastr['error']('MetaMask is not installed!');
        failFn();
        return;
    }
    await swithChain().then(async () => {
        {
            MoralisUser.value = Moralis.User.current();
            console.log('--lb getMoralisUser MoralisUser.value', MoralisUser.value)
            if (!MoralisUser.value) {// 没有MoralisUser，就获取，然后在userHub.vue里触发获取uid和token
                try {
                    MoralisUser.value = await Moralis.authenticate({
                        signingMessage: "Log in BlackWar using Moralis",
                    });
                    //获取用户钱包地址
                    console.log("--lb !MoralisUser.value but Moralis.authenticated user adddress:", MoralisUser.value, "getUserTokenByMoralis()");
                } catch (error) {
                    console.error('--lb getMoralisUser ERROR:', error);
                    //失败，给用户提示
                    toastr['error'](error.message);
                }
                common.hideLoading('getMoralisUser');
            } else {
                //表示用户已经登录了Moralis，不需要再登录，避免不必要的小狐狸弹窗
                console.log("--lb already login");
                common.hideLoading('getMoralisUser');
                //获取用户钱包地址
                console.log("--lb user adddress:", MoralisUser.value.get("ethAddress"));
            }
            let store = common.getStore();
            store.commit('set_MoralisUser',Moralis.User.current());//这里只赋值MoralisUser，userHub.vue里监控MoralisUser，有值就会调用getUserTokenByMoralis
            console.log('store.state.MoralisUser',store.state.MoralisUser);
        }
    })
}
//调用Moralis云函数，返回uid、token
const getUserTokenByMoralis = async () => {
    common.showLoading('getUserTokenByMoralis');
    console.log('--lb getUserTokenByMoralis()');
    //参数说明：http://docs.wargamedev.com/server/protocol/web/extra.txt
    const params = {
        "appid": common.userApi.AppId,
        "xbd": "",
        "domain_name": common.domainName,
        "channel_id": common.get_channelId(),
        "device_token": common.buildDeviceToken()
    };
    console.warn(params)
    const res = await Moralis.Cloud.run("getToken", params);
    console.warn("--lb getUserTokenByMoralis res", res);

    if (res.code === 0) {
        let store = common.getStore();
        //成功
        console.log("--lb uid:", res.data.uinfo.uid, "token:", res.data.token);
        common.hideLoading('getUserTokenByMoralis');
        store.commit("set_loginGameType", 2);
        common.buildSendToGameHeader(res.data.uinfo.uid, res.data.token);
        //查询是钱包否已绑定了游戏账号
        common.queryUserBindInfo(res.address, "conntctWallet");
        common.LOGIN_SESSION.auth.actor = res.address;
        common.setUserInfo(res);
    } else {
        //失败
        console.error("--lb failed, msg:" + res.msg);
        toastr['error'](res.msg);
        common.hideLoading('getUserTokenByMoralis');
        common.runLoginCallback(res, "error");
    }
    return res;
}



export default {
    initMoralis, getMoralisUser, getUserTokenByMoralis, getUserWalletAddres
}