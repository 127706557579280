<template>
  <div class="minigame">
    <div class="layout">
      <div
        :class="{
          conent: true,
          active: inx == currentGameIndex,
          rotate: rotate,
        }"
        v-for="(item, inx) in gameList"
        :key="inx"
      >
        <img :src="cdnUrl + '/web_assets/img/minigame_lion.png'" alt="" />
        <div class="right" v-if="apiReadyTag">
          <h2 class="mortina">{{ langTxt[lang].index.minigame[0] }}</h2>
          <p>
            {{ langTxt[lang].index.minigame[1] }} <br />
            {{ langTxt[lang].index.minigame[2] }}
          </p>
          <div
            :class="{
              btn: true,
            }"
          >
            <ui_btn_1
              :class="{
                animated: true,
                pulse: pluse,
              }"
              :width="'2.8rem'"
              :fontsize="'0.24rem'"
              @click="toPage('earn')"
            >
              {{ langTxt[lang].index.minigame[3] }}</ui_btn_1
            >
            <ui_btn_1
              class="btn_stats"
              @click="toStats"
              :width="'1.86rem'"
              :fontsize="'0.24rem'"
              :hollow="true"
            >
              {{ langTxt[lang].index.minigame[4] }}</ui_btn_1
            >
            <appDownload class="onlypc" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./minigame.scss"></style>
<script src="./minigame.js"></script>
