<template>
  <div class="userlogin">
    <div class="login">
      <h1 class="mortina">{{ lang == "en" ? "Log In" : "登錄" }}</h1>
      <p>{{ lang == "en" ? "Welcome to log in block WAR." : "歡迎登錄Block War" }}</p>
    </div>
    <!-- by pw -->
    <div
      class="tipsbox"
      :style="{
        width:
          checkErr.check_te_err || (formVal.pw && checkErr.check_pw_err) ? '100%' : 0,
        borderWidth:
          checkErr.check_te_err || (formVal.pw && checkErr.check_pw_err) ? '1px' : 0,
        height:
          checkErr.check_te_err || (formVal.pw && checkErr.check_pw_err) ? 'auto' : 0,
        marginBottom:
          checkErr.check_te_err || (formVal.pw && checkErr.check_pw_err) ? '0.2rem' : 0,
      }"
    >
      <div class="tips">
        <div class="left">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15 11.5V9H5V11.5H15Z"
              fill="#EA3B42"
            />
          </svg>
        </div>
        <div class="right">
          <div v-if="checkErr.check_te_err">
            <p v-if="errTxt.tmErrTxt">
              {{ lang == "en" ? "Please Enter Your Email / Tel" : "請輸入郵箱/手機號碼" }}
            </p>
            <p class="want">
              {{ lang == "en" ? "want to" : "是否" }}
              <span @click="showRegUI" class="xbd">{{
                lang == "en" ? "Create Account?" : "創建一個賬號？"
              }}</span>
            </p>
          </div>
          <div v-else-if="formVal.pw && checkErr.check_pw_err">
            <p>{{ langTxt[lang].login[3] }}</p>
            <p v-if="errTxt.pwErrTxt" class="want">
              {{
                lang == "en"
                  ? "The Length Must Be Between 6 And 12"
                  : "字符數量需要在6至12之間"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loginType == 2">
      <ui_input
        v-model:val="formVal.telmail"
        :placeholder="lang == 'en' ? 'E-Mail/Tel' : '郵箱地址 / 手機號碼'"
        :err="checkErr.check_te_err"
        :errtxt="errTxt.tmErrTxt"
        @keyup.enter="login"
      ></ui_input>
      <ui_input
        :type="'password'"
        v-model:val="formVal.pw"
        :placeholder="lang == 'en' ? 'Password' : '密碼'"
        :err="formVal.pw && checkErr.check_pw_err"
        :errtxt="errTxt.pwErrTxt"
        @keyup.enter="login"
      ></ui_input>
    </div>
    <div v-else>
      <!-- by vc -->
      <!-- <ui_input
        v-model:val="formVal.telmail"
        :placeholder="'TEL'"
        :err="formVal.telmail && checkErr.check_t_err"
        :ertxt="errTxt.tErrTxt"
        @keyup.enter="login"
      ></ui_input>
      <div class="vcbox">
        <ui_input
          class="vc"
          v-model:val="formVal.verify_code"
          :err="formVal.verify_code && checkErr.check_vc_err"
          :ertxt="errTxt.vcErrTxt"
          :width="'1.5rem'"
          :placeholder="'VERIFY CODE'"
          @keyup.enter="login"
        ></ui_input>
        <ui_btn_1 :width="'2rem'" :fontsize="'0.18rem'" @click="sendLoginSMS">{{
          sendSmsBtnTxt
        }}</ui_btn_1>
      </div> -->
    </div>
    <div class="switch_forgot">
      <!-- <span @click="switchLoginType">Switch Login Mode</span> -->
      <span @click="resetPW">{{ langTxt[lang].login[4] }}</span>
    </div>
    <ui_btn_1
      :width="'100%'"
      :fontsize="'0.18rem'"
      :disclick="!canLoginTag"
      @click="login"
      >{{ lang == "en" ? "LOGIN" : "登錄" }}</ui_btn_1
    >
    <div class="tocreate">
      {{ langTxt[lang].login[5] }}
      <span class="create xbd" @click="showRegUI">{{ langTxt[lang].login[6] }}</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.tipsbox {
  width: 100px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ea3b42;
  border-radius: 10px;
  transition: width 0.5s 0.1s;
  position: relative;
}

.tips {
  width: 3.7rem;
  box-sizing: border-box;
  padding: 0.1rem 0.2rem;
  display: flex;
  align-items: center;

  .left {
    margin-right: 0.1rem;
  }

  p {
    color: #333;
    margin: 0;
    font-size: 0.16rem;

    &.want {
      color: rgba(51, 51, 51, 0.8);

      span {
        font-weight: bold;
        cursor: pointer;
        background: var(--linearGradientRed);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      &:hover {
        span {
          background: var(--linearGradientRedHover);
          -webkit-background-clip: text;
          background-clip: text;
        }
      }
    }
  }
}

.tocreate {
  color: #bdbdbd;
  text-align: center;

  .create {
    font-weight: bold;
  }
}

.switch_forgot {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.3rem;

  span {
    cursor: pointer;

    &:hover {
      background: var(--linearGradientRed);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}
</style>
<script src="./userLogin.js"></script>
