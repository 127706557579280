<template>
    <div class="template sys_popup">
        <big v-if="showSysPopupTag && !showMinBanner" :remain_secs="remain_secs" @close="close"></big>
        <minibanner v-if="showSysPopupTag && showMinBanner" :remain_secs="remain_secs" @click="showPopup"></minibanner>
    </div>
</template>
<script setup>
import axios from 'axios';
import { computed, onUnmounted,ref } from 'vue';
import common from '../../utils/common';
import { useStore } from 'vuex';
import big from '@/components/sysPopup/big';
import minibanner from '@/components/sysPopup/minibanner';

// 获取倒计时时间
let ST,SI;
let remain_secs = ref();
axios.get(common.userApi.requestUrl + '/misc_api/countdowns/0').then((r) => {
    r.data.remain_secs = 0; showSysPopupTag.value = true;
    // if (r.data.code == 0 && r.data.remain_secs) {
    //     showSysPopupTag.value = true;
    //     remain_secs.value = r.data.remain_secs;
    //     ST = setTimeout(() => {
    //         // showSysPopupTag.value = false;
    //     }, r.data.remain_secs * 1000);
    //     SI = setInterval(() => {
    //         if (remain_secs.value>0){
    //             remain_secs.value -= 1
    //         }
    //         else {
    //             clearInterval(SI);
    //         }
    //     }, 1000);
    // }
})

//是否需要显示弹窗
let showSysPopupTag = ref(false);
//是否需要显示小横幅
let showMinBanner = ref(false);
if (localStorage.getItem("closeSysPopup") == 1) {
    showMinBanner.value = true;
}

//语言跟随全局设置
let store = useStore();
var lang = computed(() => {
    return store.state.lang;
});

//关闭大弹窗，显示小横幅
const close = () => {
    showMinBanner.value = true;
}
// 显示大弹窗，关闭小横幅
const showPopup = () => {
    localStorage.setItem('closeSysPopup', 0);
    showMinBanner.value = false;
}

onUnmounted(() => {
    clearTimeout(ST);
    clearInterval(SI);
});
</script>