<template>
  <div class="msghub template">
    <div :class="{'ico':true}" @click="switchMsgList" v-if="msgContainer">
      <div class="red_dot" v-if="unReadCount>0">{{unReadCount}}</div>
    </div>
    <div class="msgcontainer">
      <div class="msg_list" v-if="showMsgListTag">
        <div class="content" v-if="!msgContainer">
          loading...
        </div>
        <div class="content" v-else-if="msgContainer.length > 0">
          <ul>
            <li :data-seqnum="item.seqnum" v-for="(item, inx) in msgContainer" :key="inx">
              <div v-if="item.status==0" class="unread"></div>
              <div class="flex" v-if="item.type==4" data-type="item.type">
                <div class="left">
                  <i class="ico" v-if="item.content.noti_tp == 1">
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="38" height="38" rx="19" fill="#3D9646" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M30.6154 16.4615C30.6154 15.5694 29.8922 14.8462 29 14.8462V11.6154C29 10.7232 28.2768 10 27.3846 10H9.61538C8.72323 10 8 10.7232 8 11.6154V15H10.4231V12.4231H26.5769V14.8462H19.3077C18.4155 14.8462 17.6923 15.5694 17.6923 16.4615V22.9231C17.6923 23.8152 18.4155 24.5385 19.3077 24.5385H26.5769V26.9615H10.4231V23H8V27.7692C8 28.6614 8.72323 29.3846 9.61538 29.3846H27.3846C28.2768 29.3846 29 28.6614 29 27.7692V24.5385C29.8922 24.5385 30.6154 23.8152 30.6154 22.9231V16.4615ZM20.1154 22.1154V17.2692H28.1923V22.1154H20.1154ZM24.1538 21.3077C25.046 21.3077 25.7692 20.5845 25.7692 19.6923C25.7692 18.8002 25.046 18.0769 24.1538 18.0769C23.2617 18.0769 22.5385 18.8002 22.5385 19.6923C22.5385 20.5845 23.2617 21.3077 24.1538 21.3077Z"
                        fill="white" />
                      <path d="M13 16L15.2929 18.2929C15.6834 18.6834 15.6834 19.3166 15.2929 19.7071L13 22"
                        stroke="white" stroke-width="2" stroke-linecap="round" />
                      <path d="M14 19H9" stroke="white" stroke-width="2" stroke-linecap="square" />
                    </svg>

                  </i>
                  <i class="ico" v-else-if="item.content.noti_tp == 2">
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="38" height="38" rx="19" fill="#C53650" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M26.4999 10.0018C26.4999 8.60222 25.0993 7.63546 23.7906 8.13184L10.8166 13.053H9.61538C8.72323 13.053 8 13.7762 8 14.6684V28.8222C8 29.7144 8.72323 30.4376 9.61538 30.4376H27.3846C28.2768 30.4376 29 29.7144 29 28.8222V26.5915C29.8922 26.5915 30.6154 25.8682 30.6154 24.9761V18.5145C30.6154 17.6224 29.8922 16.8992 29 16.8992V14.6684C29 13.7762 28.2768 13.053 27.3846 13.053H26.4999V10.0018ZM24.4999 13.053V10.0018L16.4559 13.053H24.4999ZM10.4231 28.0145V15.4761H26.5769V16.8992H19.3077C18.4155 16.8992 17.6923 17.6224 17.6923 18.5145V24.9761C17.6923 25.8682 18.4155 26.5915 19.3077 26.5915H26.5769V28.0145H10.4231ZM20.1154 24.1684V19.3222H28.1923V24.1684H20.1154ZM24.1538 23.3607C25.046 23.3607 25.7692 22.6375 25.7692 21.7453C25.7692 20.8532 25.046 20.1299 24.1538 20.1299C23.2617 20.1299 22.5385 20.8532 22.5385 21.7453C22.5385 22.6375 23.2617 23.3607 24.1538 23.3607Z"
                        fill="white" />
                    </svg>


                  </i>
                </div>
                <div class="right">
                  <div class="title">
                    <span class="game">{{ item.showTitle }}</span><span class="time">{{
                    formatTime(item.add_time)
                    }}</span>
                  </div>
                  <div :class="{ overview: true, success: false, error: false }">
                    {{ item.showStatus }}
                  </div>
                  <div class="detail aa">
                    <div :class="{ content: true, on: item.content.noti.op_status == 2 }" v-html="item.showTxt">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex" v-else>
                <div class="left"><i class="ico"><svg width="38" height="38" viewBox="0 0 38 38" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect x="-2" y="-2" width="42" height="42" rx="21" fill="white" fill-opacity="0.15" />
                      <path
                        d="M12.4231 15.7693C12.4231 12.137 15.3677 9.19238 19 9.19238C22.6324 9.19238 25.5769 12.137 25.5769 15.7693V24.7693H12.4231V15.7693Z"
                        stroke="white" stroke-width="3" />
                      <rect x="15.7693" y="27.8848" width="6.46154" height="2.42308" rx="1.21154" fill="white" />
                      <rect x="8.5" y="23.8462" width="21" height="2.42308" rx="1.21154" fill="white" />
                      <rect x="20.6155" y="5.26904" width="4.84615" height="3.23077" rx="1.61538"
                        transform="rotate(90 20.6155 5.26904)" fill="white" />
                    </svg>
                  </i></div>
                <div class="right">
                  <div class="title"><span class="game">{{item.title}}</span><span
                      class="time">{{formatTime(item.add_time)}}</span></div>
                  <div class="detail" v-html="item.content"></div>
                  <div class="reward" v-if="item.reward && item.reward.asset_infos && item.reward.asset_infos[0]">
                    <div v-for="(ii,iin) in item.reward.asset_infos">
                      <div v-if="ii.type==3">{{lang=='en'?'congratulations on your':'您獲得'}}
                        <p><i class="token s">{{ii.value}}</i></p>
                      </div>
                    </div>
                    <div>
                      <ui_btn_2 :width="'1.07rem'" :height="'0.33rem'" v-if="item.status==0" @click="receive(item)">
                        {{ lang == 'en' ? 'receive' :'領取'}}</ui_btn_2>
                      <span v-else class="rewarded">
                        {{ lang == 'en' ? 'already received' :'已領取'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- test data -->
            <!-- <li>
              <div class="flex">
                <div class="left">
                  <i class="ico">
                    <img src="https://cdn.blockwar.io/web_assets/img/ico_msghub_dice.png" alt="" />
                  </i>
                </div>
                <div class="right">
                  <div class="title">
                    <span class="game">DICE GAME</span><span class="time">4-22 16:44</span>
                  </div>
                  <div :class="{ overview: true, success: false, error: false }">
                    Congratulations on your successful bet !
                  </div>
                  <div class="detail">
                    <div :class="{ content: true, on: false }">
                      your bet: <i class="token g">100</i>
                      <i class="token w">100</i>
                    </div>
                  </div>
                </div>
              </div>
            </li> -->

          </ul>
        </div>
        <div v-else class="content">— NO NEWS YET —</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./msgHub.scss">

</style>
<style lang="scss">
.msg_list .content ul li {
  i.token {
    display: inline-block;
    font-style: normal;
    margin: 0 0.06rem;

    &::before {
      content: '';
      display: inline-block;
      width: 0.12rem;
      min-width: 12px;
      height: 0.12rem;
      min-height: 12px;
      vertical-align: middle;
      margin-right: 2px;
      background-size: 100% 100%;
    }

    &.g {
      color: #FFC839;

    }

    &.w {
      color: #3965FF;

      &::before {
        background: url('https://cdn.blockwar.io/web_assets/img/ico_token_w.png') no-repeat left top;
      }
    }

    &.s {
      color: #fff;

      &::before {
        background: url('https://cdn.blockwar.io/web_assets/img/ico_exchange_sliver.png') no-repeat left top;
        width: 0.16rem;
        height: 0.16rem;
        line-height: 0.16rem;
        vertical-align: middle;
        margin-right: 3px;
        background-size: 100% 100%;
      }
    }

    &::before {
      background: url('https://cdn.blockwar.io/web_assets/img/ico_exchange_gold.png') no-repeat left top;
      background-size: 100% 100%;
    }
  }
}
</style>
<script src="./msgHub"></script>
