<template>
  <span :class="{ btn1: true, border: hollow, disselect: true, disclick: disclick }" :style="{
    width: width,
    height: height,
    lineHeight: height,
    fontSize: fontsize,
  }">
    <span class="text mortina">
      <slot></slot>
    </span>
  </span>
</template>
<style lang="scss" scoped>
.btn1 {
  position: relative;
  display: inline-block;
  background: var(--linearGradientRed);
  border-radius: 0.1rem;
  font-family: "Mortina";
  text-align: center;
  color: #fff;
  cursor: pointer;

  svg {
    display: none;
    margin-left: 0.8rem;
  }

  &:hover {
    background: var(--linearGradientRedHover);
  }

  .text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn1.disclick {
  background-color: #383a48;
  background-image: none;
  cursor: url("https://cdn.blockwar.io/web_assets/img/ico_cursor.png"), auto;
  pointer-events: none;
}

.btn1.border {
  color: #fff;
  border: 2px #ef4f55 solid;
  background-color: #26254c;
  background-image: none;

  &:hover {
    background: var(--linearGradientRedHover);
  }
}
</style>
<script>
import {
  defineComponent
} from "vue";

// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_btn_1",
  props: {
    width: {
      default: "3.25rem",
    },
    height: {
      default: "0.58rem",
    },
    hollow: {
      default: false,
    },
    disclick: {
      default: false,
    },
    fontsize: {
      default: "0.18rem",
    },
  },
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    return {};
  },
});
</script>