import axios from "axios";
import Web3 from "web3";
import common from "./common";



// const ethUtil = require('ethereumjs-util');
// const sigUtil = require('@metamask/eth-sig-util');

//测试：前端加密解密
/* const encryptedMessage = ethUtil.bufferToHex(
    Buffer.from(
        JSON.stringify(
            sigUtil.encrypt({
                publicKey: publicKey,
                data: 'f38ec2db180e1eba7a5b4745f08e0284013a8741',
                version: 'x25519-xsalsa20-poly1305',
            })
        ),
        'utf8'
    )
);
console.log('--lb',"encryptedMessage", encryptedMessage);
// console.log('--lb',"encryptedMessage", sigUtil.encrypt({
//     publicKey: publicKey,
//     data: 'hello world!',
//     version: 'x25519-xsalsa20-poly1305',
// }));
window.ethereum.request({
    method: 'eth_decrypt',
    params: [encryptedMessage, common.LOGIN_SESSION.auth.actor],
}).then((decryptedMessage) => {
    console.log('--lb','The decrypted message is:', decryptedMessage);
}
).catch((error) => {
    console.log('--lb',error.message);
});
return; */
let web3;
const getAbi = function () {
    if (common.isProduction) {
        return axios.get('/Contracts/BscContracts/abis/Login.json');
    }
    else {
        return axios.get('/ContractsTest/BscContracts/abis/Login.json');
    }
}
const getNetAddress = function () {
    if (common.isProduction) {
        return axios.get('/Contracts/BscContracts/MainNetAddress.json');
    }
    else {
        return axios.get('/ContractsTest/BscContracts/TestNetAddress.json');
    }

}

let chainId;
const connectWeb3 = async function (cbFn = function () { }, failFn = function () { }) {
    web3 = new Web3(window.ethereum);
    if (common.isProduction) {
        chainId = 56;
    }
    else {
        chainId = 97
    }
    if (typeof window.web3 === 'undefined' || !window.web3.currentProvider.isMetaMask) {
        toastr['error']('MetaMask is not installed!');
        failFn();
        return false;
    }
    // 判断链对不，链不对就请求切换网络，或者添加网络，
    if (window.ethereum) {
        try {
            await (window.ethereum).request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: Web3.utils.numberToHex(chainId) // 目标链ID
                }]
            })
            cbFn();
        } catch (e) {
            if ((e).code === 4902) {
                /* try {
                    await (window.ethereum).request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: Web3.utils.numberToHex(chainId), // 目标链ID
                                chainName: 'Binance Smart Chain Mainnet',
                                nativeCurrency: {
                                    name: 'bnb',
                                    symbol: 'bnb',
                                    decimals: 18
                                },
                                rpcUrls: ['https://bsc-dataseed.binance.org'], // 节点
                                blockExplorerUrls: ['https://www.bscscan.com']
                            }
                        ]
                    })
                } catch (ee) {
                    //
                } */

                failFn();
                return;
            } else if ((e).code === 4001) {
                // common.loginout(108);
                toastr['error']('User rejected the request.');
                failFn();
                return;
            }
        }
    }
    else {
        failFn();
    }
}




let store = null;
const setStore = function (s) {
    if (!store)
        store = s;
}


const Web3SetEvent = function () {
    if (window.web3Contract) {
        console.log('--lb', "event 2");
        window.web3Contract.events
            .LoginKeyChangedEvent({}, function (error, event) {
                console.log('--lb', "callback", error, event);
            })
            .on("connected", function (subscriptionId) {
                console.log('--lb', "connected", subscriptionId);
            })
            .on("data", function (event) {
                console.log('--lb', "data", event);
            })
            .on("changed", function (event) {
                console.log('--lb', "changed", event);
            })
            .on("error", function (error, receipt) {
                console.log('--lb', "error", error, receipt);
            })
            .on("close", function (error, receipt) {
                console.log('--lb', "error", error, receipt);
            });

        window.web3Contract.once(
            "LoginKeyChangedEvent",
            {
                fromBlock: "latest",
            },
            function (error, event) {
                console.log('--lb', "once", error, event);
            }
        );

        if (window.web3Contract && window.web3Contract.getPastEvents) {
            window.web3Contract
                .getPastEvents(
                    "LoginKeyChangedEvent",
                    {
                        fromBlock: "latest",
                    },
                    function (error, events) {
                        console.log('--lb', "getPastEvents", events);
                    }
                )
                .then(function (events) {
                    console.log('--lb', "getPastEvents", events); // 与上述可选回调结果相同
                });
        }

        if (window.ethereum) {
            //监听账号变化
            ethereum.on("accountsChanged", function (accounts) {
                console.log('--lb', "accountsChanged accounts", accounts);
                // common.LOGIN_SESSION = { auth: { actor: accounts[0] } };
                // toastr['info']("accountsChanged");
            });
            ///监听网络变化
            ethereum.on("chainChanged", (chainId) => {
                console.warn('--lb', "chainChanged!", chainId);
                // toastr['info']("chainChanged");
            });
        }

    }
};
Web3SetEvent();
//获取钱包账号
const getWalletAccount = async function (
    paramArray = ["metamask"]
) {
    let getAccountRes;
    let localHostName = localStorage.getItem('block-war-hostname');
    if (localHostName !== location.hostname) {
        common.loginout(203);
        return;
    }
    //metaMask方式登录
    let ethereumIsMetaMask = window.ethereum && window.ethereum.isMetaMask;
    if (!ethereumIsMetaMask) {
        // toastr['error'](common.msg.didntFindMetaMask);
        // window.open(
        //     "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
        // );
        common.confirm({
            showConfirmTag: true,
            type: 'error',
            title: 'Error',
            text: common.msg.didntFindMetaMask + "<br /><a style='color:#D0343A' target='_blank' href='https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain'>Click here for help</a>",
            btnTxt: 'OK',
            showCancleBtn: false,
            confirmFn: function (r) {
                store.commit("set_showConfirmTag", false);
            },
            cancelFn: function (r) {
                store.commit("set_showConfirmTag", false);
            }
        })
        common.runLoginCallback(reason, common.msg.didntFindMetaMask)
        return false;
    }
    store.commit('set_chain_tp', 2);
    try {
        if (paramArray[0].toLowerCase() == "metamask") {

            common.showLoading('getWalletAccount');
            await ethereum
                .request({
                    method: "eth_requestAccounts",
                })
                .catch(function (reason) {
                    common.hideLoading('getWalletAccount');
                    console.log('--lb', reason);
                    getAccountRes = reason;
                })
                .then(function (account) {
                    common.hideLoading('getWalletAccount');
                    if (account) {
                        console.log('--lb', 'eth_requestAccounts', account)
                        common.LOGIN_SESSION.auth.actor = account[0];
                        getAccountRes = account;
                    }
                    else {
                        common.runLoginCallback('account is null', 'error');
                        getAccountRes = 'account is null'
                    }
                });
        }
    } catch (error) {
        common.hideLoading('getWalletAccount');
        console.err(error)
        getAccountRes = error
    }
    return getAccountRes;
};

//获取加密的钱包public_key
const getEncryptionPublicKeyAndLoginGame = async function (accountArr) {
    common.showLoading('getEncryptionPublicKeyAndLoginGame');
    let getRes;
    await window.ethereum.request({
        method: 'eth_getEncryptionPublicKey',
        params: [accountArr[0]],
    })
        .then((publicKey) => {
            common.hideLoading('getEncryptionPublicKeyAndLoginGame');
            console.log('--lb', publicKey);
            /* //拿到钱包公钥后，传给服务器，并返回验证字符串
            common.sendPublickeyAndGetKey(publicKey).then((res) => {
                console.log('--lb',res);
                //用钱包解密服务器传来的key
                getRes = res;
            }).catch((e) => {
                common.runLoginCallback(e, 'error');
                getRes = e;
            }); */
            getRes = publicKey;
        })
        .catch((e) => {
            common.runLoginCallback(e.message, 'error');
            common.hideLoading('getEncryptionPublicKeyAndLoginGame');
            getRes = e;
        });
    return getRes;
};
//用钱包解密服务器传来的key
const decryptedMessage = async function (res, account) {
    common.showLoading('decryptedMessage');
    console.log('--lb', res.data.key, account);
    store.commit('set_key', res.data.key);
    let getRes;
    //取得服务器传来的加密public_key，交给钱包进行解密码
    await window.ethereum.request({
        method: 'eth_decrypt',
        params: [res.data.key, account],
    }).then((decryptedMessage) => {
        common.hideLoading('decryptedMessage')
        console.log('--lb', 'The decrypted message is:', decryptedMessage);
        getRes = decryptedMessage;
    }
    ).catch((error) => {
        common.hideLoading('decryptedMessage');
        console.err(error);
        common.runLoginCallback(error, 'error');
        getRes = error;
    });
    return getRes;
}
//从取钱包的publicKey到解密服务器返回的key。用来登录或绑定等
const formwalletToKey = function (accountArr, callbackFn) {
    getEncryptionPublicKeyAndLoginGame(accountArr)
        .then((r) => {
            console.log('--lb', r);
            //到服务器换来key
            common
                .sendPublickeyAndGetKey(r)
                .then((res) => {
                    console.log('--lb', res);
                    //用钱包解key
                    if (!res) {
                        return;
                    }
                    decryptedMessage(res, accountArr[0])
                        .then((resData) => {
                            console.log('--lb', resData);
                            //用解密后的key登录或绑定等
                            callbackFn(resData);
                        })
                        .catch((e) => {
                            console.error(e);
                            common.runLoginCallback(e, "error");
                            common.hideLoading('formwalletToKey');
                        });
                })
                .catch((e) => {
                    console.error(e);
                    common.runLoginCallback(e, "error");
                });
        })
        .catch((e) => {
            common.hideLoading('formwalletToKey');
            console.error(e);
            common.runLoginCallback(e, error);
        });
}


export default {
    setStore, getWalletAccount, getEncryptionPublicKeyAndLoginGame, decryptedMessage, formwalletToKey, connectWeb3
    // approve
}