export default {
    namespaced: true,
    state: {
        ui_model: 1, //影响注册和登录界面。1:表示正常的注册登录；2表示钱包绑账号；3表示账号绑钱包;4表示钱包补全账号密码
        showUserHub: false,
        showUserLogin: false,
        showUserReg: false,
        showUserBind: false,
        showUserBindToWallet: false,
        showUserRestPW: false,
    },
    actions: {},
    mutations: {
        set_ui_model(stata, payload) {
            stata.ui_model = payload;
        },
        set_showUserHub(state, payload) {
            state.showUserHub = payload;
            state.showUserLogin = true;
            state.showUserReg = false;
            state.showUserBind = false;
            state.showUserRestPW = false;
            state.showUserBindToWallet = false;
            if (payload == true) {
                state.ui_model = 1;
            }
        },
        set_showUserLogin(state, payload) {
            state.showUserHub = true;
            state.showUserLogin = payload;
            state.showUserReg = false;
            state.showUserBind = false;
            state.showUserRestPW = false;
            state.showUserBindToWallet = false;
        },
        set_showUserReg(state, payload) {
            state.showUserHub = true;
            state.showUserLogin = false;
            state.showUserReg = payload;
            state.showUserBind = false;
            state.showUserRestPW = false;
            state.showUserBindToWallet = false;
        },
        set_showUserBind(state, payload) {
            state.showUserHub = true;
            state.showUserLogin = false;
            state.showUserReg = false;
            state.showUserBind = payload;
            state.showUserBindToWallet = false;
            state.showUserRestPW = false;
        },
        set_showUserBindToWallet(state, payload) {
            state.showUserHub = true;
            state.showUserLogin = false;
            state.showUserReg = false;
            state.showUserBind = false;
            state.showUserBindToWallet = payload;
            state.showUserRestPW = false;
        },
        set_showUserRestPW(state, payload) {
            state.showUserHub = true;
            state.showUserLogin = false;
            state.showUserReg = false;
            state.showUserBind = false;
            state.showUserBindToWallet = false;
            state.showUserRestPW = payload;
        },
    },
}
