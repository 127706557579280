import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router/indexRouter.js'
import store from './store/index.js'
import './assets/css/scroll.css'

// createApp(App).use(store).use(router).mount('#app');
//如果需要获取根组件，注释掉上面这一句，用下面两句
window.app = createApp(App).use(store).use(router).use(ElementPlus);
window.vm = window.app.mount("#app");

//跳转页面，默认回到顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
