import { defineComponent, reactive, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import axios from "axios";
import common from "../../utils/common";
import web3Tools from "../../utils/web3Tools";

import ui_btn_1 from "../../components/ui/ui_btn_1.vue";
import ui_input from "../../components/ui/ui_input.vue";

export default defineComponent({
    name: "userLogin",
    props: {},
    components: { ui_btn_1, ui_input },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        let loginType = ref(2);//登錄類型, 1為短信驗證碼登錄, 2為密碼登錄。
        const btnTxt = 'get verify code';
        let sendSmsBtnTxt = ref(btnTxt);
        let ui_model = computed(() => {
            return store.state.userHubStore.ui_model;
        });

        let formVal = reactive(common.form.loginVal)
        let errTxt = reactive(common.form.loginErrTxt);
        let checkErr = reactive({
            check_te_err: computed(() => {
                return common.form.loginCheckErr.check_te_err(formVal.telmail);
            }),
            check_t_err: computed(() => {
                return common.form.loginCheckErr.check_t_err(formVal.telmail);
            }),
            check_pw_err: computed(() => {
                return common.form.loginCheckErr.check_pw_err(formVal.pw);
            }),
            check_vc_err: computed(() => {
                return formVal.verify_code && !(common.form.reg.specialCharREG.test(formVal.verify_code));
            }),
        })

        let canLoginTag = computed(() => {
            if (loginType.value == 2) {
                return (formVal.telmail && formVal.pw) && (!checkErr.check_te_err && !checkErr.check_pw_err)
            }
            else {
                return (formVal.telmail && formVal.verify_code) && (!checkErr.check_t_err && !checkErr.check_vc_err)
            }
        })

        const showRegUI = () => {
            store.commit("userHubStore/set_showUserReg", true);
            common.reportRefereeUser();
        };
        const switchLoginType = () => {
            if (loginType.value == 1) {
                loginType.value = 2
            }
            else {
                loginType.value = 1;
            }
        }

        const resetPW = () => {
            store.commit('userHubStore/set_showUserRestPW', true)
        }
        window.resetPW = resetPW;

        const sendLoginSMS = () => {
            if (formVal.telmail && loginType.value && sendSmsBtnTxt.value == btnTxt) {
                const sendAccountType = loginType.value == 1 ? 'phones' : 'emails';

                common.sendSMS(sendAccountType, formVal.telmail, common.sendSmsType.login, sendSmsBtnTxt, btnTxt, function (r) {
                    if (r.data.debug_verify_code && !common.isProduction) { formVal.verify_code = r.data.debug_verify_code; }
                });
            }
        }

        const login = async function () {
            if (canLoginTag.value) {
                common.loginByUser(formVal.telmail, formVal.pw, loginType.value, function () {
                    store.commit('set_loginGameType', 1);
                    //查詢綁定關係
                    common.queryUserBindInfo(formVal.telmail, 'login');
                    formVal.telmail = '';
                    formVal.pw = '';
                    console.log('--lb', formVal.telmail, formVal.pw);
                });
            }
        };
        onMounted(() => {
            formVal.telmail = ''; formVal.pw = '';
        });

        var lang = computed(() => {
            return store.state.lang;
        });
        var langTxt = store.state.text;
        return {
            langTxt,
            lang, ui_model, showRegUI, login, loginType, switchLoginType, sendLoginSMS, sendSmsBtnTxt, btnTxt, formVal, errTxt, checkErr, canLoginTag, resetPW
        };
    },
});