export default {
  namespaced: true,
  state: {
    game:1,
    loginByWallet: function(){}
  },
  actions: {},
  mutations: {
    set_loginByWallet(state, payload){
      state.loginByWallet = payload;
    },
  },
}
