import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/home/Home.vue' //首页组件需要引入进来

//路由的配置数组
// path 必填。对应地址路径必须以 / 开头
// component 必填。对应的的路由组件
// name 路由的名字
const routes = [
  {
    path: '/', //首页
    name: 'home',
    component: Home //首页对应的组件，已用import引入
  },
  {
    path: '/signup', //注册
    name: 'signup',
    component: Home
  },
  {
    path: '/recover', //找回密码
    name: 'recover',
    component: Home
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products.vue')
  },
  {
    path: '/story',
    name: 'story',
    component: () => import('../views/story.vue')
  },
  {
    path: '/whitepaper',
    name: 'whitepaper',
    component: () => import('../views/whitepaper.vue')
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: () => import('../views/roadmap.vue')
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('../views/exchange.vue')
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import('../views/recommend.vue')
  },
  {
    path: '/mall',
    name: 'mall',
    component: () => import('../views/mall.vue')
  },
  {
    path: '/game_stats',
    name: 'game_stats',
    component: () => import('../views/game_stats.vue')
  },
  {
    path: '/blockwar', //战场
    name: 'blockwar',
    folder: 'game_blockwar',//游戏文件存放的服务器文件夹名
    component: () => import('../views/game_blockwar.vue')
  },
  {
    path: '/earn',//生产
    name: 'earn',
    folder: 'game_earn',//游戏文件存放的服务器文件夹名
    component: () => import('../views/game_earn.vue')
  },
  {
    path: '/hashgame', //赛跑
    name: 'hashgame',
    folder: 'hashgame',//游戏文件存放的服务器文件夹名
    component: () => import('../views/hashgame.vue')
  },
  {
    path: '/dice',//dice
    name: 'dice',
    folder: 'game_dice',//游戏文件存放的服务器文件夹名
    component: () => import('../views/game_dice.vue')
  },
  {
    path: '/union',//工会
    name: 'union',
    folder: 'game_union',//游戏文件存放的服务器文件夹名
    component: () => import('../views/game_union.vue')
  },
  {
    path: '/race', //赛跑
    name: 'race',
    folder: 'game_race',//游戏文件存放的服务器文件夹名
    component: () => import('../views/game_race.vue')
  },
  {
    path: '/roll', //猜大小
    name: 'roll',
    folder: '',//游戏文件存放的服务器文件夹名
    component: () => import('../views/roll/roll.vue')
  },
  {
    path: '/chat', //聊天
    name: 'chat',
    component: () => import('../views/chat/chat.vue')
  },
  {
    path: '/center', //个人中心
    name: 'center',
    component: () => import('../views/center/center.vue')
  },
  {
    path: '/hashtalk', //IM
    name: 'hashtalk',
    component: () => import('../views/hashtalk.vue')
  },
  {
    path: '/accelerator', //VPN
    name: 'accelerator',
    component: () => import('../views/accelerator.vue')
  },
  {
    path: '/vpn', //VPN
    name: 'vpn',
    component: () => import('../pages/vpn/vpn.vue'),
    children: [//这种方式，component会被渲染到vpn.vue里的<router-view> 内部
      {
        path: 'home',
        name: 'vpn_home',
        component: () => import('../pages/vpn/download.vue'),
      },
      {
        path: 'signup',
        name: 'vpn_signup',
        component: () => import('../pages/vpn/signup.vue'),
      },
      {
        path: 'user',
        name: 'vpn_user',
        component: () => import('../pages/vpn/user.vue'),
      },
    ]
  },
  {
    path: '/u', //大钱包
    name: 'u',
    component: () => import('../views/u.vue'),
    children: [//这种方式，component会被渲染到u.vue里的<router-view> 内部
      {
        path: '',
        name: 'u_',
        component: () => import('../components/u/u_component.vue'),
      },
      {
        path: 'detail',
        name: 'u_detail',
        component: () => import('../components/u/Detail_v2.vue'),
      },
    ]
  },
  // 免费领福利
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/welfare/welfare.vue'),
  },
  // 盲盒
  {
    path: '/box',
    name: 'box',
    component: () => import('../views/BlindBox/BlindBox.vue')
  },
  // 易币付
  {
    path: '/ybf',
    name: 'ybf',
    component: () => import('../views/ybf/ybf.vue')
  },
  {
    path: '/ybftest',
    name: 'ybftest',
    component: () => import('../views/ybf/test.vue')
  },
]

//创建路由对象
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
