export default {
    didntFindMetaMask: 'Please install the MetaMask plug-in and bind it to binance chain.',
    buySuccess: 'Purchased blind box successfully',
    loginNoComplete: 'The last login is not complete, please manually click the fox icon in the plug-in bar to log in',
    wantQuite: 'Do you want to quit?',
    inputTelMailErr: 'Please enter your email',
    // inputTelMailErr: 'Please enter your email or mobile phone number',
    inputTelErr: 'Please enter your mobile phone number',
    pwDiff: 'The two inputs are different',
    pwLentErrTxt: 'The length must be between 6 and 12',
    specialErrTxt: 'Cannot contain special characters',
    nickErrtxt: 'Cannot contain special characters And length be between 1 and 8',
    verifycodeErrtxt: 'The verification code is incorrect',
    sendSuccess: 'It was sent successfully',
    inputIsInvalidt: 'The input is invalidt',
    opSuccess: 'Operation is successful',
    alreadyBound: 'It is already bound',
    bindAgameAccout: 'Please bind a game account.'
}