import { defineComponent, reactive, ref, computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import axios from "axios";
import common from "../../utils/common";

import ui_btn_1 from "../ui/ui_btn_1.vue";
import ui_input from "../ui/ui_input.vue";

export default defineComponent({
    name: "userLogin",
    props: {},
    components: { ui_btn_1, ui_input },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        var lang = computed(() => {
            return store.state.lang;
        });
        const btnTxt = lang=='en'?'get verify code':'獲取驗證碼';
        let sendSmsBtnTxt = ref(btnTxt);

        let formVal = reactive({
            telmail: '',
            pw1: '',
            pw2: '',
            verify_code: '',
        })
        let errTxt = reactive({
            tErrTxt: lang=='en'?'Please enter your email':'請輸入郵箱',
            // tErrTxt: common.msg.inputTelMailErr,
            specialErrTxt: common.msg.specialErrTxt,
            vcErrTxt: lang.value=='en'?'The verification code is incorrect':'驗證碼錯誤',
            pwErrTxt: '',
        });
        let checkErr = reactive({
            check_te_err: computed(() => {
                return formVal.telmail && !common.form.reg.eREG.test(formVal.telmail);
                // return formVal.telmail && (!(common.form.reg.tREG.test(formVal.telmail) || common.form.reg.eREG.test(formVal.telmail)));
            }),
            check_t_err: computed(() => {
                return formVal.telmail && !(common.form.reg.tREG.test(formVal.telmail));
            }),
            check_pw_err: computed(() => {
                if (formVal.pw1 && (formVal.pw1.length > 12 || formVal.pw1.length < 6)) {
                    errTxt.pwErrTxt = lang.value=='en'?'The length must be between 6 and 12':'字符數量需要在6至12之間';
                    return true;
                }
                else if (formVal.pw1 && !common.form.reg.specialCharREG.test(formVal.pw1)) {
                    errTxt.pwErrTxt = common.msg.specialErrTxt;
                    return true
                }
                else if (formVal.pw1 && formVal.pw2 && formVal.pw1 != formVal.pw2) {
                    errTxt.pwErrTxt = lang.value=='en'?'The two inputs are different':'两次输入的信息不一致';;
                    return true
                }
                else {
                    return false;
                }
            }),
            check_vc_err: computed(() => {
                return formVal.verify_code && !(common.form.reg.specialCharREG.test(formVal.verify_code));
            }),
        })

        let canResetTag = computed(() => {
            return (formVal.telmail && formVal.verify_code && formVal.pw1 && formVal.pw2) && (!checkErr.check_te_err && !checkErr.check_vc_err && !checkErr.check_pw_err)
        })



        const sendResetPwSMS = () => {
            if (formVal.telmail && sendSmsBtnTxt.value == btnTxt) {
                common.sendSMS('emails', formVal.telmail, common.sendSmsType.restPW, sendSmsBtnTxt, btnTxt, function (r) {
                    if (r.data.debug_verify_code && !common.isProduction) { formVal.verify_code = r.data.debug_verify_code; }
                });
            }
        }

        const resetPW = () => {
            if (canResetTag) {
                const sendUrl = common.userApi.requestUrl + '/user_api/accounts/' + formVal.telmail + '/passwd';
                const sendData = common.buildSendData({
                    account_type: 2,
                    verify_code: formVal.verify_code,
                    password: formVal.pw1
                });
                const sendHeaders = common.buildHeaders("form");
                common.showLoading('resetPW');
                axios.post(sendUrl, sendData, { headers: sendHeaders })
                    .then((r) => {
                        console.log('--lb', r);
                        if (r.data.code == 0) {
                            toastr['success'](r.data.msg);
                            store.commit('userHubStore/set_showUserHub', true);
                        }
                        else {
                            toastr['error'](r.data.msg);
                        }
                    }).catch((e) => {
                        toastr['error'](e);
                    }).finally(() => {
                        common.hideLoading('resetPW');
                    });
            }
        };
        const showLoginUI = () => {
            store.commit('userHubStore/set_showUserHub', true);
        }

        return { lang,resetPW, sendResetPwSMS, sendSmsBtnTxt, btnTxt, formVal, errTxt, checkErr, canResetTag, showLoginUI };
    },
});