import { computed, defineComponent, ref, } from "vue";
import { useStore } from "vuex";
import web3Tools from "../../utils/web3Tools";
// import { useRouter } from "vue-router";
import common from "../../utils/common";

import ui_btn_1 from "../../components/ui/ui_btn_1.vue";
import ui_input from "../../components/ui/ui_input.vue";
import getVRC from '@/components/userHub/getVRC.vue'
import axios from "axios";

export default defineComponent({
    name: "userReg",
    props: {},
    components: { ui_btn_1, ui_input,getVRC },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        var lang = computed(() => {
            return store.state.lang;
        });
        var langTxt = store.state.text;

        let ui_model = computed(() => {
            return store.state.userHubStore.ui_model;
        });

        let needVerityCode = store.state.needVerityCode; //是否開啟註冊驗證碼
        console.log('--lb', 'needVerityCode', needVerityCode)

        let nick = ref('');
        let telmail = ref(null);
        let pw1 = ref(null);
        let pw2 = ref(null);

        const showLoginUI = () => {
            store.commit("userHubStore/set_showUserLogin", true);
        };

        //驗證nick
        let checkNickTag = computed(() => {
            if (!nick.value) {
                return true;
            }
            else {
                return (common.form.reg.specialCharREG.test(nick.value) && nick.value.length > 0 && nick.value.length < 9);
            }

        });
        const nickErrtxt = common.msg.nickErrtxt;

        //驗證郵箱手機輸入
        let ttRes = computed(() => {
            return common.form.reg.tREG.test(telmail.value);
        });
        let etRes = computed(() => {
            return common.form.reg.eREG.test(telmail.value) && telmail.value.length < 31;
        });
        let errClass1Tag = ref(false);
        const inputTelMailErrTxt = common.msg.inputTelMailErr;
        const telmailBlur = () => {
            if (telmail.value) {
                if (!ttRes.value && !etRes.value) {
                    errClass1Tag.value = true;
                }
            }
        };
        const telmailFocus = () => {
            errClass1Tag.value = false;
        };

        //各種判斷條件
        //是否為手機號和郵箱
        const checkTelMailErrTag = computed(() => {
            if (ttRes.value) {
                accountType.value = 1;
            }
            else {
                accountType.value = 2;
            }
            return (!etRes.value && telmail.value);
        })
        //驗證密碼輸入
        const pwDiffErrTxt = common.msg.pwDiff;
        const pwLentErrTxt = common.msg.pwLentErrTxt;
        const specialErrTxt = common.msg.specialErrTxt;
        let checkPW_special_res = computed(() => {
            return false; //允許密碼含特殊字符
            // return !common.form.reg.specialCharREG.test(pw1.value);
        });
        //丙次密碼是否相符
        const pwDiffTag = computed(() => {
            return (pw1.value && pw2.value && pw2.value != pw1.value);
        })
        //密碼是否6-12位
        const pwLengTag = computed(() => {
            let pw1String = pw1.value + '';
            return (pw1.value && (pw1String.length > 12 || pw1String.length < 6));
        })

        //驗證碼
        let phone_num = ref(); //手机号
        let accountType = ref(1); //1為手機號碼，2為郵箱
        let verify_code = ref();
        const btnTxt = langTxt[lang.value].register[6];
        let sendSmsBtnTxt = ref(btnTxt);
        let checkVerifyCode = computed(() => {
            return (verify_code.value && common.form.reg.specialCharREG.test(verify_code.value))
        });

        const sendRegSMS = () => {
            if (telmail.value && accountType.value && sendSmsBtnTxt.value == btnTxt) {
                const sendAccountType = accountType.value == 1 ? 'phones' : 'emails';

                common.sendSMS(sendAccountType, telmail.value, common.sendSmsType.reg, sendSmsBtnTxt, btnTxt, function (r) {
                    if (r.data.debug_verify_code && !common.isProduction) {
                        verify_code.value = r.data.debug_verify_code;
                    }
                });
            }
        }



        //是否可以註冊
        const regTag = computed(() => {
            return (
                // checkNickTag.value && //暱稱不能有特殊符號
                telmail.value //郵箱和手機號有填
                && (ttRes.value || etRes.value) //郵箱和手機號正確
                && (pw1.value && pw2.value) //有填兩次密碼
                && (pw1.value == pw2.value) //兩次密碼相同
                && !pwLengTag.value //密碼長度ok
                && verify_code.value //有输入验证码
                && phone_num.value //有输入验证码
                // && !checkPW_special_res.value //沒有特殊字符
                // && checkVerifyCode.value //驗證碼符合要求

            );
        });

        //註冊
        const reg = () => {
            if (accountType.value == 1) {
                toastr['error'](lang=='en'?'Please enter your email':'請輸入郵箱');
                return;
            }
            if (regTag.value) {
                let xbd = common.getQueryVariable('xbd');
                if (xbd) {
                    localStorage.setItem('xbd', xbd)
                }
                else {
                    xbd = localStorage.getItem('xbd');
                }
                const tm = common.stringTrim(telmail.value);
                const sendUrl = common.userApi.requestUrl + '/user_api/users';
                const sendHeaders = common.buildHeaders('form');
                const senddata = {
                    // verify_code: common.stringTrim(verify_code.value),
                    password: common.stringTrim(pw2.value),
                    // nick: common.stringTrim(nick.value)
                    domain_name: common.domainName,
                }
                if (needVerityCode) {
                    senddata.verify_code = common.stringTrim(verify_code.value);
                    senddata.phone_num = common.stringTrim(phone_num.value)
                }
                accountType.value == 1 ? '' : senddata.email = tm;
                const invite_code = common.getQueryVariable('invite_code');
                // 推薦碼和xbd
                if (invite_code) {
                    senddata.invite_code = invite_code;
                }
                if (xbd) {
                    senddata.xbd = xbd;
                }
                const sendData = common.buildSendData(senddata);
                common.showLoading("reg");
                axios.post(sendUrl, sendData, { headers: sendHeaders }).then((r) => {
                    console.log('--lb', r);
                    if (r.data.code == 0) {
                        //提示將轉為登錄
                        common.confirm({
                            showConfirmTag: true,
                            type: 'success',
                            title: langTxt[lang.value].register[7],
                            text: langTxt[lang.value].register[8],
                            btnTxt: langTxt[lang.value].register[9],
                            showCancleBtn: false,
                            confirmFn: function (r) {
                                //顯示登錄框
                                store.commit('userHubStore/set_showUserHub', true);
                                //關閉確認框
                                store.commit("set_showConfirmTag", false);
                                telmail.value = null;
                                pw1.value = null;
                                pw2.value = null;
                            },
                            cancelFn: function (r) {
                                store.commit("set_showConfirmTag", false);
                            }
                        })
                    }
                    else {
                        common.confirm({
                            showConfirmTag: true,
                            type: 'warn',
                            title: r.data.msg,
                            text: '',
                            btnTxt: 'OK',
                            showCancleBtn: false,
                            confirmFn: function (r) {
                                //關閉確認框
                                store.commit("set_showConfirmTag", false);
                            },
                            cancelFn: function (r) {
                                store.commit("set_showConfirmTag", false);
                            }
                        })
                    }
                }).catch((e) => {
                    console.log('--lb', e)
                }).finally(() => {
                    common.hideLoading('reg');
                })
            }
        };

        //錢包授權並取得uid和token後，補充錢包賬戶的賬號密碼資料
        const completeWallet = function () {
            web3Tools.formwalletToKey([store.state.userGameAccount], function (r) {
                common.loginBlockchain(r)
                    .then((r) => {
                        console.log('--lb', 'completeWallet', r);
                        const sendUrl = common.userApi.requestUrl + '/user_api/accounts/' + telmail.value + '/bind_infos';
                        const sendData = common.buildSendData({
                            type: 2,
                            passwd: pw1.value
                        })
                        const sendHeaders = common.buildHeaders('form');
                        common.showLoading('completeWallet');
                        axios.post(sendUrl, sendData, { headers: sendHeaders })
                            .then((r) => {
                                console.log('--lb', r);
                                if (r.data.code != 0) {
                                    toastr['error'](r.data.msg);
                                }
                                else {
                                    toastr['success'](r.data.msg);
                                    //將新值寫入緩存和state
                                    const loginRes = store.state.loginRes;
                                    loginRes.uinfo.email = telmail.value;
                                    common.setUserInfo({ data: loginRes })
                                    //關閉對話框
                                    store.commit('userHubStore/set_showUserHub', false);
                                    telmail.value = null;
                                    pw1.value = null;
                                    pw2.value = null;

                                    //重載頁面
                                    location.reload(true);

                                    //查詢綁定關係
                                    // common.queryUserBindInfo(store.state.userGameAccount, 'completeWallet')
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                toastr['error'](r.data.msg);
                            })
                            .finally(() => {
                                common.hideLoading('completeWallet');
                            });
                    })
                    .catch((e) => {
                        console.error(e)
                    });
            });

        }

        const sendPhone = (phone)=>{
            console.log(phone)
            phone_num.value = phone;
        }
        const sendVRC = (VRC)=>{
            console.log(VRC)
            verify_code.value = VRC;
        }


        return {
            sendPhone,sendVRC,
            langTxt,
            lang,
            completeWallet,
            ui_model,
            nick,
            telmail,
            pw1,
            pw2,
            showLoginUI,
            telmailBlur,
            telmailFocus,
            ttRes,
            etRes,
            errClass1Tag,
            inputTelMailErrTxt,
            pwDiffErrTxt,
            pwLentErrTxt,
            specialErrTxt,
            reg,
            checkNickTag, nickErrtxt, checkPW_special_res, pwDiffTag, checkTelMailErrTag, pwLengTag, verify_code, checkVerifyCode, sendRegSMS, sendSmsBtnTxt, btnTxt, accountType, regTag, needVerityCode
        };
    },
});