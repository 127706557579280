
import OpenBlindBox from '../../abis/OpenBlindBox.json';

import { defineComponent, ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ui_btn_1 from '@/components/ui/ui_btn_1'
import appDownload from '../app_download/app_download.vue';

export default defineComponent({
    name: "minigame",
    props: {},
    components: { ui_btn_1,appDownload },
    setup(props, ctx) {
        let router = useRouter();
        let store = useStore();
        let cdnUrl = computed(() => {
            return store.state.cdnUrl;
        });
        let icoOn = ref(false);
        let pluse = ref(false);
        const switchOn = () => {
            icoOn.value = !icoOn.value;
        };
        let lang = computed(() => {
            return store.state.lang;
        });
        let langTxt = store.state.text;

        const gameList = [
            {
                name: "Are you ready?",
                path: ["earn"],
                intro: 'Enter the world of Block War. <br />Your adventure awaits.',
                btn: ['play game']
            }
            /* {
                name: "blind box",
                path: "",
                intro: 'entrance bind box',
                btn: ['blind box']
            },
            {
                name: "game",
                path: ["g?game_id=10011", "g?game_id=10012"],
                intro: 'entrance game',
                btn: ['game low', 'game high']
            },
            {
                name: "game a",
                path: ["ga?game_id=10011", "ga?game_id=10012"],
                intro: 'entrance game a',
                btn: ['game a low', 'game a high']
            },
            {
                name: "game b",
                path: ["sieve"],
                intro: 'entrance game b',
                btn: ['start game b']
            }, */
            // {
            //     name: "TURNTABLE",
            //     path: [""],
            //     intro: 'entrance TURNTABLE',
            //     btn: ['start game']
            // },
            // {
            //     name: "RACE",
            //     path: [''],
            //     intro: "entrance RACE",
            //     btn: ['start game']
            // },
            // {
            //     name: "SIEVE",
            //     path: ['sieve'],
            //     intro: "entrance SIEVE",
            //     btn: ['start game']
            // },
        ];
        let currentGameIndex = ref(0);
        const showInfo = (inx) => {
            currentGameIndex.value = inx;
        }
        showInfo(0);
        const toPage = function (targetPage) {
            if (targetPage.indexOf('?') >= 0) {
                router.push("/" + targetPage + location.search.replace('?', '&') + '&language=' + lang.value);
            }
            else {
                if (location.search) {
                    router.push("/" + targetPage + location.search + "&language=" + lang.value);
                }
                else {
                    router.push("/" + targetPage + "?language=" + lang.value);
                }
            }
        };
        const toStats = function () {
            router.push("/game_stats" + location.search);
            store.commit('set_menuIndex', 3);
            localStorage.setItem('menuIndex', 3);
        };

        //抽盲盒
        // const blindbox = () => {
        //     let userAccount = common.LOGIN_SESSION.auth.actor;
        //     if (userAccount) {
        //         web3Tools.approve(userAccount);
        //     }
        //     else {
        //         toastr['error']('Please login')
        //     }
        // }
        let rotate = ref(true);
        let SI, ST;

        let apiReadyTag = computed(()=>{
            return store.state.apiReadyTag;
        });
        onMounted(() => {
            setTimeout(() => {
                rotate.value = false;
            }, 100)
            SI = setInterval(function () {
                pluse.value ? pluse.value = false : pluse.value = true
            }, 3000)
        });
        onUnmounted(() => {
            clearInterval(SI);
        })

        return {
            apiReadyTag,
            cdnUrl,lang, langTxt,
            icoOn, switchOn, toPage, toStats, gameList, currentGameIndex, showInfo, gameList, pluse, rotate
            // blindbox
        };
    },
});