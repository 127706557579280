<template>
  <!-- <pageTop :menuIndex="0"></pageTop> -->
  <minigame></minigame>
  <game_enter v-if="apiReadyTag" class="onlypc"></game_enter>
  <appDownload class="onlymobile"></appDownload>
  <currency> </currency>
  <introduction2></introduction2>
  <tools></tools>
  <camp></camp>
  <pagebottom v-if="apiReadyTag"></pagebottom>
  <sysPopup v-if="apiReadyTag"></sysPopup>
  <div id="welfare" @click="toVip" v-if="userInfoReadyTag && apiReadyTag">
    <!-- <img src="../../assets/img/icon_welfare.png" alt="" />-->
    <frameAni :duration="2" :imgSrc="'//cdn.blockwar.io/web_assets/img/welfare/frame/'" :imgCount="24" :imgWidth="'1rem'"
      :imgHeight="'1rem'" />
  </div>
</template>
<script>
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import pageTop from "../../components/head/pageTop.vue";
import minigame from "../../components/minigame/minigame.vue";
import currency from "../../components/currency/currency.vue";
import introduction2 from "../../components/introduction/introduction2.vue";
import tools from "../../components/tools/tools.vue";
import camp from "../../components/camp/camp.vue";
import pagebottom from "../../components/pagebottom/pagebottom.vue";
import appDownload from "../../components/app_download/app_download.vue";
import sysPopup from "../../components/sysPopup/sysPopup.vue";
import game_enter from "../../components/game_enter/game_enter.vue";
import frameAni from "../../components/sysPopup/frame.vue";

export default defineComponent({
  name: "home",
  components: {
    // pageTop,
    minigame,
    currency,
    introduction2,
    tools,
    camp,
    pagebottom,
    appDownload,
    sysPopup,
    game_enter,
    frameAni,
  },
  setup() {
    let store = useStore();
    let router = useRouter();
    let userInfoReadyTag = computed(() => {
      return store.state.userInfoReadyTag;
    });
    const toVip = () => {
      router.push('/u');
    };
    let apiReadyTag = computed(() => {
      return store.state.apiReadyTag;
    });
    onMounted(() => {
      store.commit("set_showPageTopNav", true);
    });
    return { apiReadyTag, userInfoReadyTag, toVip };
  },
});
</script>
<style lang="scss">
.h2 {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -0.2rem;
    left: 0;
    width: 0.4rem;
    height: 0.03rem;
    background: url("https://cdn.blockwar.io/web_assets/img/embellish_1.png") no-repeat;
    background-size: 100% auto;
  }
}

#welfare {
  position: fixed;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  right: 0.5rem;
  top: calc(var(--navHeight) + 0.5rem);
  z-index: 1;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
