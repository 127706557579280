<template>
  <div class="camp">
    <div class="layout">
      <div class="content">
        <img class="ico" :src="cdnUrl + '/web_assets/img/camp_ico.png'" alt="" />
        <h2 class="mortina">{{ langTxt[lang].index.creative[0] }}</h2>
        <p>{{ langTxt[lang].index.creative[1] }}</p>

        <ui_btn_1 :width="'2.28rem'" @click="playgame">{{
          langTxt[lang].index.creative[2]
        }}</ui_btn_1>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.camp {
  color: #fff;

  .layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 6rem;
    text-align: center;
    background: url("https://cdn.blockwar.io/web_assets/img/camp.png") no-repeat top
      center/auto 100%;

    h2 {
      font-size: 0.32rem;
    }

    p {
      font-size: 0.2rem;
      line-height: 0.24rem;
      width: 4.21rem;
    }
  }

  .ico {
    width: 1.7rem;
  }

  p {
    color: #fff;
    font-size: 0.36rem;
    line-height: 0.43rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
}
</style>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import ui_btn_1 from "../ui/ui_btn_1.vue";

export default defineComponent({
  name: "camp",
  props: {},
  components: {
    ui_btn_1,
  },
  setup(props, ctx) {
    let router = useRouter();
    let store = useStore();
    let cdnUrl = computed(() => {
      return store.state.cdnUrl;
    });
    const playgame = () => {
      router.push("/earn");
      if (location.search) {
        router.push("/earn" + location.search + "&language=" + lang.value);
      } else {
        router.push("/earn?language=" + lang.value);
      }
    };
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;

    return {
      langTxt,
      lang,
      playgame,
      cdnUrl,
    };
  },
});
</script>
