import axios from "axios";
// import qs from 'qs'
// import FingerprintJS from '@fingerprintjs/fingerprintjs';
import msg from "./msg";
import form from "./form";

const appVer = "1.2.4.20240607";
console.warn("--lb", "common", "appVer", appVer);
const PLATFORM = 3;
const VENDOR = "web browser";
const APPID = 10001;
const LOCALE = "en";
let store;
let router;
//從url的search參數中讀取數據
const getQueryVariable = function (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].replace("=", "【").split("【");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};
var visitorId = getRandom();
let userApi = {
  identifier: "BLOCK WAR",
  requestUrl: "",
  uid: getQueryVariable("uid"),
  token: getQueryVariable("token"),
  visitorId: visitorId,
  platform: getQueryVariable("platform"),
  appType: getQueryVariable("type") || 0,
  Platform: 3,
  Vendor: "web browser",
  AppId: "10001",
  Locale: "en",
};
if (localStorage.getItem("apiUrl")) {
  userApi.requestUrl = localStorage.getItem("apiUrl");
}
console.log("--lb", userApi);
//複製插件
let toClipboard;

var headers = {
  "X-App-Uid": "",
  "X-App-Token": "",
};

// 泛指所有非vpn的，用url参数登录
const isInElectron = function () {
  const res = (getQueryVariable("platform") && getQueryVariable("uid") && getQueryVariable("token") && location.href.indexOf("/vpn") < 0) || window.electron ? true : false;
  if (res) {
    console.warn("--lb", "common", "isInElectron", res, location.href);
  }
  return res;
};

const buildDeviceToken = function () {
  //device-token獲取邏輯：優先級3：生成，優先級2：取本地緩存，優先級1：讀url參數。
  if (getQueryVariable("device-token")) {
    visitorId = getQueryVariable("device-token");
  } else {
    const localVisitorId = localStorage.getItem("visitorId");
    if (localVisitorId && localVisitorId.length == 32) {
      visitorId = localVisitorId;
    } else {
      visitorId = getRandom();
    }
  }
  localStorage.setItem("visitorId", visitorId);
  return visitorId;
};
let LOGIN_SESSION = { auth: { actor: "" } };
const loginout = function (lineNum = "null") {
  console.warn("--lb", "common", "common.loginout", lineNum);
  if (store) {
    store.commit("vpn/set_uid", null);
    store.commit("vpn/set_token", null);
    store.commit("vpn/set_loginInfo", null);
    store.commit("vpn/set_showTabIndex", null);
    store.commit("vpn/set_walletAddress", null);
    store.commit("set_loginRes", null);
    store.commit("set_userInfo", null);
    store.commit("set_uid", null);
    store.commit("set_userAccount", null);
    store.commit("set_userGameAccount", null);
    store.commit("set_walletLoginName", null);
    store.commit("set_loginGameType", null);
    store.commit("set_bindState", null);
    store.commit("set_email", null);
    store.commit("set_phone_num", null);
    store.commit("userHubStore/set_showUserHub", false);
    // store.commit('set_loginBtnClickTag', null);
    store.commit("set_showConfirmTag", false);
  }
  localStorage.removeItem("block-war-public_key");
  localStorage.removeItem("block-war-private_key");
  localStorage.removeItem("block-war-loginRes");
  localStorage.removeItem("msgContainer");
  localStorage.removeItem("token");
  localStorage.removeItem("uid");
  localStorage.removeItem("sendHeaders");

  LOGIN_SESSION.auth.actor = null;
  try {
    if (Moralis && Moralis.User) {
      Moralis.User.logOut();
    }
  } catch (e) {
    console.log("common: Moralis.User.logOut()", e);
  }
  // 广播退出
  if (top.bc) {
    top.bc.postMessage("logout");
  }
};

//将准备传给游戏的header写入本地缓存
const buildSendToGameHeader = function (uid, token) {
  let sendGameHeaders = {
    Accept: "application/json, text/plain, */*",
    "X-App-Uid": uid,
    "X-App-Token": token,
    "X-Sys-Device-Token": localStorage.getItem("visitorId"),
    "X-Sys-Platform": PLATFORM,
    "X-Sys-Vendor": VENDOR,
    "X-App-Id": APPID,
    "X-App-Locale": LOCALE,
    "X-App-Ver": appVer,
  };
  //写入缓存，传给游戏等
  localStorage.setItem("sendHeaders", JSON.stringify(sendGameHeaders));
  console.warn("!!!!sendGameHeaders", sendGameHeaders, "code line: 133", localStorage.getItem("sendHeaders"));
};

axios.defaults.timeout = 60000; //設置超時時間，單位毫秒
axios.defaults.retry = 3; //設置全局請求次數
axios.defaults.retryDelay = 1000; //設置全局請求間隙
// 添加響應攔截器
axios.interceptors.response.use(
  function (response) {
    if (response && response.data && response.data.code !== 0) {
      console.warn("response.data.code!==0", response.data.code, response.config, response.config.url);
    }
    //需要重新登錄
    if (response.data.code == 21 || response.data.code == 30 || response.data.code == 5) {
      //退出
      loginout(21305 + ":" + response.data.code + ";" + response.config.url);
      if (response.data.code == 30) {
        toastr["error"](response.data.msg);
      }
      return;
    }
    if (response.request.responseURL.indexOf("/user_api/sessions") > 0) {
      console.warn("!!!!response", response);
      if (response.data.code == 0) {
        //登录成功才有uid和token
        buildSendToGameHeader(response.data.uinfo.uid, response.data.token);
      }
    }
    return response;
  },
  function (error) {
    // 對響應錯誤做點什麼
    var config = error.config;
    store.commit(
      "set_appErrorLog",
      `axios MSG: ${error.message}<br /> URL: ${error.config.url}<br />DATA: ${typeof error.config.data == "string" ? error.config.data : JSON.stringify(error.config.data)}`
    );
    // 如果配置不存在或未設置重試選項，則返回錯誤信息
    if (!config || !config.retry) return Promise.reject(error.response.data);

    // 設置變量即跟蹤重試次數
    config.__retryCount = config.__retryCount || 0;

    // 檢查我們是否已經超過了總重試次數
    if (config.__retryCount >= config.retry) {
      // 返回錯誤信息
      // toastr['warn']('The network is not running smoothly.')
      return Promise.reject(error.response.data);
    }

    // 重試次數加1
    config.__retryCount++;
    console.log("--lb", "config.__retryCount", config.__retryCount);
    if (config.__retryCount >= 3) {
      if (store) {
        store.commit("showLoading", -1);
        console.log("--lb", "hide loadingNum:", "config.__retryCount >= 3");
        toastr["error"]("Network Error!! " + config.url);
      }
      return;
    }

    // 創建延時器等待發送重試請求
    var backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1);
    });

    // 返回調用AXIOS來重試請求
    return backoff.then(() => {
      // 替換config裡的url,再重試
      if (store && store.state.apiUrlArray[config.__retryCount] && config.url.indexOf(userApi.requestUrl) >= 0) {
        config.url = config.url.replace(userApi.requestUrl, store.state.apiUrlArray[config.__retryCount]);
        console.warn("--lb", "common", "new requestUrl:", config.url);
      }
      return axios.request(config);
    });
  }
);
// 請求攔截器
axios.interceptors.request.use(
  (config) => {
    // 檢查設備token
    config.headers["X-Sys-Device-Token"] = buildDeviceToken();
    config.headers["X-Sys-Platform"] = PLATFORM;
    config.headers["X-Sys-Vendor"] = VENDOR;
    config.headers["X-App-Id"] = APPID;
    config.headers["X-App-Locale"] = LOCALE;
    config.headers["X-App-Ver"] = appVer;
    //uid和token的讀取順序：1、地址欄參數；2、store.state中取；3、store.state.vpn模板取。
    //vpn發請求時，會先清store.state的uid和token，只保留store.state.vpn的uid和token
    if (getQueryVariable("uid")) {
      config.headers["X-App-Uid"] = getQueryVariable("uid");
    } else {
      if (store && store.state.uid) {
        config.headers["X-App-Uid"] = store.state.uid;
      } else if (store && store.state.vpn.uid) {
        config.headers["X-App-Uid"] = store.state.vpn.uid;
      }
    }
    if (getQueryVariable("token")) {
      config.headers["X-App-Token"] = getQueryVariable("token");
      // console.warn('!!!!x-app-token',"getQueryVariable('token')",getQueryVariable('token'), 'code line: 207')
    } else {
      if (store && store.state.loginRes) {
        config.headers["X-App-Token"] = store.state.loginRes.token;
        // console.warn('!!!!x-app-token',"store.state.loginRes.token",store.state.loginRes.token, 'code line: 212')
      } else if (store && store.state.vpn.token) {
        config.headers["X-App-Token"] = store.state.vpn.token;
        // console.warn('!!!!x-app-token',"store.state.vpn.token",store.state.vpn.token, 'code line: 218')
      }
    }
    if (getQueryVariable("type")) {
      config.headers["X-App-Type"] = getQueryVariable("type");
    }
    if (getQueryVariable("deviceId")) {
      config.headers["X-Sys-Device-Token"] = getQueryVariable("deviceId");
    }
    if (getQueryVariable("platform")) {
      config.headers["X-Sys-Platform"] = getQueryVariable("platform");
    }
    if (getQueryVariable("vendor")) {
      config.headers["X-Sys-Vendor"] = getQueryVariable("vendor");
    }
    if (getQueryVariable("appId")) {
      config.headers["X-App-Id"] = getQueryVariable("appId");
    }
    if (getQueryVariable("version")) {
      config.headers["X-App-Ver"] = getQueryVariable("version");
    }
    if (getQueryVariable("locale")) {
      config.headers["X-App-Locale"] = getQueryVariable("locale");
    }
    return config;
  },
  (error) => {
    //do some
    console.log("--lb", "axios.interceptors.request", error);
    return Promise.error(error);
  }
);

//獲取折算為中文的字符長度，如果有第二個參數，則截取指定折算後長度的字符串加上...
function chkHalf(str, limitNum) {
  var charNum = 0;
  for (var i = 0; i < str.length; i++) {
    let strCode = str.charCodeAt(i);
    if ((strCode >= 32 && strCode <= 127) || (strCode >= 65377 && strCode <= 65439)) {
      charNum = charNum + 0.5;
    } else {
      charNum++;
    }

    if (limitNum && charNum >= limitNum - 2) {
      return str.slice(0, i) + "...";
    }
  }
  if (limitNum) {
    return str;
  } else {
    return charNum;
  }
}

const CryptoJS = require("crypto-js"); //引用AES源碼js
const key = CryptoJS.enc.Utf8.parse("32F32F1DBA3BFD53"); //十六位十六進制數作為密鑰
const iv = CryptoJS.enc.Utf8.parse("6689DF345ADF4B85"); //十六位十六進制數作為密鑰偏移量
//解密方法
function Decrypt(word) {
  if (!word) return;
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

//加密方法
function Encrypt(word) {
  if (!word) return;
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString().toUpperCase();
}

//字符串轉base64
function encodeToBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

// base64轉字符串
function decodeFromBase64(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

/*
const isProduction =
  location.hostname != "localhost" &&
  location.hostname != "wargamedev.com" &&
  location.hostname != "www.wargamedev.com" &&
  location.hostname != "war91.vip" &&
  location.hostname != "www.war91.vip" &&
  location.hostname != "bigfun4u.com" &&
  location.hostname != "www.bigfun4u.com"
    ? true
    : false;
*/
const isProduction = true;

//用隨機數生產設備碼
/* const getFinger = async function () {
  const fp = await FingerprintJS.load({ screen_resolution: true })
  const result = await fp.get();
  const visitorId = result.visitorId;
  localStorage['visitorId'] = visitorId;
  return visitorId;
} */
function getRandom() {
  let arr = [];
  arr = arr.concat(getContent("a", "z"));
  arr = arr.concat(getContent("0", "9"));

  let id = "";
  for (let i = 0; i < 32; i++) {
    id += arr[parseInt(Math.random() * 36)];
  }
  return id;
}

function getContent(start, end) {
  let arr = [];
  for (let i = start.charCodeAt(); i <= end.charCodeAt(); i++) {
    arr.push(String.fromCharCode(i));
  }
  return arr;
}

const loadJs = (src) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    document.body.appendChild(script);

    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };
  });
};

const formatUserName = (n, s = 4) => {
  if (n.length > 2 * s + 3) {
    return n.substr(0, s) + "..." + n.slice(-s);
  } else {
    return n;
  }
};

const formatGameName = (server_game_id, lang = "en") => {
  switch (server_game_id) {
    case 10001:
      return Config.Locale == "en" ? "Red Flame Battle(low)" : "赤焰爭霸one";
    case 10002:
      return Config.Locale == "en" ? "Red Flame Battle(high)" : "赤焰爭霸two";
    case 10003:
      return Config.Locale == "en" ? "Pet Run" : "森林賽跑";
    case 10004:
      return Config.Locale == "en" ? "Baccarat" : "百家樂";
    case 10005:
      return Config.Locale == "en" ? "Roulette" : "神龍輪盤";
    case 10006:
      return Config.Locale == "en" ? "Majong" : "二八槓";
    case 10007:
      return Config.Locale == "en" ? "NuNu" : "牛牛";
    case 10008:
      return Config.Locale == "en" ? "Fishing King" : "海底捕魚";
    case 10011:
      return Config.Locale == "en" ? "Red Flame(high)" : "赤焰one(鏈遊)";
    case 10012:
      return Config.Locale == "en" ? "Red Flame(low)" : "赤焰two(鏈遊)";
    default:
      return Config.Locale == "en" ? "Game" : "遊戲";
  }
};

const formatDateTime = (date) => {
  if (date == "" || !date || (date + "").length < 10) {
    return date;
  }
  if ((date + "").length == 10) {
    date = date * 1000;
  }
  var date = new Date(date);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
};

//格式化channel圖標
//1=usdt  2=other  3=usdc  4=busd 5=dia
const formatChannelIcon = (icoArrayStr) => {
  if (!icoArrayStr) {
    return "";
  }
  let a = icoArrayStr.split(",");
  let returnHtml = "";
  let otherHtml = "";
  for (let i = 0; i < a.length; i++) {
    switch (a[i]) {
      case "1":
        returnHtml += '<span data-channel="1" class="icon_channel usdt"></span>';
        break;
      case "2":
        otherHtml += '<span data-channel="2" class="icon_channel other"></span>';
        break;
      case "3":
        returnHtml += '<span data-channel="3" class="icon_channel usdc"></span>';
        break;
      case "4":
        returnHtml += '<span data-channel="4" class="icon_channel busd"></span>';
        break;
      case "5":
        returnHtml += '<span data-channel="5" class="icon_channel dia"></span>';
        break;
    }
  }
  a = null;
  return returnHtml + otherHtml;
};

//篩選金幣
const formatAssetGold = (asset_infos) => {
  for (let i = 0; i < asset_infos.length; i++) {
    if (asset_infos[i].type == 2) {
      return asset_infos[i].value;
    }
  }
};
//篩選银幣
const formatAssetSilver = (asset_infos) => {
  for (let i = 0; i < asset_infos.length; i++) {
    if (asset_infos[i].type == 3) {
      return asset_infos[i].value;
    }
  }
};

// 格式化各種資產圖標和數值
const formatAssetInfos = (asset_infos) => {
  let str = "";
  let calssStr = "";
  for (let i = 0; i < asset_infos.length; i++) {
    switch (asset_infos[i].type) {
      case 1: //鑽石
        calssStr = "ico_diam";
        break;
      case 2: //金幣
        calssStr = "ico_gold";
        break;
      case 3: //銀幣
        calssStr = "ico_silver";
        break;
      case 4: //金牌
        calssStr = "ico_medal_g";
        break;
      case 5: //銀牌
        calssStr = "ico_medal_s";
        break;
      case 6: //魅力
        calssStr = "ico_charm";
        break;
      case 7: //魔豆
        calssStr = "ico_bean";
        break;
      case 8: //活動幣
        calssStr = "ico_event";
        break;
      case "u": //活動幣
        calssStr = "ico_u";
        break;
      default:
        calssStr = "";
    }
    str += `<span class="assets_info"><i class="ico_asset ${calssStr}"></i> ${asset_infos[i].value} </span>`;
  }
  return str;
};

export default {
  buildSendToGameHeader,
  domainName: location.hostname.replace("www.", ""),
  loginout,
  appVer,
  buildDeviceToken,
  toClipboard,
  Decrypt,
  Encrypt,
  encodeToBase64,
  decodeFromBase64,
  loadJs,
  isProduction,
  userApi,
  headers,
  msg,
  form,
  chkHalf,
  formatUserName,
  formatDateTime,
  formatChannelIcon,
  formatGameName,
  formatAssetGold,
  formatAssetSilver,
  formatAssetInfos,
  getQueryVariable,
  loginByWalletSuccessFN: null,
  loginByWalletFailFn: null,
  isInElectron: isInElectron,
  isMobilePhone: (mpn) => {
    return /^([0|\+]\d{1,4}-?)?\d{7,11}$/.test(mpn);
  },
  isEmail: (mail) => {
    return /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(mail);
  },
  chain_tp: ["", "", "BSC", "ETH", "TRON"],
  openTalk: function (toUid) {
    // if (this.isInElectron()) {
    //   console.log('--lb', "call IM: to_main_talk_to");
    //   window.electron.sendMessage("to_main_talk_to", {
    //     uid: toUid,
    //   });
    // }
    // else {
    //   window.open("hashtalk://" + toUid);
    // }
    window.open("hashtalk://chat-to-user/" + toUid);
  },
  showLogin: function () {
    console.log("common.showLogin()");
    const _this = this;
    if (window.loginByWallet) {
      window.loginByWallet(
        ["metamask"],
        function (r) {},
        function (e) {
          // _this.confirm({
          //   type: "info",
          //   title: "Warn",
          //   text: "Please login",
          //   btnTxt: "OK",
          //   showCancleBtn: false,
          //   confirmFn: function (r) {
          //     _this.showLogin();
          //   },
          //   cancelFn: function (r) {
          //     _this.showLogin();
          //   },
          // });
        }
      );
    }
  },
  runLoginCallback: function (rORe, tag = "success") {
    console.log("--lb", "common runLoginCallback", "tag:", tag);
    store.commit("userHubStore/set_showUserHub", false);
    if (this.loginByWalletSuccessFN && tag == "success") {
      if (rORe.sendHeaders) {
        rORe.sendHeaders = JSON.parse(localStorage.getItem("sendHeaders"));
        console.warn("runLoginCallback loginByWalletSuccessFN rORe", rORe);
      }
      this.loginByWalletSuccessFN(rORe);
      this.loginByWalletSuccessFN = null;
    }
    if (this.loginByWalletFailFn && tag != "success") {
      this.loginByWalletFailFn(rORe);
      this.loginByWalletFailFn = null;
    }
    console.warn(
      "window.loginByWallet getStorgeUserInfoOrLogin",
      this.loginByWalletSuccessFN,
      ";",
      this.loginByWalletFailFn,
      ";",
      "localStorage.getItem('sendHeaders')",
      localStorage.getItem("sendHeaders")
    );
  },

  sendSmsType: {
    //驗證碼類型，1為驗證碼登錄, 2註冊，3，為密碼重置，4，為保險箱密碼重置, 5,賬戶綁定
    login: 1,
    reg: 2,
    restPW: 3,
    bindAccount: 5,
  },
  stringTrim: function (x) {
    return (x + "").replace(/^\s+|\s+$/gm, "");
  },
  //1為鑽石，2為金幣，3為銀幣，4為金牌，5為銀牌，6為魅力值，7為魔豆數量, 8為活動幣
  financeType: {
    diamond: 1,
    gold: 2,
    silver: 3,
    goldMedal: 4,
    silverMedal: 5,
    charm: 6,
    magic: 7,
    event: 8,
  },
  getValueByFinanceType: (type, userFinanceInfo) => {
    let res;
    for (let i = 0; i < userFinanceInfo.length; i++) {
      if (userFinanceInfo[i].type == type) {
        res = userFinanceInfo[i].value;
        break;
      }
    }
    return res;
  },

  setStore: function (s) {
    if (!store) store = s;
  },
  getStore: function (s) {
    return store;
  },
  setRouter: function (r) {
    if (!router) router = r;
  },
  // 首頁不顯示Loading
  showLoading: function (source = "") {
    if (store) {
      store.commit("showLoading", 1);
      console.log("--lb", "show loadingNum:", store.state.showLoadingTag, "source:", source);
    }
  },
  hideLoading: function (source = "") {
    if (store) {
      store.commit("showLoading", -1);
      console.log("--lb", "hide loadingNum:", store.state.showLoadingTag, "source:", source);
    }
  },

  //創建請求後端接口時需要的headers
  buildHeaders: function (type = "json", headerData = null) {
    let _headers = JSON.stringify(headers);
    let newHeaders = JSON.parse(_headers);
    if (type == "form") {
      newHeaders["Content-Type"] = "application/x-www-form-urlencoded";
    } else {
      newHeaders["Content-Type"] = "application/json";
    }
    if (headerData) {
      //console.log('--lb','headerData:', headerData);
      for (let k in headerData) {
        newHeaders[k] = headerData[k];
      }
    }
    return newHeaders;
  },
  buildVpnHeaders: function () {
    let _headers = JSON.stringify(headers);
    let newHeaders = JSON.parse(_headers);
    //清除常規登錄
    if (store.state.loginRes || store.state.uid) {
      console.log("common buildVpnHeaders", "clear store uid and loginRes");
      store.commit("set_uid", "");
      store.commit("set_loginRes", "");
    }
    newHeaders["Content-Type"] = "application/x-www-form-urlencoded";
    return newHeaders;
  },
  //格式化請求後端接口的數據
  buildSendData: function (data) {
    let ret = "";
    for (let it in data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    }
    return ret.slice(0, ret.length - 1);
  },
  // 獲取系統配置信息
  getSysConfig: async function (configName = null) {
    let sendUrl = this.userApi.requestUrl + "/misc_api/sys_config/items";
    if (configName) {
      sendUrl += "?names=" + configName;
    }
    const sendHeaders = this.buildHeaders("form");
    const _this = this;
    this.showLoading("getSysConfig");
    await axios
      .get(sendUrl, { headers: sendHeaders })
      .then((r) => {
        if (store && r.data.code == 0) {
          store.commit("set_sysConfig", r.data.items);
          return r.data.items;
        }
      })
      .catch()
      .finally(() => {
        _this.hideLoading("getSysConfig");
        return false;
      });
  },
  //預處理後端接口請求成功的數據
  thenFunction: function (url, response, callback = null) {
    //console.log('--lb',url + ": ", response);
    if (response.data.code == 0) {
      if (callback) callback(response);
    } else {
      toastr["error"](response.data.msg);
    }
  },
  //預處理後端接口請求失敗數據
  catchFunction: function (url, response, callback = null) {
    //console.log('--lb','ERROR: ' + url + ": ", response);
    toastr["error"](response);
    if (callback) callback(response);
  },
  //封裝json格式的post請求
  post: async function (url, data, thenFn, catchFn, finallyFn = null, headerData = null) {
    let $headers = this.buildHeaders("json", headerData);
    await axios
      .post(userApi.requestUrl + url, data, { headers: $headers })
      .then(function (response) {
        //console.log('--lb',url + ": ", response);
        if (response.data.code == 0) {
          thenFn(response);
        } else {
          toastr["error"](response.data.msg);
        }
      })
      .catch(function (response) {
        //console.log('--lb',url + ": ", response);
        catchFn(response);
      })
      .finally(function () {
        if (finallyFn) {
          finallyFn();
        }
      });
  },
  //封裝form格式的post請求
  postFormUrlencoded: async function (url, data, thenFn, catchFn, finallyFn = null, headerData = null) {
    let $headers = this.buildHeaders("form", headerData);
    let ret = "";
    for (let it in data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    }
    await axios
      .post(userApi.requestUrl + url, ret.slice(0, ret.length - 1), {
        headers: $headers,
      })
      .then(function (response) {
        //console.log('--lb',url + ": ", response);
        if (response.data.code == 0) {
          thenFn(response);
        } else {
          toastr["error"](response.data.msg);
        }
      })
      .catch(function (response) {
        //console.log('--lb',url + ": ", response);
        catchFn(response);
      })
      .finally(function () {
        if (finallyFn) {
          finallyFn();
        }
      });
  },

  //所有錢包取得用戶名後，都共用這個SESSION。
  LOGIN_SESSION: LOGIN_SESSION,
  sendPublickeyAndGetKey: async function (public_key) {
    console.log("--lb", "sendPublickeyAndGetKey");
    let _this = this;
    _this.showLoading("sendPublickeyAndGetKey");
    let sendUrl = _this.userApi.requestUrl + `/user_api/block_chain/2/accounts/${_this.LOGIN_SESSION.auth.actor}/metamask/keys`;
    let sendData = _this.buildSendData({
      account: _this.LOGIN_SESSION.auth.actor,
      public_key: public_key,
    });
    let sendHeaders = _this.buildHeaders("form");
    let key;
    await axios
      .post(sendUrl, sendData, { headers: sendHeaders })
      .then((res) => {
        if (res.data.code == 0) {
          console.log("--lb", res);
          key = res;
        } else {
          toastr["error"](res.data.msg);
          console.error(res.data.msg);
        }
      })
      .catch((errRes) => {
        toastr["error"](errRes);
      })
      .finally(() => {
        _this.hideLoading("sendPublickeyAndGetKey");
      });
    return key;
  },
  // 區塊鏈登錄
  loginBlockchain: async function (decryptedMessage) {
    console.log("--lb", "loginBlockchain");
    const _this = this;
    _this.showLoading("loginBlockchain");
    let sendUrl = _this.userApi.requestUrl + "/user_api/block_chain/" + store.state.chain_tp + "/metamask/sessions/";
    let buildJson = {
      account: _this.LOGIN_SESSION.auth.actor,
      key: decryptedMessage,
      domain_name: _this.domainName,
    };
    let channelId = _this.get_channelId();
    if (channelId) {
      buildJson.channel_id = channelId;
    }
    let xbd = _this.get_xbd();
    if (xbd) {
      buildJson.xbd = xbd;
    }
    let sendHeaders = _this.buildHeaders("form");
    let sendData = _this.buildSendData(buildJson);
    if (!sendHeaders["X-Sys-Device-Token"]) {
      // sendHeaders['X-Sys-Device-Token'] = localStorage.getItem('visitorId');
    }
    await axios
      .post(sendUrl, sendData, { headers: sendHeaders })
      .then(function (res) {
        if (res.data.code == 0) {
          console.warn("!!!!!!", res);
          _this.setUserInfo(res);
        }
      })
      .catch(function (res) {
        console.error("res", res);
        _this.runLoginCallback(res, "error");
      })
      .finally(function () {
        _this.hideLoading("loginBlockchain");
      });
  },
  setUserInfo: function (res, form = "") {
    console.log("--lb", "setUserInfo", form);
    console.log("--lb", res.data);
    let _this = this;
    //服務器返回的用戶信息，或緩存中讀取到用戶信息
    if (res.data.token) {
      res.data.uinfo.token = res.data.token;
      res.data.sendHeaders = headers;
      res.data.domain_name = _this.domainName;
      res.data.token_create_timestamp = new Date().getTime();
      //下面這兩個字段，走登錄和走緩存兩種取用戶信息的方式會有缺失，需要處理一下。
      //用戶名
      if (!res.data.sessionAccount) {
        res.data.sessionAccount = _this.LOGIN_SESSION.auth.actor;
      } else {
        _this.LOGIN_SESSION.auth.actor = res.data.sessionAccount;
      }
      //登錄類型。是錢包登錄還是賬號密碼登錄
      //store中有登錄類型，說明不是從緩存中讀用戶信息
      if (store.state.loginGameType) {
        res.data.loginGameType = store.state.loginGameType;
      }
      //store中沒有,根據擁有的字段判斷
      else {
        if (res.data.uinfo && res.data.uinfo.email) {
          store.commit("set_loginGameType", 1);
        } else {
          store.commit("set_loginGameType", 2);
        }
      }
      console.log("--lb", "write res.data:", res.data);
      store.commit("set_userAccount", _this.formatUserName(res.data.sessionAccount));
      store.commit("set_userGameAccount", res.data.sessionAccount);
      store.commit("set_userInfo", res.data.uinfo);
      store.commit("set_loginRes", res.data);
      console.log("--lb", "set_loginRes", res.data);
      store.commit("set_uid", res.data.uinfo.uid);
      //將登錄結果緩存
      localStorage.setItem("block-war-loginRes", _this.Encrypt(JSON.stringify(res.data)));
      _this.buildSendToGameHeader(res.data.uinfo.uid, res.data.uinfo.token);
      //處理回調
      _this.runLoginCallback(res.data);
      //登錄時存儲推薦人信息
      let xbd = _this.get_xbd();
      console.warn("--lb", "common", "xbd", xbd);
    }
    //沒有獲取到用戶信息，或僅連接到錢包獲取了uid
    else {
      _this.runLoginCallback("token is null", "error");
    }
    // 广播登录信息
    if (top.bc) {
      top.bc.postMessage(JSON.stringify(res));
    }
    console.log("--lb", "common.setUserInfo end");
  },
  //生成隨機數
  randomNum: function (min, max) {
    switch (arguments.length) {
      case 1:
        return Math.floor(Math.random() * minNum + 1);
        break;
      case 2:
        return Math.floor(Math.random() * (max - min + 1) + min);
        break;
      default:
        return 0;
        break;
    }
  },
  //發送短信
  sendSMS: function (accountType, telmail, sendSmsType, sendSmsBtnTxt, btnTxt, thenFn) {
    this.showLoading("sendSMS");
    const sendUrl = this.userApi.requestUrl + "/user_api/" + accountType + "/" + telmail + "/sms";
    const sendHeaders = this.buildHeaders("form");
    const sendData = this.buildSendData({
      type: sendSmsType,
    });
    console.log("--lb", sendUrl, sendHeaders, sendData);

    sendSmsBtnTxt.value = "60";
    let SI = setInterval(function () {
      sendSmsBtnTxt.value -= 1;
      if (sendSmsBtnTxt.value <= 0) {
        sendSmsBtnTxt.value = btnTxt;
        clearInterval(SI);
      }
    }, 1000);
    let _this = this;
    axios
      .post(sendUrl, sendData, { headers: sendHeaders })
      .then((r) => {
        console.log("--lb", r);
        if (r.data.code == 0) {
          toastr["success"](r.data.msg);
          if (thenFn) {
            thenFn(r);
          }
        } else {
          toastr["error"](r.data.msg);
        }
      })
      .catch((e) => {
        console.log("--lb", e);
        toastr["error"](e);
      })
      .finally(() => {
        _this.hideLoading("sendSMS");
      });
  },
  //查詢綁定情況
  //queryType:1為賬號密碼方式登錄；2為錢包登錄
  queryUserBindInfo: async function (accountName, tag = "", callbackFn = function (r) {}) {
    console.log("--lb", "queryUserBindInfo tag", tag);
    let _this = this;
    let sendUrl;
    let queryType = store.state.loginGameType;
    console.log("--lb", "queryType", queryType);
    if (!queryType) {
      if (this.form.reg.tREG.test(accountName) || this.form.reg.eREG.test(accountName)) {
        queryType = 1;
      } else {
        queryType = 2;
      }
    }
    if (queryType == 1) {
      sendUrl = this.userApi.requestUrl + "/user_api/block_chain/2/bind_state?uid=" + store.state.userInfo.uid;
      console.log("--lb", 1, sendUrl);
    } else if (queryType == 2 && accountName) {
      sendUrl = this.userApi.requestUrl + "/user_api/block_chain/2/bind_state?account=" + accountName;
      console.log("--lb", 2, sendUrl);
    }
    const sendHeaders = this.buildHeaders("form");
    const sendData = {
      headers: sendHeaders,
    };

    let queryRes;
    await axios
      .get(sendUrl, sendData)
      .then((r) => {
        console.log("--lb", "queryUserBindInfo", r);
        queryRes = r;
        store.commit("set_bindState", r.data.state);
        if (r.data.code !== 0) {
          return;
        } else if (r.data.uid) {
          store.commit("set_uid", r.data.uid);
        }
        if (r.data.account) {
          store.commit("set_walletLoginName", r.data.account);
        }
        if (r.data.phone_num) {
          store.commit("set_phone_num", r.data.phone_num);
        }
        if (r.data.email) {
          store.commit("set_email", r.data.email);
        }
        if (callbackFn) {
          console.log("--lb", "queryUserBindInfo callbackFn");
          callbackFn(r.data);
        }
      })
      .catch((e) => {
        console.error(e);
        queryRes = e;
      });
    return queryRes;
  },
  //將賬號與錢包綁定
  bindAccountToWallet: function (walletAccount, key, forceTag = 0) {
    const _this = this;
    _this.showLoading("bindAccountToWallet");
    console.log("--lb", key);
    const sendUrl = _this.userApi.requestUrl + "/user_api/block_chain/2/accounts/" + walletAccount + "/metamask/bind_info";
    const sendData = _this.buildSendData({
      key: key,
      force: forceTag,
    });
    const sendHeaders = _this.buildHeaders("form");
    axios
      .post(sendUrl, sendData, { headers: sendHeaders })
      .then((r) => {
        console.log("--lb", r);
        if (r.data.code == 0) {
          _this.bindWalletSuccessFN(r.data);
          toastr["success"](r.data.msg);
        } else {
          _this.bindWalletFailFn(r.data.msg);
          toastr["error"](r.data.msg);
        }
      })
      .catch((e) => {
        _this.bindWalletFailFn(e);
        console.error(e);
        toastr["error"](e);
      })
      .finally(() => {
        _this.hideLoading("bindAccountToWallet");
        _this.queryUserBindInfo(walletAccount, "bindAccountToWallet");
      });
  },
  //獲取我的分享鏈接
  //例：http://wargamedev.com/?xbd=Nzk5MTI1MDEmMA==
  getMyShareUrl: function () {
    const sendUrl = this.userApi.requestUrl + "/user_api/share_link";
    const sendHeaders = this.buildHeaders("form");
    const sendData = {
      headers: sendHeaders,
    };
    axios
      .get(sendUrl, sendData)
      .then((r) => {
        console.log("--lb", "getMyShareUrl", r);
      })
      .catch((e) => {
        console.error(e);
      });
  },
  get_xbd: function () {
    let xbd = this.getQueryVariable("xbd");
    if (xbd) {
      localStorage.setItem("xbd", xbd);
    } else {
      xbd = localStorage.getItem("xbd");
    }
    console.warn("--lb", "common", "xbd", xbd);
    return xbd;
  },
  get_channelId: function () {
    let channel_id = this.getQueryVariable("channel_id");
    if (channel_id) {
      localStorage.setItem("channel_id", channel_id);
    } else {
      channel_id = localStorage.getItem("channel_id");
    }
    if (!channel_id) {
      channel_id = 0;
    }
    console.warn("--lb", "common", "channel_id", channel_id);
    return channel_id;
  },
  //上報推薦人信息。點擊下載和註冊按鈕立即上報，錢包登錄如果沒有綁定uid也立即上報
  reportRefereeUser: function (cbFn) {
    //獲取推薦人信息
    let xbd = this.get_xbd();
    //註冊時上報推薦人信息
    if (xbd) {
      const sendUrl = this.userApi.requestUrl + "/user_api/device_referee_user";
      const sendData = this.buildSendData({ xbd: xbd });
      const sendHeaders = this.buildHeaders("form");
      this.toClipboard('{"xbd":"' + this.get_xbd() + '"}');
      axios
        .patch(sendUrl, sendData, { headers: sendHeaders })
        .then((r) => {
          console.warn("--lb", "common", "reportRefereeUser xbd", r, xbd);
          if (r.data.code == 0) {
            if (cbFn) {
              cbFn();
            }
          } else {
            // toastr['error']('ERROR! reportRefereeUser:' + xbd + "; " + r.data.msg);
          }
        })
        .catch((e) => {
          // toastr['error']('ERROR!! reportRefereeUser:' + xbd);
          console.error(e);
        })
        .finally(() => {
          if (cbFn) {
            cbFn();
          }
        });
    } else {
      if (cbFn) {
        cbFn();
      }
    }
  },
  //向服務器發送錢包公鑰，並取得驗證字符串
  getWalletVerifyStr: async function (r, callbackFN) {
    const _this = this;
    const sendUrl = this.userApi.requestUrl + "";
    const sendHeaders = this.buildHeaders("form");
    const sendHeadData = this.buildSendData({});
    await axios
      .post(sendUrl, sendHeadData, { headers: sendHeaders })
      .then(function (res) {
        console.log("--lb", res);
        //用服務器返回的字符串去錢包解密
        callbackFN(res);
      })
      .catch(function (e) {
        console.error(e);
        _this.runLoginCallback(e, "error");
      });
  },
  showConfirm: function (obj) {
    store.commit("set_confirm", obj);
  },
  //处理登录后的数据
  loginData: function (r, afterRunFunction = function () {}) {
    const _this = this;
    console.log("--lb loginData", r);
    if (r.data.code == 0) {
      if (r.data.uinfos) {
        r.data.uinfo = r.data.uinfos[0];
      }
      let userAccountName = r.data.uinfo.phone_num;
      if (!userAccountName) {
        userAccountName = r.data.uinfo.email;
      }
      _this.LOGIN_SESSION = { auth: { actor: userAccountName } };
      //登錄後存儲用戶信息
      if (!_this.loginByWalletSuccessFN) {
        _this.loginByWalletSuccessFN = function (res) {
          console.log("--lb", "pageTopLogin", res);
        };
        _this.loginByWalletFailFn = function (e) {
          console.error("pageTopLogin", e);
        };
      }
      _this.setUserInfo(r);
      //登錄後運行。如登錄後綁定，登錄後查詢等。用戶信息已寫入緩存和store
      console.log("--lb", "common afterRunFunction");
      afterRunFunction();
    } else {
      toastr["error"](r.data.msg);
    }
  },
  //賬號密碼登錄以及錢包登錄後綁定賬號密碼時的登錄，都共用此方法
  loginByUser: async function (telmail, pw, loginType, afterRunFunction = function () {}) {
    const _this = this;
    const sendUrl = _this.userApi.requestUrl + "/user_api/sessions";
    const sendHeaders = _this.buildHeaders("form");
    let tempData = {
      password: _this.stringTrim(pw),
      domain_name: _this.domainName,
      type: loginType,
    };
    let channel_id = _this.get_channelId();
    if (channel_id) {
      tempData.channel_id = channel_id;
    }
    let isPhone = _this.form.reg.tREG.test(telmail);
    let isMail = _this.form.reg.eREG.test(telmail);
    if (isPhone) {
      tempData.phone_num = _this.stringTrim(telmail);
    } else if (isMail) {
      tempData.email = _this.stringTrim(telmail);
    }
    const sendData = _this.buildSendData(tempData);
    _this.showLoading("loginByUser");
    await axios
      .post(sendUrl, sendData, { headers: sendHeaders })
      .then((r) => {
        _this.loginData(r, afterRunFunction);
      })
      .catch((e) => {
        console.log("--lb", e);
        toastr["error"](e);
      })
      .finally(() => {
        _this.hideLoading("loginByUser");
      });
  },
  //確認框
  confirm: function (obj) {
    obj.showConfirmTag = true;
    store.commit("set_confirm", obj);
  },

  //查詢自己的各幣餘額
  /* type: 財產類型(uint), 1為鑽石，2為金幣，3為銀幣，4為金牌，5為銀牌，6為魅力值，7為魔豆數量, 8為活動幣
  value: 值(64位整形字符串), 如果值為空則代表隱藏
  lock_value:值(64位整形字符串), 當前被鎖定的值 */
  queryFinance: async function (showLoading = true) {
    if (!store.state.uid) {
      return;
    }
    const _this = this;
    if (showLoading) _this.showLoading("queryFinance");
    const sendUrl = _this.userApi.requestUrl + "/finance_api/assets?uids=" + store.state.uid;
    const sendHeaders = _this.buildHeaders();
    const sendData = {
      headers: sendHeaders,
    };
    let returnRes;
    await axios
      .get(sendUrl, sendData)
      .then((r) => {
        console.log("--lb", "queryFinance", r);
        returnRes = r;
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (showLoading) _this.hideLoading("queryFinance");
      });
    return returnRes;
  },
  //更新用戶基本信息及緩存
  updateUserInfo: function () {
    const _this = this;
    //內嵌IM時的登錄邏輯
    if (_this.isInElectron()) {
      const sendUrl = _this.userApi.requestUrl + "/user_api/users?uids=" + _this.getQueryVariable("uid");
      const sendHeaders = _this.buildHeaders("form");
      const sendData = {
        headers: sendHeaders,
      };
      axios
        .get(sendUrl, sendData)
        .then((r) => {
          console.log("--lb", "update userInfo code:", r.data.code);
          if (r.data.code == 0) {
            store.commit("set_loginGameType", 1);
          } else {
            if (router.currentRoute.value.path != "/") {
              _this.loginout(1189);
              store.commit("userHubStore/set_showUserHub", true);
            }
          }
          console.log("--lb", "r.data:", r.data);
          store.commit("set_userInfo", r.data.uinfos[0]);
          store.commit("set_uid", r.data.uinfos[0].uid);
        })
        .catch((e) => {});
      return;
    }
    //普通頁面的登錄邏輯
    if (localStorage.getItem("block-war-loginRes")) {
      const loginRes = JSON.parse(_this.Decrypt(localStorage.getItem("block-war-loginRes")));
      console.warn("--lb", "common", "loginRes:", loginRes);
      //如果未過期返回用戶信息
      if (new Date().getTime() < loginRes.token_create_timestamp + loginRes.token_expire_timestamp * 1000 && loginRes.token && loginRes.uinfo && loginRes.uinfo.uid) {
        //如果token可用
        const sendUrl = _this.userApi.requestUrl + "/user_api/users?uids=" + loginRes.uinfo.uid;
        const sendHeaders = _this.buildHeaders("form", loginRes.sendHeaders);
        const sendData = {
          headers: sendHeaders,
        };
        axios
          .get(sendUrl, sendData)
          .then((r) => {
            console.log("--lb", "update userInfo code:", r.data.code);
            if (r.data.code == 0) {
              loginRes.uinfo = r.data.uinfos[0];
              _this.setUserInfo({ data: loginRes });
              //這個方法包含了回調函數的處理
              _this.runLoginCallback(loginRes);
              _this.queryUserBindInfo(loginRes.sessionAccount, "getStorgeUserInfoOrLogin");
            } else {
              //首頁token無效時不自動彈窗,外部調用時彈窗
              if (_this.loginByWalletSuccessFN) {
                _this.loginout(1232);
                store.commit("userHubStore/set_showUserHub", true);
                store.commit("set_loginBtnClickTag", false);
                console.log("--lb", "false", false);
              }
            }
          })
          .catch((e) => {
            store.commit("userHubStore/set_showUserHub", true);
            store.commit("set_loginBtnClickTag", false);
            console.log("--lb", "false", false);
          });
      } else if (!store.state.loginBtnClickTag) {
        store.commit("userHubStore/set_showUserHub", true);
        store.commit("set_loginBtnClickTag", false);
        console.log("--lb", "false", false);
      }
    }
  },

  //刪除已讀消息
  delReadedMsg: function () {
    const sendUrl = this.userApi.requestUrl + "/misc_api/system_messages/all_reads";
    const sendHeader = this.buildHeaders("form");
    axios.delete(sendUrl, {}, { headers: sendHeader });
  },
  //格式化18位浮點數為小數
  formatCount18Float: (c) => {
    if (!c) {
      return "";
    }
    let l = (c + "").length;
    if (l == 18) {
      return "0." + (c + "");
    } else if (l < 18) {
      let z = "0.";
      for (let i = l; i < 18; i++) {
        z += "0";
      }
      return z + (c + "");
    } else {
      let d = l - 18;
      let lbefore = (c + "").slice(0, d);
      let lafter = (c + "").slice(d, l);
      return lbefore + "." + lafter;
    }
  },
  //格式化6位浮點數為小數
  formatCount6Float: (c) => {
    if (!c) {
      return "";
    }
    let l = (c + "").length;
    if (l == 6) {
      return "0." + (c + "");
    } else if (l < 6) {
      let z = "0.";
      for (let i = l; i < 6; i++) {
        z += "0";
      }
      return z + (c + "");
    } else {
      let d = l - 6;
      let lbefore = (c + "").slice(0, d);
      let lafter = (c + "").slice(d, l);
      return lbefore + "." + lafter;
    }
  },
  //格式化NFT商品的價格
  fortmatNFTprice: function (item) {
    let a = [];
    let _this = this;
    for (let k in item) {
      if (k == "gold_medal") {
        a.push({ type: _this.financeType.goldMedal, value: item[k] });
      }
      if (k == "silver_medal") {
        a.push({ type: _this.financeType.silverMedal, value: item[k] });
      }
      //手續費（註釋掉，改為單獨處理）
      // if (k == 'charge') {
      //   a.push({ type: _this.financeType.gold, value: item[k] })
      // }
    }
    return a;
  },
};
