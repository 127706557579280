<template>
  <div
    v-if="apiReadyTag"
    class="head disselect template"
    v-show="showPageTop && !isInElectron"
  >
    <nav class="nav">
      <div class="layout">
        <msgHub
          :loadEvent="true"
          v-if="uid && userinfo && userInfoReadyTag"
          class="onlymobile"
        ></msgHub>
        <div id="logo">
          <img @click="toPage('/', '', 0)" :src="cdnUrl + '/web_assets/img/logo.png'" />
        </div>
        <ul v-if="showPageTopNav">
          <li
            v-for="(item, inx) in menuList"
            :class="{
              on: item.to == routerPathName,
              parent: item.child,
            }"
            :key="inx"
            v-show="uid || !item.logined"
            @click="item.child ? '' : toPage(item.to, item.link, inx, item.click)"
            :data-to="item.to"
          >
            <span :data="routerPathName">{{ item.text }}</span>
            <ul v-if="item.child">
              <li
                v-for="(i, index) in item.child"
                :class="{ on: i.to == routerPathName }"
                :key="index"
                v-show="uid || !i.logined"
                @click="toPage(i.to, i.link, inx, i.click)"
                :data-to="i.to"
              >
                <span>{{ i.text }}</span>
              </li>
            </ul>
          </li>
        </ul>
        <div class="head-right">
          <msgHub :loadEvent="false" v-if="uid && userinfo && userInfoReadyTag"></msgHub>
          <div id="lang" @click="switchLang">
            <span v-if="lang == 'en'">中</span>
            <span v-else>En</span>
          </div>
          <user></user>
        </div>
        <div
          class="mobile_menu onlymobile"
          @click="mobileMenuShowTag = !mobileMenuShowTag"
        >
          <img :src="cdnUrl + '/web_assets/img/mobile_menu.png'" alt="" />
        </div>
      </div>
    </nav>
    <teleport to=".vue_app">
      <div id="menu" :class="{ block: mobileMenuShowTag }">
        <div class="menulist">
          <div v-if="uid && userinfo" class="user" @click="toCenter">
            <div class="box">
              <img :src="userinfo.head_url" alt="" />{{ userinfo.nick }}
            </div>
          </div>
          <ul v-if="showPageTopNav" class="topnav">
            <li class="parent langage">
              <div
                class="langval"
                @click="langShowTag ? (langShowTag = false) : (langShowTag = true)"
              >
                {{ lang == "en" ? "LUANGUAGE" : "語言" }}
                <span
                  >{{ lang == "en" ? "English" : "中文"
                  }}<svg
                    :class="{ rotate: langShowTag }"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.29289 18.7071C8.68342 19.0976 9.31658 19.0976 9.70711 18.7071L16.0711 12.3431L9.70711 5.97919C9.31658 5.58866 8.68342 5.58866 8.29289 5.97919C7.90237 6.36971 7.90237 7.00287 8.29289 7.3934L13.2426 12.3431L8.29289 17.2929C7.90237 17.6834 7.90237 18.3166 8.29289 18.7071Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
              <dl v-show="langShowTag" @click="switchLang">
                <dd
                  @click="mobileMenuShowTag = !mobileMenuShowTag"
                  :class="{ on: lang == 'en' }"
                >
                  <span class="left">English</span><i></i>
                </dd>
                <dd
                  @click="mobileMenuShowTag = !mobileMenuShowTag"
                  :class="{ on: lang != 'en' }"
                >
                  <span class="left">中文</span><i></i>
                </dd>
              </dl>
            </li>
            <li
              v-for="(item, inx) in menuListMobile"
              :class="{
                on: item.to == routerPathName,
                parent: item.child,
              }"
              :key="inx"
              v-show="uid || !item.logined"
              @click="item.child ? '' : toPage(item.to, item.link, inx, item.click)"
              :data-to="item.to"
            >
              <span
                class="val"
                @click="
                  item.child
                    ? showChildMenuIndex == inx
                      ? (showChildMenuIndex = null)
                      : (showChildMenuIndex = inx)
                    : (mobileMenuShowTag = false)
                "
                :data="routerPathName"
                >{{ item.text
                }}<svg
                  v-if="item.child"
                  :class="{ rotate: showChildMenuIndex == inx }"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.29289 18.7071C8.68342 19.0976 9.31658 19.0976 9.70711 18.7071L16.0711 12.3431L9.70711 5.97919C9.31658 5.58866 8.68342 5.58866 8.29289 5.97919C7.90237 6.36971 7.90237 7.00287 8.29289 7.3934L13.2426 12.3431L8.29289 17.2929C7.90237 17.6834 7.90237 18.3166 8.29289 18.7071Z"
                    fill="white"
                  /></svg
              ></span>
              <ul v-if="item.child" :class="{ show_child: showChildMenuIndex == inx }">
                <li
                  v-for="(i, index) in item.child"
                  :class="{ on: i.to == routerPathName }"
                  :key="index"
                  v-show="uid || !i.logined"
                  @click="toPage(i.to, i.link, inx, i.click)"
                  :data-to="i.to"
                >
                  <span class="val" @click="mobileMenuShowTag = false">{{ i.text }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="mobile_user" @click="mobileMenuShowTag = !mobileMenuShowTag">
          <user v-if="mobileMenuShowTag" :mobileMenuShowTag="mobileMenuShowTag"></user>
        </div>
      </div>
    </teleport>
  </div>
</template>
<style lang="scss" scoped src="./pageTop.scss"></style>
<script src="./pageTop.js"></script>
