import axios from "axios";
import { defineComponent, onMounted, onBeforeUnmount, ref, computed,watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import common from "../../utils/common";
import moralisTool from "../../utils/moralisTool";
import web3Tools from "../../utils/web3Tools";

import userHub from '../userHub/userHub.vue';
import ui_btn_1 from '@/components/ui/ui_btn_1.vue'

/* // 這裡定義一些全局的方法給外部調用，會被改寫，一般來說，必須先在這裡定義，方便管理。 */
window.loginByWallet = null;
window.bindWalletUI = null;
window.showCompleteWalletUI = null;
window.queryUserBindInfoByUid = null;
window.getUserRefereeInfo = null;
// 調用此方法，獲得web登錄所需要的參數
window.getloginParams = function () {
    return {
        'xbd': common.get_xbd(),
        'channel_id': common.get_channelId(),
        'device_token': common.buildDeviceToken()
    }
 }

export default defineComponent({
    name: "user",
    props: ['mobileMenuShowTag'],
    components: { userHub, ui_btn_1 },
    setup(props, ctx) {
        let router = useRouter();
        let store = useStore();
        web3Tools.setStore(store);
        common.setStore(store);
        localStorage.setItem(
            "block-war-hostname",
            location.hostname
        );
        let public_key = ref(""); //用戶存儲在鏈上的公鑰,
        let private_key = ref(""); //用來登錄的私鑰

        let currentRouteName = computed(() => {
            return router.currentRoute.value.path;
        });

        //初始化云登录环境后，是否可以显示云登录按钮
        let canLogin_v2 = computed(() => {
            store.state.set_canLogin_v2;
        }); 
        // 监控canLogin_v2字段，init成功时会显示云登录按钮,init不成功，就一直init
        watch(canLogin_v2, (newVal, oldVal) => {
            if (!newVal) {
                console.log('agin startMoralis()');
                moralisTool.initMoralis();
            }
        });
        
        //在IM中
        if (common.isInElectron()) {
            console.log('--lb isInElectron');
            common.loginout(65);
            localStorage.removeItem('block-war-loginRes');
            public_key.value = null;
            private_key.value = null;
            common.LOGIN_SESSION.auth.actor = null;
            const uid = common.getQueryVariable('uid');
            const platform = common.getQueryVariable('platform');
            const token = common.getQueryVariable('token');
            const deviceToken = common.getQueryVariable('device-token');
            const type = common.getQueryVariable('type');
            store.commit('set_uid', uid);
            // 如果地址栏带了登录信息过来，把相关登录信息写入localStorage，模拟正常登录流程
            if(common.isInElectron()){
                const loginRes = {
                    "code": 0,
                    "msg": "Success",
                    "uinfo": {
                        "uid": uid,
                        "token": token
                    },
                    "token": token,
                    "sendHeaders": {
                        "common": {
                            "Accept": "application/json, text/plain, */*"
                        },
                        "delete": {},
                        "get": {},
                        "head": {},
                        "post": {
                            "Content-Type": "application/x-www-form-urlencoded"
                        },
                        "put": {
                            "Content-Type": "application/x-www-form-urlencoded"
                        },
                        "patch": {
                            "Content-Type": "application/x-www-form-urlencoded"
                        },
                        "X-Sys-Device-Token": deviceToken,
                        "X-Sys-Platform": platform,
                        "X-Sys-Vendor": "web browser",
                        "X-App-Id": 10001,
                        "X-App-Locale": "en",
                        "X-App-Ver": common.appVer,
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    "domain_name": common.domainName,
                    "sessionAccount": null,
                    "loginGameType": 1
                }
                if(type){
                    loginRes.sendHeaders['X-App-Type'] = type;
                }
                localStorage.setItem(
                "block-war-loginRes",
                common.Encrypt(JSON.stringify(loginRes))
                );
                console.log('--lb build loginres',loginRes);
            }
        }

        //是否手動點擊的頂部登錄按鈕。不是則為代碼調用
        let loginBtnClickTag = computed(() => {
            console.log('--lb', 'loginBtnClickTag:', loginBtnClickTag);
            return store.state.loginBtnClickTag;
        });

        let loginGameType = computed(() => {
            console.warn('--lb', 'store.state.loginGameType', store.state.loginGameType)
            return store.state.loginGameType;
        });
        let bindState = computed(() => {
            console.info('store.state.bindState', store.state.bindState)
            return store.state.bindState;
        });

        //錢包地址或賬號的略名
        let UserAccount = computed(() => {
            return store.state.userAccount
        })
        //錢包地址或賬號的全名
        let userGameAccount = computed(() => {
            return store.state.userGameAccount
        })
        //錢包登錄的賬號
        let walletLoginName = computed(() => {
            return store.state.walletLoginName;
        })
        //錢包登錄的綁定的賬號
        let email = computed(() => {
            return store.state.email;
        })
        let phone_num = computed(() => {
            return store.state.phone_num;
        })
        //錢包登錄的綁定的賬號
        let uid = computed(() => {
            return store.state.uid;
        })

        let userInfo = computed(() => {
            return store.state.userInfo;
        });

        //登出
        const clearUserLoginData = () => {
            common.loginout(175);
            public_key.value = null;
            private_key.value = null;
        }

        const clearUserData = function () {
            common.confirm({
                type: 'info',
                title: 'Warn',
                text: common.msg.wantQuite,
                btnTxt: 'OK',
                showCancleBtn: true,
                confirmFn: function (r) {
                    clearUserLoginData();
                    localStorage.setItem('menuIndex', 0);
                    store.commit('set_menuIndex', 0);
                    router.push("./");
                    location.reload();
                },
                cancelFn: function (r) {
                    store.commit("set_showConfirmTag", false);
                }

            })
        };

        //點擊登錄按鈕顯示登錄框
        /*
        @liu_robin 登錄雲函數寫好了，例子寫好了，例子：LoginTest.js
        但是雲函數里面返回的uid、token是假的，需要光輝個提供了接口才能是真的
        */
        const showLoginUI = function (loginBtnClickTag = false) {
            common.loginout(206);
            store.commit('userHubStore/set_showUserHub', true);
            // store.commit('set_loginBtnClickTag', loginBtnClickTag);
            console.log('--lb', 'loginBtnClickTag', loginBtnClickTag)
        }
        //顯示登錄框
        let showUserHubTag = computed(() => {
            return store.state.userHubStore.showUserHub;
        });

        //返回緩存中的用戶信息,或者彈登錄框
        const getStorgeUserInfoOrLogin = async function () {
            //內嵌IM時的登錄邏輯
            if (common.isInElectron()) {
                const sendUrl = common.userApi.requestUrl + "/user_api/users?uids=" + common.getQueryVariable('uid');
                const sendHeaders = common.buildHeaders('form');
                const sendData = {
                    headers: sendHeaders
                };
                await axios.get(sendUrl, sendData)
                    .then((r) => {
                        console.log('--lb', 'update userInfo:', r.data)
                        if (r.data.code == 0) {
                            let loginRes = JSON.parse(common.Decrypt(localStorage.getItem('block-war-loginRes')));
                            loginRes.uinfo = r.data.uinfos[0];
                            loginRes.sessionAccount = r.data.uinfos[0].email;
                            console.warn('--lb', 'loginRes:', loginRes)
                            localStorage.setItem(
                                "block-war-loginRes",
                                common.Encrypt(JSON.stringify(loginRes))
                                );
                            common.setUserInfo({ data: loginRes },'common.isInElectron /user_api/users?uids=')
                            //這個方法包含了回調函數的處理
                            common.runLoginCallback(loginRes);
                            common.queryUserBindInfo(loginRes.sessionAccount, 'getStorgeUserInfoOrLogin');
                        }
                        // else {
                        //     if (router.currentRoute.value.path != "/") {
                        //         showLoginUI();
                        //     }
                        // }
                    })
                    .catch((e) => {
                        // showLoginUI();
                    })
                    .finally(()=>{
                        store.commit("set_userInfoReadyTag", true);
                    })
                return;
            }
            //普通頁面的登錄邏輯
            else if (localStorage.getItem('block-war-loginRes')) {
                const loginRes = JSON.parse(common.Decrypt(localStorage.getItem('block-war-loginRes')));
                console.warn('--lb loginRes:', loginRes)
                //如果未過期返回用戶信息
                if (loginRes.token &&loginRes.uinfo &&loginRes.uinfo.uid) {
                    //如果token可用
                    const sendUrl = common.userApi.requestUrl + "/user_api/users?uids=" + loginRes.uinfo.uid;
                    const sendHeaders = common.buildHeaders('form', loginRes.sendHeaders);
                    const sendData = {
                        headers: sendHeaders
                    };
                    await axios.get(sendUrl, sendData)
                        .then((r) => {
                            console.log('--lb', 'update userInfo:', r.data)
                            if (r.data.code == 0) {
                                common.setUserInfo({ data: loginRes },"localStorage.getItem('block-war-loginRes')",loginRes)
                                //這個方法包含了回調函數的處理
                                common.runLoginCallback(loginRes);
                                common.queryUserBindInfo(loginRes.sessionAccount, 'getStorgeUserInfoOrLogin');
                            }
                            else {
                                //首頁token無效時不自動彈窗,外部調用時彈窗
                                if (router.currentRoute.value.path != "/") {
                                    showLoginUI();
                                }
                                clearUserLoginData();
                            }

                        })
                        .catch((e) => {
                            showLoginUI();
                        })
                        .finally(()=>{
                            store.commit("set_userInfoReadyTag", true);
                        })
                }
                else if (!loginBtnClickTag) {
                    await showLoginUI();
                    store.commit("set_userInfoReadyTag", true);
                }
            }
            else{
                store.commit("set_userInfoReadyTag", true);
            }
        };
        //遊戲等外部來源非手動點擊登錄按鈕調用下面這個全局函數來獲取用戶信息
        //緩存中有用戶信息直接返回
        //緩存中沒有用戶信息，進入手動點擊登錄按鈕流程：彈出登錄框，並設置登錄成功和失敗回調。
        //例子：top.loginByWallet(['metamask'],function(r){console.log('--lb',3333,r)},function(e){console.log('--lb',4444,e)});
        window.loginByWallet = async function (
            paramArray = ["metamask"], //登錄錢包名稱,默認為metamask
            loginByWalletSuccessFN = function (r) { }, //登錄成功回調
            loginByWalletFailFn = function (e) { } //登錄失敗回調  
        ) {
            common.reportRefereeUser();
            common.loginByWalletSuccessFN = loginByWalletSuccessFN;
            common.loginByWalletFailFn = loginByWalletFailFn;
            //如果已登錄且未過期
            if (localStorage.getItem('block-war-loginRes')) {
                await getStorgeUserInfoOrLogin();
            }
            else {
                await showLoginUI();
            }
        };

        //顯示綁定錢包界面，並返回結果
        const bindWalletUI = function (
            paramArray = ["metamask"], //登錄錢包名稱,默認為metamask
            bindWalletSuccessFN = function (r) { console.log('--lb', 'bindWalletSuccessFN', r) }, //綁定成功回調
            bindWalletFailFn = function (e) { console.error('bindWalletFailFn', e) } //綁定失敗回調  
        ) {
            if (bindState.value != 1) {
                common.bindWalletSuccessFN = bindWalletSuccessFN;
                common.bindWalletFailFn = bindWalletFailFn;
                common.queryUserBindInfo(store.state.loginRes.sessionAccount, 'bindWalletUI').then((r) => {
                    if (r.data.code == 0) {
                        //錢包已被綁定過
                        if (r.data.state == 1) {
                            toastr['error']('This wallet has been bound by another account');
                        }
                        //錢包未被綁定過
                        else {
                            //顯示綁定錢包界面
                            store.commit('userHubStore/set_showUserBindToWallet', true);
                        }
                    }
                    else {
                        toastr['error'](r.data.msg);
                    }
                }).catch((e) => { bindWalletFailFn(e) });
            }
        }
        window.bindWalletUI = bindWalletUI;
        //顯示錢包登錄補充賬號密碼界面
        const showCompleteWalletUI = function () {
            if (store.state.userInfo.email) {
                console.log('--lb', store.state.userInfo.email);
                return;
            }
            if (store.state.loginGameType != 2) {
                toastr["error"]('Error! Please login with wallet');
                return;
            }
            //查詢該錢包是否已補填
            common.queryUserBindInfo(store.state.userGameAccount, 'showCompleteWalletUI').then((r) => {
                if (r.data.code == 0) {
                    if (r.data.email && r.data.phone_num) {
                        // toastr["error"]('Error! The wallet account has completed filling in the information before.')
                    }
                    else {
                        //複用註冊界面，模式為4
                        store.commit("userHubStore/set_ui_model", 4);
                        store.commit("userHubStore/set_showUserReg", true);
                    }

                }
                else {
                    toastr["error"](r.data.msg)
                }
            }).catch((e) => { });
        }
        window.showCompleteWalletUI = showCompleteWalletUI;
        //對外接口：通過uid查詢綁定的錢包
        window.queryUserBindInfoByUid = function (callbackFn) {

            let accountName = store.state.userGameAccount;
            if (store.state.userInfo && store.state.userInfo.uid) {
                common.queryUserBindInfo(accountName, '', callbackFn);
            }
            else if (accountName) {
                callbackFn({
                    "code": 0,
                    "msg": "",
                    "state": 0,
                    "account": accountName,
                    "uid": "",
                    "phone_num": "",
                    "email": ""
                });
            }
            else {
                callbackFn({
                    "code": 0,
                    "msg": "User is not logged in.",
                    "state": 0,
                    "account": "",
                    "uid": "",
                    "phone_num": "",
                    "email": ""
                });
            }


        }
        //對外接口，獲取推薦人信息
        window.getUserRefereeInfo = function (callbackFn) {
            if (!store.state.userInfo) {
                if (callbackFn) { callbackFn({ referee_uid: null, walletAddress: null }); }
                return;
            }
            const referee_uid = store.state.userInfo.referee_uid;
            if (referee_uid && referee_uid != 0) {
                const sendUrl = common.userApi.requestUrl + '/user_api/block_chain/2/bind_state?uid=' + referee_uid;
                const sendHeaders = common.buildHeaders('form');
                axios.get(sendUrl, { headers: sendHeaders }).then((r) => {
                    if (r.data.code == 0) {
                        if (callbackFn) { callbackFn({ referee_uid: referee_uid, walletAddress: r.data.account }); }
                    }
                    else {
                        if (callbackFn) { callbackFn({ referee_uid: 'error', walletAddress: 'error' }); }
                    }

                }).catch((e) => {
                    if (callbackFn) { callbackFn({ referee_uid: 'error', walletAddress: 'error' }); }
                }).finally();
            }
            else {
                if (callbackFn) { callbackFn({ referee_uid: 0, walletAddress: 0 }); }
            }
        }

        const toCenter = () => {
            router.push('/center')
        }
        const toMyWallet = () => {
            router.push('/u')
        }

        
        //显示找回密码界面
        if(router.currentRoute.value.name=='recover'){
            showLoginUI();
            store.commit('set_loginBtnClickTag', true);
            store.commit('userHubStore/set_showUserRestPW', true)
        }
        //显示注册界面
        if(router.currentRoute.value.name=='signup'){
            showLoginUI();
            store.commit("userHubStore/set_showUserReg", true);
            common.reportRefereeUser();
        }

        onMounted(() => {
            //自動登錄
            getStorgeUserInfoOrLogin();

            //根據url的字段來顯示註冊或登錄
            const showUI = common.getQueryVariable('ui');
            console.log('--lb', 'showUI', showUI)
            if (showUI == 'login') {
                console.log('--lb', 'showLoginUI');
                store.commit("userHubStore/set_showUserLogin", true);
            }
            if (showUI == 'register') {
                console.log('--lb', 'showRegUI');
                store.commit("userHubStore/set_showUserReg", true);
            }
        });

        onBeforeUnmount(() => {
            if (common.isInElectron()) {
                common.loginout(469);
                public_key.value = null;
                private_key.value = null;
                common.LOGIN_SESSION.auth.actor = null;
            }
        });




        let lang = computed(() => {
            return store.state.lang;
        });
        let langTxt = store.state.text;

        return {
            currentRouteName,
            langTxt,
            lang,
            loginByWallet, bindWalletUI, clearUserData, showLoginUI, UserAccount, userGameAccount, showUserHubTag, userInfo, loginGameType, bindState, showCompleteWalletUI, walletLoginName, toMyWallet, toCenter, phone_num, email, uid
        };
    },
});
