import { defineComponent, computed, watch, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import user from "../user/user.vue";
import msgHub from "../msgHub/msgHub.vue";
import common from "../../utils/common";

export default defineComponent({
    name: "pageTop",
    props: {},
    components: { user, msgHub },
    setup(props, ctx) {
        let router = useRouter();
        let store = useStore();
        let cdnUrl = computed(() => {
            return store.state.cdnUrl;
        })
        console.log('--lb', router.currentRoute.value)
        const toCenter = () => {
            router.push('/center');
            mobileMenuShowTag.value = false;
        }

        let mobileMenuShowTag = ref(false);

        let isInElectron = common.isInElectron();

        let routerPathName = computed(() => {
            return router.currentRoute.value.path;
        })

        let apiReadyTag = computed(()=>{
            return store.state.apiReadyTag;
        });
        let userInfoReadyTag = computed(() => {
          return store.state.userInfoReadyTag;
        });

        //查詢消息用的uid
        let uid = computed(() => {
            return store.state.uid;
        });
        let userinfo = computed(() => {
            return store.state.userInfo;
        });
        let loginRes = computed(() => {
            return store.state.loginRes;
        });

        let menuIndex = computed(() => {
            return store.state.menuIndex
        });

        let showPageTop = computed(() => {
            return store.state.showPageTop;
        })
        let showPageTopNav = computed(() => {
            return store.state.showPageTopNav;
        })
        let lang = computed(() => {
            return store.state.lang;
        });
        let langTxt = store.state.text;
        let menuList = ref(langTxt[lang.value].nav);
        watch(lang, (newVal, oldVal) => {
            console.log('--lb', newVal, oldVal)
            menuList.value = langTxt[newVal].nav;
        })
        let menuListMobile = computed(()=>{
                return [
                    {
                        text: lang.value=='en'?'HOME':'首頁',
                        to: '/',
                        logined: false,
                    },
                    {
                        text: lang.value=='en'?'PRODUCTS':'產品',
                        logined: false,
                        child: [
                            {
                                text: 'Hash Talk',
                                to: '/hashtalk',
                                // link: 'https://www.baidu.com',
                                logined: false,
                            },
                            {
                                text: 'Blockwar VPN',
                                to: '/accelerator',
                                logined: false,
                            },
                        ]
                    },
                    {
                        text: lang.value=='en'?'STORY':'故事',
                        to: '/story',
                        logined: false,
                    },
                    {
                        text: lang.value=='en'?'ABOUT US':'關於我們',
                        logined: false,
                        child: [
                            {
                                text: lang.value=='en'?'Whitepaper':'白皮書',
                                to: '/whitepaper',
                                // link: 'https://www.baidu.com',
                                logined: false,
                            },
                            {
                                text: lang.value=='en'?'Roadmap':'線路圖',
                                to: '/roadmap',
                                logined: false,
                            },
                        ]
                    },
                    {
                        text: lang.value=='en'?'MORE':'更多',
                        logined: false,
                        child: [
                            {
                                text: lang.value=='en'?'Exchange':'兑换',
                                to: '/exchange',
                                logined: false,
                            },
                            {
                                text: lang.value=='en'?'Recommend':'推荐好友',
                                to: '/recommend',
                                logined: true,
                            },
                            {
                                text: lang.value=='en'?'Mall':'商城',
                                to: '/mall',
                                logined: true,
                            },
                            {
                                text: lang.value=='en'?'On-Chain Data':'链上数据',
                                link: 'https://tool.blockwar.io/game-data',
                            },
                            {
                                text: lang.value=='en'?'Game Stats':'游戏数据',
                                to:'/game_stats',
                                logined: false,
                            },
                        ]
                    },
                ]
        });

        let menuClick = {
            toGameTool: function () {
                if (common.isProduction) {

                }
                else {
                    window.open(
                        `http://data.boshi.com/test/test`
                    );
                }
            }
        };
        const toPage = (to, link = null, inx = 0, click = null) => {
            // console.log('--lb', to, link, inx, click)
            console.log('--lb', 'routerPathName', router.currentRoute.value, to);
            store.commit("userHubStore/set_showUserHub", false);
            if (inx || inx == 0) {
                store.commit('set_menuIndex', inx);
                localStorage.setItem('menuIndex', inx);
            }
            if (link) {
                window.open(link);
            }
            else if (to) {
                router.push(to);
            }
            else if (click) {
                menuClick[click]();
            }
            store.commit('set_menuIndex', inx);
        }


        // 切換語言
        let localLang = localStorage.getItem('lang');
        if (localLang) {
            if (localLang == 'cn') {
                localLang = 'zh_cn';
                localStorage.setItem('lang', 'zh_cn');
            }
            store.commit('set_lang', localLang);
        }
        const switchLang = () => {
            if (lang.value == 'en') {
                store.commit('set_lang', 'zh_cn');
                localStorage.setItem('lang', 'zh_cn');
            }
            else {
                store.commit('set_lang', 'en');
                localStorage.setItem('lang', 'en');
            }
        }

        // 移動端顯示子菜單
        let showChildMenuIndex = ref();

        // 移動端顯示語言項
        let langShowTag = ref(false);

        onMounted(() => {

            isInElectron = common.isInElectron()

            let inx = localStorage.getItem('menuIndex');
            if (inx) { store.commit('set_menuIndex', inx); }
            /* switch (location.pathname) {
                case '/products':
                    store.commit('set_menuIndex', 1);
                    break;
                case '/story':
                    store.commit('set_menuIndex', 2);
                    break;
                case '/whitepaper':
                    store.commit('set_menuIndex', 3);
                    break;
                case '/roadmap':
                    store.commit('set_menuIndex', 4);
                    break;
                case '/exchange':
                    store.commit('set_menuIndex', 5);
                    break;
                default:
                    store.commit('set_menuIndex', 0);
            } */
        });


        return {userInfoReadyTag,apiReadyTag,cdnUrl,toCenter,menuListMobile,langShowTag,showChildMenuIndex, mobileMenuShowTag,lang, switchLang, isInElectron, uid, userinfo, menuIndex, menuList, toPage, showPageTop, showPageTopNav, routerPathName };
    },
});