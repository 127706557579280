<template>
  <div class="get_vrc template">
    <div class="phone">
      <input
        type="text"
        v-model="phone"
        :placeholder="lang == 'en' ? 'Tel' : '請輸入手機號碼'"
        :data-check="isMobilePhone"
        @input="isMobilePhone = false"
      />
      <svg
        class="right"
        v-if="isMobilePhone && CD_Num != 0"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
          fill="#37AB00"
        />
        <path
          d="M15.0963 5.16138L16.7743 6.80826L8.59204 14.8388L3.87109 10.2054L5.55058 8.55852L8.59352 11.545L15.0963 5.16138V5.16138Z"
          fill="white"
        />
      </svg>
      <span class="phonetxt" v-if="phone && CD_Num == 0" @click="getVRC">{{
        lang == "en" ? "Verification Code" : "獲取驗證碼"
      }}</span>
    </div>
    <div class="phone" v-if="phone && CD_Num > 0">
      <input
        type="text"
        v-model="VRC"
        :placeholder="lang == 'en' ? 'enter confirmation code' : '請輸入驗證碼'"
      />
      <span v-if="CD_Num > 1" class="phonetxt reget"
        >{{ lang == "en" ? "Reacquire" : "重新獲取" }} ({{ CD_Num }}s)</span
      >
      <span v-if="CD_Num == 1" class="phonetxt reget wait" @click="getVRC">重新獲取</span>
    </div>
  </div>
</template>
<script setup>
import { watch, ref, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common.js";
import axios from "axios";

let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});

let props = defineProps({
  codeType: {
    default: "1", //1為驗證碼登錄, 2註冊，3，為密碼重置，4，為保險箱密碼重置, 5,賬戶綁定
  },
});
let emits = defineEmits(["sendPhone", "sendVRC"]);
let phone = ref("");
let CD_Num = ref(0);
let VRC = ref();
let getVRC_SI;

let isMobilePhone = computed(() => {
  return common.isMobilePhone(phone.value);
});

const CD60 = () => {
  CD_Num.value = 60;
  getVRC_SI = setInterval(() => {
    if (CD_Num.value > 1) {
      CD_Num.value = CD_Num.value - 1;
    } else {
      CD_Num.value = 1;
      clearInterval(getVRC_SI);
    }
  }, 1000);
};
const getVRC = () => {
  VRC.value = "";
  if (!isMobilePhone.value) {
    toastr["error"](
      lang.value == "en" ? "Please enter your mobile number" : "請輸入手機號碼"
    );
    return;
  }
  const sendUrl = common.userApi.requestUrl + "/user_api/phones/" + phone.value + "/sms";
  const sendData = common.buildSendData({
    type: props.codeType,
  });
  common.showLoading("getVRC");
  axios
    .post(sendUrl, sendData)
    .then((r) => {
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
        emits("sendPhone", phone.value);
        CD60();
      } else {
        console.error(r.data.msg);
        toastr["error"](r.data.msg);
      }
    })
    .finally(() => {
      common.hideLoading("getVRC");
    });
};

watch(VRC, () => {
  emits("sendVRC", VRC.value);
});
onUnmounted(() => {
  clearInterval(getVRC_SI);
});
</script>
<style lang="scss" scoped>
.get_vrc.template {
  width: 100%;
  .phone {
    border-radius: 0.1rem;
    background: #34395b;
    height: 0.48rem;
    padding: 0.14rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    input {
      display: flex;
      width: 60%;
      height: 100%;
      background-color: transparent;
      box-sizing: border-box;
      outline: none;
      box-shadow: none;
      border: 0;
      color: #fff;
    }
  }
  .phonetxt {
    color: #9daaff;
    cursor: pointer;
    &.reget {
      color: rgba(255, 255, 255, 0.6);
      &.wait {
        color: #9daaff;
      }
    }
  }
}
</style>
