export default {
    namespaced: true,
    state: {
        en: {
            nav: [
                {
                    text: 'HOME',
                    to: '/',
                    logined: false,
                },
                {
                    text: 'PRODUCTS',
                    logined: false,
                    child: [
                        {
                            text: 'Hash Talk',
                            to: '/hashtalk',
                            logined: false,
                        },
                        {
                            text: 'Blockwar vpn',
                            to: '/accelerator',
                            logined: false,
                        },
                    ]
                },
                {
                    text: 'STORY',
                    to: '/story',
                    logined: false,
                },
                {
                    text: 'ABOUT US',
                    logined: false,
                    child: [
                        {
                            text: 'Whitepaper',
                            // to: '/whitepaper',
                            link: '/whitepaper',
                            logined: false,
                        },
                        {
                            text: 'Roadmap',
                            to: '/roadmap',
                            logined: false,
                        },
                    ]
                },
                {
                    text: 'MORE',
                    logined: false,
                    child: [
                        {
                            text: 'Exchange',
                            to: '/exchange',
                            logined: true,
                        },
                        {
                            text: 'Recommend',
                            to: '/recommend',
                            logined: true,
                        },
                        {
                            text: 'Mall',
                            to: '/mall',
                            logined: false,
                        },
                        {
                            text: 'On-Chain Data',
                            link: 'https://tool.blockwar.io/game-data',
                            logined: false,
                        },
                        // {
                        //     text: 'Account Activities',
                        //     link: (location.hostname != "wargamedev.com" && location.hostname != "localhost") ? 'https://tool.blockwar.io/asset-change' : 'http://admin.wargamedev.com:6065/asset-change ',
                        //     logined: false,
                        // },
                        {
                            text: 'Game Stats',
                            to: '/game_stats',
                            logined: false,
                        },
                    ]
                },
            ],
            user: ['Personal Center', 'Log Out'],
            index: {
                minigame: ['Are You Ready?', 'Enter the world of Block War.', 'Your adventure awaits.', 'play game', 'game stats'],
                currency: ['ONE CURRENCY IN CIRCULATION', 'Block War universe recognizes a unified currency, War Aureus, as this ensures the efficiency and robustness of its economy. Join the rush for profits by utilizing other meta resources and hiring worker NFTs.', 'Mining With NFT', 'War Aureus are mined by worker NFTs with assistance of various NFT toolkits.', 'These War Aurei are pillars to the Block War universe.'],
                intro: ['Rich Casual Game Platform', 'Block War open platform allows any game to be part of the universe where War Aureus is the only currency circulated. The platform will strictly enforce open and fair play policies on all games.'],
                hashTalk: ['Hash Talk', 'Hashtalk makes chatting a breeze for players. It is also a portal for all information related to Block War universe and has many tools built in to enhance your experience.', 'download'],
                vpn: ['Blockwar Accelerator', 'Stay connected with all the security and privacy you deserve. Enjoy fast and sustained connection anytime, anywhere.', 'download'],
                creative: ['Creative NFT Gameplay', 'Games released on Block War platform may release unique NFT cards that contributes to Block War economic system, spicing up your mining and gaming experience.', 'play game'],
                appDownload: ['Download APP']
            },
            story: [
                'Different races of beings coexist on the ancient continent of Varo. The Human Alliance and the Orc Horde are the two largest groups. Although there are occasional skirmishes between them, a very delicate balance of power had been sustained. However, a mysterious continent called Scarlet appeared to the south of Varo. This balance is about to be all but upset.',
                'The first one to set foot on Scarlet was a human fisherman. After sending the news of this new exciting continent back to the alliance, human pioneers landed on Scarlet en masse. They discovered that the soil is not only fertile and rich in minerals, but also filled with ancient energy of magic. The news spread like wildfire. The orcs did not want humans to monopolize the continent. An army of orcs was also sent to snatch resources. The war between humans and orcs is imminent.',
                'The story of Block War took place in a neutral area in the middle of Scarlet. The war between humans and orcs continues. But in this neutral zone, everyone for the time being maintained peace and made various props to mine resources and plant crops for their respective allegiance.',
                'As a lord of this zone, you can command workers of various occupations to work, forge, or trade to maximize your resources and profits.',
                'With wealth and resources in abundance, you may just be able to sway the battle between humans and orcs. Join the Block War.'
            ],
            gameStats: {
                tab: ['Today', 'Yesterday', 'Total'],
                gold: ['Net Balance (Gold)', 'Deposit', 'Withdraw', "Gold"],
                data: ['Task Spending', 'Achievement Spending', 'Gold Medal Count', 'Sliver Medal Count', 'Card Count', 'Card Gain'],
            },
            center: [
                'EDIT', 'Amount Remaining', 'Silver', 'Gold Medal Count', 'Sliver Medal Count', 'Name', 'Game ID', 'Personal Information', 'Account Information', 'Login Account', 'change Password', 'Wallet', 'BIND WALLET', 'Not Yet Bound To The Wallet', 'not logged in account', 'login account', 'Complete account', 'login WALLET', 'Modify Avatar', 'If you want to modify the avatar, you need to pay ', 'gold coins', 'Modify Nickname', 'Modifying the nickname requires paying ', 'Confirmation of changes will deduct your fee', "Please Enter Your New Nickname", 'Please enter your new nickname in the input box',
            ],
            confirm: {
                title: [],
                text: [],
                btnTxt: ['Cancel', "GO", "OK"],
            },
            exchange: {
                menu: ['My Trades', 'Trading Hall', 'Publish Trade', 'Trade Settings'],
                hall: ['Amount', 'User Name', 'Note', 'Chaannel', 'Date'],
                my: ['Amount', 'Player ID', 'Time', 'Content', 'State'],
                status: ['rejected', 'completed', 'canceled', 'Frozen'],
                btn: ['CANCEL', 'SELL', 'BUY', 'Cancel trade', 'arbitrating', 'Back'],
                detail: ['Cancel', 'please enter quantity', 'Quantity for', 'sell', 'buy', 'DETAILS', 'User Name', 'quantity for sale', 'Minmum Amount', 'Channels', 'Note', 'COMFIRM TO PUBLISH'],
                setting: ['trade updates Off/on', 'SUBMIT'],
            },
            recommend: {
                menu: ['Invite Friends', 'MY TEAM', 'WITHDRAW REWARDS', 'WITHDRAW RECORD', 'Channel Reward'],
                invite: ['Direct Player', 'Team Size', 'Daily New Signup', 'Daily Active', 'FREE TO JOIN', 'Copy the invitation link below to invite your friend to join us and get a great commission from his bets!!', 'COPY'],
                myTeam: ['Player ID', 'Player Contribution', 'Team Contribution', 'Downline Player', 'Team Size', 'Type', 'Player', 'Commision Based Agent'],
                rewards: ['Player ID', 'Game', 'Time', 'Income'],
                record: ['Time', 'Income'],
                channel: ['total rewards withdrawn', 'total bets placed by players', 'claimable rewards from commision (commision is 20 % of all bets for channel)', 'receive'],
            },
            register: [
                'Sign Up', 'Welcome To Sign Up Block WAR.', 'VERIFY CODE', 'Have an account?', 'Supplementary account password', 'You can choose one more login method, which will make your game experience better', 'get verify code', 'registration success', 'go log in !', 'OK', 'Confirm Password', 'Password', 'Login'
            ],
            login: ['log in', 'Welcome to Block War', 'Create account?', 'The password may be wrong.', 'Forgot password?', ' Not a member?', 'Create account', 'get verify code'],
        },
        zh_cn: {
            nav: [
                {
                    text: '首頁',
                    to: '/',
                    logined: false,
                },
                {
                    text: '產品',
                    logined: false,
                    child: [
                        {
                            text: 'Hash Talk',
                            to: '/hashtalk',
                            logined: false,
                        },
                        {
                            text: 'Blockwar vpn',
                            to: '/accelerator',
                            logined: false,
                        },
                    ]
                },
                {
                    text: '故事',
                    to: '/story',
                    logined: false,
                },
                {
                    text: '關於我們',
                    logined: false,
                    child: [
                        {
                            text: '白皮書',
                            // to: '/whitepaper',
                            link: '/whitepaper',
                            logined: false,
                        },
                        {
                            text: '線路圖',
                            to: '/roadmap',
                            logined: false,
                        },
                    ]
                },
                {
                    text: '更多',
                    logined: false,
                    child: [
                        {
                            text: '兌換',
                            to: '/exchange',
                            logined: true,
                        },
                        {
                            text: '推薦好友',
                            to: '/recommend',
                            logined: true,
                        },
                        {
                            text: '商城',
                            to: '/mall',
                            logined: false,
                        },
                        {
                            text: '鏈上數據',
                            link: 'https://tool.blockwar.io/game-data',
                            logined: false,
                        },
                        // {
                        //     text: '賬戶活動',
                        //     link: (location.hostname == 'www.blockwar.io' || location.hostname == 'blockwar.io') ? 'https://tool.blockwar.io/asset-change' : 'http://admin.wargamedev.com:6065/asset-change ',
                        //     logined: false,
                        // },
                        {
                            text: '遊戲數據',
                            to: '/game_stats',
                            logined: false,
                        },
                    ]
                },
            ],
            user: ['個人中心', '退出'],
            index: {
                minigame: ['您準備好了嗎?', '進入BLOCKWAR的世界', '想要的都在這裡', '開始遊戲', '遊戲數據'],
                currency: ['唯一貨幣流通', '戰遊只發行戰遊幣，單一的幣種保障高效的經濟系統.通過兌換世界資源，招募工人(nft)，加入到礦山資源的爭奪中。', '生產NFT', 'WAT依靠工人NFT的開採，配合更多NFT道具的組合，', '為遊戲世界創造源源不斷的資金支持。'],
                intro: ['豐富多彩的遊戲世界', '戰遊世界通過開放平臺，可將任何遊戲接入到戰遊的世界中。在這裡戰遊幣就是唯一的硬通貨。戰遊世界將保障接入遊戲的公平性。'],
                hashTalk: ['Hash Talk', 'hask talk 讓玩家輕松聊天互動，快捷獲取諮詢消息，集合遊戲多種功能，方便快捷使用。', '下載'],
                vpn: ['Blockwar Accelerator', '安全、私密的訪問互聯網，隨時隨地享受快速、穩定的鏈接，暢快的瀏覽網絡。', '下載'],
                creative: ['豐富的NFT玩法', '接入戰遊世界的遊戲會有專屬的NFT卡牌，融入戰遊世界經濟系統，讓採集和遊戲更高效.', '開始遊戲'],
                appDownload: ['下載APP']
            },
            story: [
                '古老的瓦羅大陸上生存著各種各樣的種族。人類聯盟與獸人部落是其中最大的兩個族群。他們之間雖然偶有小規模沖突。但是一直保持著微妙的平衡。然而一片名叫血色大陸的神秘大陸出現在了古大陸的南方。將要打破了這種平衡。',
                '第一個踏上血色大陸的是一位人類漁夫。在把這個新大陸的消息傳回聯盟後。人類拓荒者們登上血色大陸。他們發現這裡不但土壤肥沃礦產豐富。還充滿了古老的魔法能量。然後這個消息不脛而走。獸人部落並不希望人類獨佔這片大陸。也派出了獸人大軍搶奪資源。人類與獸人的大戰一觸即發。',
                '《blockwar》的故事發生在血色大陸中部一片中立區域。人類和獸人的戰爭雖然在繼續。但是在這片中立區大家卻暫時的一起製造各種道具開採資源和種植作物，為了各自的種族。',
                '您作為這片區域的領主。可以指揮各個職業進行勞作。鑄造。交易來讓您的資源最大化。',
                '您也可以利用這些資源來左右人類與獸人大戰的局面。'
            ],
            gameStats: {
                tab: ['今日', '昨日', '累計'],
                gold: ['輸贏總數 (金幣)', '充值', '提現', '金幣'],
                data: ['任務支出 (金幣)', '成就支出 (金幣)', '金牌數 (枚)', '銀牌數 (枚)', '卡牌給出數 (張)', '卡牌加成收益 (金幣)'],
            },
            center: [
                '編輯', '金幣餘額', '銀幣餘額', '金牌數', '銀牌數', '暱稱', '遊戲 ID', '個人信息', '賬戶信息', '賬號登錄', '修改密碼', '錢包', '綁定錢包', '未綁定錢包', '未登錄賬號', '登錄賬號', '完善賬戶', '登錄錢包', '修改頭像', '如需修改頭像，需支付 ', '金幣', '修改暱稱', '修改暱稱需要支付 ', '確認更改將扣除您的費用', '請在輸入框中輸入您的新暱稱', '請在輸入框中輸入您的新暱稱',
            ],
            confirm: {
                title: [],
                text: [],
                btnTxt: ['取消', "確認", "確認"],
            },
            exchange: {
                menu: ['我的交易', '交易大廳', '發布交易', '交易設置'],
                hall: ['數量', '用戶名', '簡介', '支持渠道', '日期'],
                my: ['數量', '玩家 ID', '時間', '內容', '狀態'],
                status: ['拒絕', '已完成', '已取消', '凍結'],
                btn: ['取消', '出售', '購入', '取消交易', '仲裁', '返回'],
                detail: ['取消', '輸入數量', '確認', '賣出', '買入', '詳細', '用戶名', '交易數量', '最小額度', '支持渠道', '簡介', '確認發布 '],
                setting: ['交易通知 關閉/開啟', '提交'],
            },
            recommend: {
                menu: ['邀請好友', '我的隊伍', '獎勵提取', '提取記錄', '渠道獎勵'],
                invite: ['直屬玩家', '團隊玩家', '今日新增', '今日活躍', '免費加入', '復製下面的鏈接，邀請您的朋友加入我們，並從他的投註中獲得豐厚的傭金!!', '復製'],
                myTeam: ['玩家 ID', '玩家貢獻', '團隊貢獻', '直屬人數', '團隊人數', '類型', '玩家', 'Commision Based Agent'],
                rewards: ['玩家ID', '遊戲', '時間', '收益'],
                record: ['時間', '收益'],
                channel: ['提現總獎勵', '玩家總投註', '可獲取的傭金獎勵 (傭金為渠道所有投註的20%)', '提取'],
            },
            register: ['Sign Up', 'Welcome To Sign Up Block WAR.', 'VERIFY CODE', 'Have an account?', 'Supplementary account password', 'You can choose one more login method, which will make your game experience better', 'get verify code', 'registration success', 'go log in !', 'OK', 'Confirm Password', 'Password', 'Login'],
            login: ['log in', 'Welcome to Block War', '創建賬號?', '密碼不正確。', '是否忘記密碼?', ' 尚無賬號?', '歡迎創建一個', 'get verify code'],
        }

    },
    actions: {},
    mutations: {
        set_uid(state, payload) {
            state.uid = payload;
        },
        set_loginInfo(state, payload) {
            state.loginInfo = payload;
        },
        set_showTabIndex(state, payload) {
            state.showTabIndex = payload;
        }
    },
}
