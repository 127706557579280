<template>
  <teleport to="body">
    <div class="component_user_hub">
      <div :class="{ content: true }">
        <userBind_walletToAccount
          class="userbind"
          v-if="showBindToAccountTag"
        ></userBind_walletToAccount>
        <userBind_AccountToWallet
          class="userbind"
          v-else-if="showUserBindToWalletTag"
        ></userBind_AccountToWallet>
        <div v-else class="user_hub">
          <div class="userhub_box" style="height: auto">
            <div class="form">
              <userLogin v-if="showLoginTag"></userLogin>
              <userReg v-if="showRegTag"></userReg>
              <UserResetPW class="userreset" v-if="showResetPWTag"></UserResetPW>
            </div>
            <div class="loginwallet" v-if="showLoginTag && ui_model == 1">
              <!-- <ui_btn_1 v-if="canLogin_v2" @click="conntctWallet_v2" class="xbd login_v2" :width="'3.7rem'"><img src="https://cdn.blockwar.io/web_assets/img/ico_wallet.png"
                  alt="" />
                CONNECT
                WALLET</ui_btn_1>
              <ui_btn_1 v-else @click="conntctWallet" class="xbd login_v1" :width="'3.7rem'"><img src="https://cdn.blockwar.io/web_assets/img/ico_wallet.png"
                  alt="" />
                CONNECT
                WALLET.</ui_btn_1> -->
              <ui_btn_1 @click="conntctWallet" class="xbd login_v1" :width="'3.7rem'"
                ><img
                  src="https://cdn.blockwar.io/web_assets/img/ico_wallet.png"
                  alt=""
                />
                {{ lang == "en" ? "CONNECT WALLET" : "连接钱包" }}</ui_btn_1
              >
            </div>
            <span class="close onlypc" @click="hideUserHub"
              ><svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M26.3066 8.629L19.6777 15.2579L13.0487 8.629L8.63049 13.0472L15.2594 19.6746L8.63049 26.3051L13.0472 30.7249L19.6761 24.096L19.681 24.1009L24.1016 19.6803L24.0975 19.6762L30.7248 13.0472L26.3066 8.629ZM25.2616 20.8403L20.8414 25.2605L26.3082 30.7233L30.7264 26.3051L25.2616 20.8403Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
            </span>
            <div class="lang onlypc">
              <span class="val"
                >{{ lang == "en" ? "English" : "中文"
                }}<svg
                  width="8"
                  height="7"
                  viewBox="0 0 8 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.8417 6.18792C4.4482 6.80132 3.5518 6.80132 3.1583 6.18792L0.657751 2.28995C0.230795 1.62439 0.708715 0.75 1.49945 0.75H6.50055C7.29128 0.75 7.7692 1.62439 7.34225 2.28995L4.8417 6.18792Z"
                    fill="white"
                  />
                </svg>
              </span>
              <ul>
                <li @click="switchLang" :class="{ on: lang == 'en' }">English</li>
                <li @click="switchLang" :class="{ on: lang != 'en' }">中文</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<style lang="scss" src="./userHub.scss"></style>
<style lang="scss">
.errtxt {
  text-align: left;
  font-size: 0.16rem;
  line-height: 1;
  color: #ea3b42 !important;
  margin-bottom: 0.24rem;
  white-space: nowrap;
}
</style>
<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import web3Tools from "../../utils/web3Tools";
import common from "../../utils/common";
import moralisTool from "../../utils/moralisTool";
import userLogin from "./userLogin.vue";
import userReg from "./userReg.vue";
import userBind_walletToAccount from "./userBind_walletToAccount.vue";
import userBind_AccountToWallet from "./userBind_AccountToWallet";
import UserResetPW from "./userResetPW.vue";
import Ui_btn_1 from "../ui/ui_btn_1.vue";

export default defineComponent({
  name: "userHub",
  props: {},
  components: {
    userLogin,
    userReg,
    userBind_walletToAccount,
    userBind_AccountToWallet,
    UserResetPW,
    Ui_btn_1,
  },
  setup(props, ctx) {
    const isProduction = common.isProduction;
    let router = useRouter();
    let store = useStore();
    let UserAccount = computed(() => {
      return store.state.userAccount;
    });
    var lang = computed(() => {
      return store.state.lang;
    });
    const switchLang = () => {
      if (lang.value == "en") {
        store.commit("set_lang", "zh_cn");
        localStorage.setItem("lang", "zh_cn");
      } else {
        store.commit("set_lang", "en");
        localStorage.setItem("lang", "en");
      }
    };

    // 雲用戶。有了雲用戶才能去獲取UID和token。
    let MoralisUser = computed(() => {
      return store.state.MoralisUser;
    });
    watch(MoralisUser, (newVal, oldVal) => {
      if (newVal && newVal != oldVal) {
        console.log(
          "watch MoralisUser moralisTool.getUserTokenByMoralis()",
          newVal,
          oldVal
        );
        moralisTool.getUserTokenByMoralis();
      }
    });
    //是否可以顯示雲登錄按鈕
    let canLogin_v2 = computed(() => {
      return store.state.canLogin_v2;
    });

    //是否手動點擊的頂部登錄按鈕。不是則為代碼調用
    let loginBtnClickTag = computed(() => {
      console.log("--lb", "loginBtnClickTag:", loginBtnClickTag.value);
      return store.state.loginBtnClickTag;
    });
    let ui_model = computed(() => {
      return store.state.userHubStore.ui_model;
    });
    let h2Txt = common.msg.bindAgameAccout;

    let showLoginTag = computed(() => {
      return store.state.userHubStore.showUserLogin;
    });
    let showRegTag = computed(() => {
      return store.state.userHubStore.showUserReg;
    });
    let showBindToAccountTag = computed(() => {
      return store.state.userHubStore.showUserBind;
    });
    let showUserBindToWalletTag = computed(() => {
      return store.state.userHubStore.showUserBindToWallet;
    });
    let showResetPWTag = computed(() => {
      return store.state.userHubStore.showUserRestPW;
    });
    const hideUserHub = () => {
      store.commit("userHubStore/set_showUserHub", false);
      router.push("/");
      if (common.loginByWalletFailFn) {
        // common.loginByWalletFailFn("login cancel");
        common.runLoginCallback("login cancel", "error");
      }
      console.log("--lb", "loginBtnClickTag", store.state.loginBtnClickTag);
    };

    // 獲取私鑰公鑰方式登錄
    const conntctWallet = async function () {
      console.log("--lb", "conntct Wallet", "loginBtnClickTag", loginBtnClickTag.value);
      //外部調用登錄錢包會設置成功回調和失敗回調，如果是手動調用，設置一個默認的成功和失敗回調
      if (!common.loginByWalletSuccessFN) {
        common.loginByWalletSuccessFN = function (r) {
          console.log("--lb", "btn_login call conntctWallet", r);
        };
        common.loginByWalletFailFn = function (e) {
          console.error("--lb btn_login call conntctWallet", e);
          // toastr["error"](e);
        };
      }
      //連接錢包。外部調用會連接錢包後繼續登錄到服務器
      web3Tools.connectWeb3(
        function () {
          web3Tools
            .getWalletAccount(["metamask"])
            .then((accountArr) => {
              console.log("--lb", accountArr);
              store.commit("set_loginGameType", 2);
              //查詢是錢包否已綁定了遊戲賬號
              common.queryUserBindInfo(accountArr[0], "conntctWallet");
              //是否用戶點擊的登錄。用戶手動點擊的登錄，僅獲取錢包賬號
              console.log("--lb", "loginBtnClickTag", loginBtnClickTag.value);
              if (loginBtnClickTag.value) {
                store.commit("userHubStore/set_showUserHub", false);
                store.commit("set_userAccount", common.formatUserName(accountArr[0]));
                store.commit("set_userGameAccount", accountArr[0]);
                return;
              }
              web3Tools.formwalletToKey(accountArr, function (r) {
                common.loginBlockchain(r);
              });
            })
            .catch((e) => {
              console.error(e);
              common.runLoginCallback(e, "error");
            });
        },
        function () {
          hideUserHub();
        }
      );
    };
    // 雲方式登錄
    const conntctWallet_v2 = async function () {
      console.log("--lb", "conntct Wallet v2");
      console.log("--lb", "loginBtnClickTag", loginBtnClickTag.value);
      //外部調用登錄錢包會設置成功回調和失敗回調，如果是手動調用，設置一個默認的成功和失敗回調
      if (!common.loginByWalletSuccessFN) {
        common.loginByWalletSuccessFN = function (r) {
          console.log("--lb", "btn_login call conntctWallet", r);
        };
        common.loginByWalletFailFn = function (e) {
          console.error("--lb btn_login call conntctWallet", e);
          // toastr["error"](e);
        };
      }
      // 如果已有云登錄用戶信息，就直接獲取token，否則先獲取雲登錄用戶，並獲取token
      if (MoralisUser.value && !localStorage.getItem("block-war-loginRes")) {
        console.log("if (MoralisUser.value) moralisTool.getUserTokenByMoralis()");
        moralisTool.getUserTokenByMoralis();
      } else {
        moralisTool.initMoralis(async function () {
          await moralisTool.getMoralisUser(MoralisUser);
        });
      }
    };

    // let wobble = ref(false);

    onMounted(() => {
      // wobble.value = true;
    });
    return {
      isProduction,
      showLoginTag,
      showRegTag,
      showBindToAccountTag,
      showUserBindToWalletTag,
      showResetPWTag,
      hideUserHub,
      conntctWallet,
      conntctWallet_v2,
      UserAccount,
      ui_model,
      h2Txt,
      canLogin_v2,
      // wobble,
      lang,
      switchLang,
    };
  },
});
</script>
