

import axios from "axios";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import common from "../../utils/common";
import ui_btn_2 from '../../components/ui/ui_btn_2.vue'

export default defineComponent({
    name: "msgHub",
    props: {
        loadEvent: Boolean
    },
    components: { ui_btn_2 },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        const unRepeat = (array, key) => {
            var result = [array[0]];
            for (var i = 1; i < array.length; i++) {
                var item = array[i];
                var repeat = false;
                for (var j = 0; j < result.length; j++) {
                    if (item[key] == result[j][key]) {
                        repeat = true;
                        break;
                    }
                }
                if (!repeat) {
                    result.push(item);
                }
            }
            return result;
        }
        //格式化一个消息的内容
        const formatChainMsgContent = (sys_msgs_item) => {
            //普通消息
            if (sys_msgs_item.type != 4) {
                if (sys_msgs_item.content) {
                    let content = sys_msgs_item.content.replace(/\[gold\]/gi, '<i class="token g">');
                    content = content.replace(/\[\\glod]/gi, '</i>');
                    content = content.replace(/\[wat\]/gi, '<i class="token w">');
                    content = content.replace(/\[\\wat]/gi, '</i>');
                    sys_msgs_item.content = content;
                }
            }
            //链上充值提现
            else {
                sys_msgs_item.content = JSON.parse(sys_msgs_item.content);
                // 消息的类型：充值、提现
                if (sys_msgs_item.content.noti_tp == 1) {
                    sys_msgs_item.showTitle = 'RECHARGE';
                    // 进行中、失败、成功
                    if (sys_msgs_item.content.noti.op_status == 0) {
                        sys_msgs_item.showStatus = 'In submission...';
                        sys_msgs_item.showTxt = 'The amount of coins you have topped up:<i class="token g">' + sys_msgs_item.content.noti.gold_count + '</i> has been consumed:<i class="token w">' + (sys_msgs_item.content.noti.wat_count / (Math.pow(10, 18))) + '</i>';
                    }
                    else if (sys_msgs_item.content.noti.op_status == 1) {
                        sys_msgs_item.showStatus = 'Recharge failed !';
                        sys_msgs_item.showTxt = 'The amount of coins you have topped up:<i class="token g">' + sys_msgs_item.content.noti.gold_count + '</i> has been consumed:<i class="token w">' + (sys_msgs_item.content.noti.wat_count / (Math.pow(10, 18))) + '</i> will be returned to the original place.';
                    }
                    else if (sys_msgs_item.content.noti.op_status == 2) {
                        sys_msgs_item.showStatus = 'Congratulations on the successful recharge!';
                        sys_msgs_item.showTxt = 'The amount of coins you have topped up:<i class="token g">' + sys_msgs_item.content.noti.gold_count + '</i> Account has been received!  has been consumed:<i class="token w">' + (sys_msgs_item.content.noti.wat_count / (Math.pow(10, 18))) + '</i>';
                    }
                }
                else if (sys_msgs_item.content.noti_tp == 2) {
                    sys_msgs_item.showTitle = 'WITHDRAW';
                    // 进行中、失败、成功
                    if (sys_msgs_item.content.noti.op_status == 0) {
                        sys_msgs_item.showStatus = 'In submission...';
                        sys_msgs_item.showTxt = 'Amount of Chain Coins you withdraw:<i class="token w">' + (sys_msgs_item.content.noti.wat_count / (Math.pow(10, 18))) + '</i> has been consumed:<i class="token g">' + sys_msgs_item.content.noti.gold_count + '</i>';
                    }
                    else if (sys_msgs_item.content.noti.op_status == 1) {
                        sys_msgs_item.showStatus = 'Withdrawal failed !';
                        sys_msgs_item.showTxt = 'Amount of Chain Coins you withdraw:<i class="token w">' + (sys_msgs_item.content.noti.wat_count / (Math.pow(10, 18))) + '</i> has been consumed:<i class="token g">' + sys_msgs_item.content.noti.gold_count + '</i> will be returned to the original place.';
                    }
                    else if (sys_msgs_item.content.noti.op_status == 2) {
                        sys_msgs_item.showStatus = 'Congratulations on the successful withdrawal !';
                        sys_msgs_item.showTxt = 'Amount of Chain Coins you withdraw:<i class="token w">' + (sys_msgs_item.content.noti.wat_count / (Math.pow(10, 18))) + '</i> Account has been received! has been consumed: <i class="token g">' + sys_msgs_item.content.noti.gold_count + '</i>';
                    }
                }
            }
            return sys_msgs_item;
        }
        // 拉取消息
        let temArray = [];
        // 如果本地缓存有数据，先取本地缓存
        if (localStorage.getItem('msgContainer')) {
            temArray = JSON.parse(localStorage.getItem('msgContainer'));
        }
        let loadMsgListOffset = 0;
        const loadDataList = () => {
            const sendUrl = common.userApi.requestUrl + '/misc_api/system_messages?only_self=false&offset=' + loadMsgListOffset + '&count=50&types=0,4';
            const sendHeader = common.buildHeaders('form');
            axios.get(sendUrl, { headers: sendHeader }).then((r) => {
                // 如果服务器有返回消息列表，附加到列表数组
                if (r.data.code == 0) {
                    // 格式化content
                    let sys_msgs = r.data.sys_msgs;
                    if (sys_msgs && sys_msgs[0]) {
                        for (let i = 0; i < sys_msgs.length; i++) {
                            r.data.sys_msgs[i] = formatChainMsgContent(sys_msgs[i])
                            temArray.push(r.data.sys_msgs[i])
                        }
                    }
                }
                // 去重排序后渲染
                if (temArray[0]) {
                    temArray = unRepeat(temArray, 'seqnum');
                    msgContainer.value = temArray.sort(function (a, b) {
                        return (b.add_time - a.add_time);
                    })
                }
                // 计数未读消息的数量
                if (msgContainer.value[0]) {
                    unReadCount.value = 0;
                    for (let i = 0; i < msgContainer.value.length; i++) {
                        if (msgContainer.value[i].status == 0) {
                            unReadCount.value++;
                        }
                    }
                }
                //写入本地缓存
                console.log('--lb', 'unReadCount.value', unReadCount.value);
                console.log('--lb', 'msgContainer', msgContainer.value);
            })
        }

        let msgContainer = ref([]);


        // 轮询用户事件
        let ST_user_events;
        const user_events = () => {
            console.log('--lb','user_events', new Date())
            const sendUrl = common.userApi.requestUrl + '/misc_api/user_events';
            const sendHeader = common.buildHeaders('form');
            axios.get(sendUrl, { headers: sendHeader, timeout: 60000 }, { timeout: 60000 }).then((r) => {
                // console.log('--lb',r);
                if (r.data.code == 0) {
                    loadDataList();
                }
            })
            ST_user_events = setTimeout(function () {
                user_events();
            }, 60000);
        }

        let showMsgListTag = ref(false);

        const formatTime = function (d) {
            return common.formatDateTime(d);
        }

        // 未读数量
        let unReadCount = ref(0);
        // 显示或关闭列表
        const switchMsgList = () => {
            showMsgListTag.value = !showMsgListTag.value;
            let ajaxCount = 0;
            //显示列表时，全设已读(排除需要领取奖励的消息)，并通知服务器隐藏已读的消息
            if (showMsgListTag.value) {
                console.log('--lb', msgContainer.value);
                for (let i = 0; i < msgContainer.value.length; i++) {
                    if (msgContainer.value[i].status == 0 && msgContainer.value[i].reward.asset_infos.length == 0) {
                        msgContainer.value[i].status = 1;
                        ajaxCount++;
                        const sendUrl = common.userApi.requestUrl + '/misc_api/system_messages/' + msgContainer.value[i].seqnum;
                        const sendHeader = common.buildHeaders('form');
                        const sendData = common.buildSendData({ status: 1 });
                        axios.patch(sendUrl, sendData, { headers: sendHeader }).then((r) => {
                            unReadCount.value--;
                            console.log('--lb', ajaxCount, unReadCount.value);
                        })
                    }
                }
                //如果用设为已读的操作，就通知服务器隐藏所有已读
                if (ajaxCount > 0) {
                    common.delReadedMsg();
                    ajaxCount = 0;
                }
                // 所有消息已读,并存入本地缓存
                if (msgContainer.value && msgContainer.value[0]) {
                    localStorage.setItem('msgContainer', JSON.stringify(msgContainer.value));
                }
            }
        }

        // 领取反馈消息获得的奖励，并设为已读
        const receive = (item) => {
            const sendUrl = common.userApi.requestUrl + '/misc_api/system_messages/' + item.seqnum;
            const sendHeader = common.buildHeaders('form');
            const sendData = common.buildSendData({ status: 2 })
            axios.patch(sendUrl, sendData, { headers: sendHeader }).then((r) => {
                if (r.data.code == 0) {
                    item.status = 2;
                    common.delReadedMsg();
                    if (msgContainer.value && msgContainer.value[0]) {
                        localStorage.setItem('msgContainer', JSON.stringify(msgContainer.value));
                    }
                }
            })
        }

        let lang = computed(() => {
            return store.state.lang;
        });

        onMounted(() => {
            // if(props.loadEvent){
                user_events();
                loadDataList();
            // }
        });
        onUnmounted(() => {
            clearTimeout(ST_user_events);
        });

        return { msgContainer, unReadCount, showMsgListTag, switchMsgList, formatTime, lang, receive };
    },
});