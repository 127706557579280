export default {
    namespaced: true,
    state: {
        uid: null,
        token: null,
        loginInfo: null,
        showTabIndex: null,
        walletAddress: null
    },
    actions: {},
    mutations: {
        set_uid(state, payload) {
            state.uid = payload;
        },
        set_token(state, payload) {
            state.token = payload;
        },
        set_loginInfo(state, payload) {
            state.loginInfo = payload;
        },
        set_showTabIndex(state, payload) {
            state.showTabIndex = payload;
        },
        set_walletAddress(state, payload) {
            state.walletAddress = payload;
        },
    },
}
