<template>
  <div class="template frame" :data-num="loadedNum" :data-imgcount="props.imgCount">
    <div class="first" v-show="loadedNum < props.imgCount">
      <img
        :src="
          props.imgSrc
            ? props.imgSrc + '1.png?v=' + t
            : cdnUrl + '/web_assets/img/angel/angel_1.png?v=1688652498110'
        "
      />
    </div>
    <div
      v-show="loadedNum == props.imgCount"
      class="limit"
      :style="'width:' + props.imgWidth + ';height:' + props.imgHeight"
    >
      <div
        :class="{
          load: loadedNum < props.imgCount,
          animate: loadedNum == props.imgCount,
        }"
        :style="
          'animation-duration:' +
          props.duration +
          's;animation-timing-function:steps(' +
          props.imgCount +
          ',end);width:calc( ' +
          props.imgWidth +
          ' * ' +
          props.imgCount +
          ' )'
        "
      >
        <div
          class="imgbox"
          v-for="(item, index) in props.imgCount"
          :key="index"
          :style="
            'overflow:hidden;width:' + props.imgWidth + ';height:' + props.imgHeight
          "
        >
          <img
            :style="'width:' + props.imgWidth + ';height:' + props.imgHeight"
            :src="
              props.imgSrc
                ? props.imgSrc + (index + 1) + '.png?v=' + t
                : cdnUrl +
                  '/web_assets/img/angel/angel_' +
                  (index + 1) +
                  '.png?v=1688652498110'
            "
            @load="imgload"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
let store = useStore();
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});
let props = defineProps({
  duration: {
    type: Number,
    default: 2,
  },
  imgSrc: {
    type: String,
    default: "//cdn.blockwar.io/web_assets/img/angel/angel_",
  },
  imgCount: {
    type: Number,
    default: 30,
  },
  imgWidth: {
    type: String,
    default: "100px",
  },
  imgHeight: {
    type: String,
    default: "100px",
  },
});

const t = new Date().getTime();

//图片已加载数量
let loadedNum = ref(1);
const imgload = () => {
  if (loadedNum.value < props.imgCount) {
    loadedNum.value++;
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.limit {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.load {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  opacity: 0;
}
.animate {
  display: flex;
  width: 100px;
  flex-wrap: nowrap;
  height: 100%;
  animation: infiniteplay 2s steps(30, end) infinite;
}

@keyframes infiniteplay {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
</style>
