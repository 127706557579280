<template>
  <div class="bind_to_account">
    <div class="content">
      <h1 class="mortina">bind account</h1>
      <p>{{ h2Txt }}</p>
      <div class="form">
        <!-- <h2>{{ walletAccount }}</h2> -->
        <ui_input v-model:val="formVal.telmail" :placeholder="'E-MAIL'" :err="formVal.telmail && checkErr.check_te_err"
          :errtxt="errTxt.tmErrTxt" @keyup.enter="login"></ui_input>
        <ui_input :type="'password'" v-model:val="formVal.pw" :placeholder="'PASSWORD'"
          :err="formVal.pw && checkErr.check_pw_err" :errtxt="errTxt.pwErrTxt" @keyup.enter="login"></ui_input>
        <ui_btn_1 :width="'100%'" :fontsize="'0.18rem'" :disclick="!canLoginTag" @click="login">login</ui_btn_1>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bind_to_account {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 4.5rem;
    min-height: 5.37rem;
    background: #1b203f;
    box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.35);
    border-radius: 30px;
    padding: 0.6rem 0.4rem;
    box-sizing: border-box;
    color: #fff;

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 0.32rem;
      line-height: 0.42rem;
      margin-bottom: 0.2rem;
    }

    h2 {
      font-size: 0.2rem;
      word-break: break-all;
      word-wrap: break-word;
      padding: 0.2rem;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 0.2rem;
      color: #666;
      margin-top: 0;
      margin-bottom: 0.3rem;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #bdbdbd;
      margin: 0;
      margin-bottom: 0.3rem;
    }
  }
}
</style>
<script>
import { defineComponent, reactive, computed, ref } from "vue";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import ui_btn_1 from "../ui/ui_btn_1.vue";
import ui_input from "../ui/ui_input.vue";
import common from "../../utils/common";
import web3Tools from "../../utils/web3Tools";

export default defineComponent({
  name: "userBind_walletToAccount",
  props: {},
  components: { ui_btn_1, ui_input },
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    let walletAccount = ref(null);
    web3Tools.getWalletAccount().then((r) => {
      console.log('--lb',r);
      walletAccount.value = r[0];
    });

    let h2Txt = common.msg.bindAgameAccout;
    let formVal = reactive(common.form.loginVal);
    let errTxt = reactive(common.form.loginErrTxt);
    let checkErr = reactive({
      check_te_err: computed(() => {
        return common.form.loginCheckErr.check_te_err(formVal.telmail);
      }),
      check_t_err: computed(() => {
        return common.form.loginCheckErr.check_t_err(formVal.telmail);
      }),
      check_pw_err: computed(() => {
        return common.form.loginCheckErr.check_pw_err(formVal.pw);
      }),
      check_vc_err: computed(() => {
        return (
          formVal.verify_code &&
          !common.form.reg.specialCharREG.test(formVal.verify_code)
        );
      }),
    });
    let loginType = ref(2); //登录类型, 1为短信验证码登录, 2为密码登录。

    let canLoginTag = computed(() => {
      return (
        formVal.telmail &&
        formVal.pw &&
        !checkErr.check_te_err &&
        !checkErr.check_pw_err
      );
    });

    const login = async function () {
      console.log('--lb',walletAccount);
      if (canLoginTag.value) {
        common.loginByUser(
          formVal.telmail,
          formVal.pw,
          loginType.value,
          function () {
            //登录完成后，执行绑定该账号到钱包的操作
            //取钱包的publickey，换取key后登录
            web3Tools.formwalletToKey([walletAccount.value], function (r) {
              //用解出来的key去绑定钱包
              store.commit("userHubStore/set_ui_model", 1);
              common.bindAccountToWallet(walletAccount.value, r, 0);
            });
          }
        );
      }
    };

    return {
      formVal,
      errTxt,
      checkErr,
      canLoginTag,
      login,
      walletAccount,
      h2Txt,
    };
  },
});
</script>
