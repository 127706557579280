<template>
  <div class="currency">
    <div class="layout">
      <div class="t">
        <div class="limit">
          <div class="l">
            <h2 class="mortina h2">{{ langTxt[lang].index.currency[0] }}</h2>
            <p>
              {{ langTxt[lang].index.currency[1] }}
            </p>
            <div class="hr"></div>
          </div>
          <div class="r">
            <img :src="cdnUrl + '/web_assets/img/currency_t_right.png'" alt="" />
          </div>
        </div>
      </div>
      <div class="m">
        <h2 class="mortina">{{ langTxt[lang].index.currency[2] }}</h2>
        <p>
          {{ langTxt[lang].index.currency[3] }}<br />
          {{ langTxt[lang].index.currency[4] }}
        </p>
        <ul class="currency_list">
          <li class="mortina">
            <img :src="cdnUrl + '/web_assets/img/currency_ico_1.png'" alt="" />
            <div>TIMBER</div>
          </li>
          <li class="mortina">
            <img :src="cdnUrl + '/web_assets/img/currency_ico_2.png'" alt="" />
            <div>ORE</div>
          </li>
          <li class="mortina">
            <img :src="cdnUrl + '/web_assets/img/currency_ico_3.png'" alt="" />
            <div>GOLD</div>
          </li>
          <li class="mortina">
            <img :src="cdnUrl + '/web_assets/img/currency_ico_4.png'" alt="" />
            <div>FOOD</div>
          </li>
        </ul>
      </div>
      <div class="b">
        <span class="box">
          <img
            :src="cdnUrl + '/web_assets/img/currency2_role1.png'"
            alt=""
            class="role1"
            :style="{
              transitionDuration: transition1.duration + 's',
              left: transition1.left + 'rem',
              transform: 'scale(' + transition1.scale + ')',
            }"
          />
          <img class="bg" :src="cdnUrl + '/web_assets/img/currency2_bg.png'" alt="" />
          <img
            :src="cdnUrl + '/web_assets/img/currency2_role2.png'"
            alt=""
            class="role2"
            :style="{
              transitionDuration: transition2.duration + 's',
              right: transition2.right + 'rem',
              transform: 'scale(' + transition2.scale + ')',
            }"
          />
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./currency.scss"></style>
<script src="./currency.js"></script>
