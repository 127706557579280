<template>
    <span class="icon_back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L7.92893 12.3431L14.2929 5.97918C14.6834 5.58866 15.3166 5.58866 15.7071 5.97919C16.0976 6.36971 16.0976 7.00287 15.7071 7.3934L10.7574 12.3431L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071Z"
                fill="white" />
        </svg>
    </span>
</template>
<style lang="scss" scoped>
.icon_back{
    width: 0.24rem;
    height: 0.24rem;
    cursor: pointer;
    display: inline-block;
    position: relative;
    svg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>