
import msg from "./msg";
const reg = {
    // tREG: /^(0|+|86)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/, //国内手机号正则
    tREG: /^([0|\+]\d{1,4}-?)?\d{7,11}$/, //国内与国际手机号
    eREG: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/, //email正则
    specialCharREG: /^\w+$/, //特殊字符正则
};
const loginErrTxt = {
    tmErrTxt: msg.inputTelMailErr,
    tErrTxt: msg.inputTelErr,
    pwErrTxt: '',
    vcErrTxt: msg.verifycodeErrtxt
}
export default {
    reg,
    loginVal: {
        telmail: '',
        pw: '',
        verify_code: '',
    },
    loginErrTxt,
    loginCheckErr: {
        check_te_err: (v) => {
            // const res = v && !(((reg.eREG.test(v) && v.length < 31)));
            const res = v && !((reg.tREG.test(v) || (reg.eREG.test(v) && v.length < 31)));
            return res;
        },
        check_t_err: (v) => {
            return v && !(reg.tREG.test(v));
        },
        check_pw_err: function (v) {
            if (v && (v.length > 12 || v.length < 6)) {
                loginErrTxt.pwErrTxt = msg.pwLentErrTxt;
                return true;
            }
            // else if (v && !reg.specialCharREG.test(v)) {
            //     loginErrTxt.pwErrTxt = msg.specialErrTxt;
            //     return true
            // }
            else {
                return false;
            }
        },
        check_vc_err: function (v) {
            return v && !(this.reg.specialCharREG.test(v));
        }
    },
}