import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import common from "../../utils/common";

import icon_newgame from "../ui/icon/icon_newgame.vue";

export default defineComponent({
    name: "currency2",
    props: {},
    components: {icon_newgame},
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        let cdnUrl = computed(() => {
            return store.state.cdnUrl;
        });
        let transition1 = reactive({
            duration: 0,
            left: 0.1,
            scale: 1,
        });
        let transition2 = reactive({
            duration: 0,
            scale: 1,
            right: -0.8
        });

        const setRunData = (d) => {
            let timeout = common.randomNum(900, 1000);
            d.duration = timeout / 1000;
            d.scale = common.randomNum(95, 100) / 100;
            const leftRight = common.randomNum(4, 7) / 10;
            if (d.left) {
                d.left = leftRight;
            } else {
                d.right = leftRight;
            }
            setTimeout(function () {
                setRunData(d);
            }, timeout);
        };
        // setRunData(transition1);
        // setRunData(transition2);

        let lang = computed(() => {
            return store.state.lang;
        });
        let langTxt = store.state.text;

        return { langTxt, lang,icon_newgame, transition1, transition2,cdnUrl };
    },
});