<template>
  <div class="user_bind_wallet disselect">
    <div class="contain">
      <div class="title">
        <i class="info">
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
            <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
            <circle cx="13.5" cy="7.5" r="1.5" fill="white" /></svg
        ></i>
        Bind account to a wallet
      </div>
      <div class="txt">
        <p>Please confirm the bound wallet account</p>
        <div class="accout">{{ walletAccount }}</div>
      </div>

      <div class="btn">
        <span @click="cancel">Cancel</span>
        <span @click="confirm" class="confirm">OK</span>
      </div>
      <span class="close" @click="cancel"></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.user_bind_wallet {
  position: fixed;
  z-index: 8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .contain {
    width: 4.5rem;
    min-height: 2.27rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.3rem 0.3rem 0.2rem 0.3rem;
    position: relative;
    .title {
      font-weight: bold;
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      color: #333;
      margin-bottom: 0.1rem;
      i {
        margin-right: 0.1rem;
        svg {
          widows: 0.27rem;
          height: 0.27rem;
          vertical-align: middle;
        }
      }
    }
    .txt {
      font-size: 0.18rem;
      color: #5c5c5c;
      font-weight: 500;
      line-height: 0.25rem;
      margin-left: 0.37rem;
      min-height: 0.76rem;
      margin-bottom: 0.17rem;
      .accout {
        padding: 0.1rem;
        font-weight: 400;
        font-size: 0.18rem;
        line-height: 0.22rem;
        text-align: center;
        text-transform: capitalize;
        color: #4f4f4f;
        border: 1px solid #bdbdbd;
        border-radius: 0.1rem;
      }
    }
    .btn {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        display: inline-block;
        width: 0.86rem;
        height: 0.38rem;
        background-color: #bdbdbd;
        border-radius: 5px;
        font-size: 0.18rem;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #828282;
        }
        &.confirm {
          background: var(--linearGradientRed);
          margin-left: 0.2rem;
          color: #fff;
          &:hover {
            background: var(--linearGradientRedHover);
          }
        }
      }
    }
  }
}
.close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
  background: url("https://cdn.blockwar.io/web_assets/img/ico_close.png") no-repeat center
    center / 100% auto;
  &:hover {
    background-image: url("https://cdn.blockwar.io/web_assets/img/ico_close_hover.png");
  }
}
</style>
<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common";
// import { useRouter } from "vue-router";
import web3Tools from "../../utils/web3Tools";

export default defineComponent({
  name: "user_bind_wallet",
  props: {},
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    let walletAccount = ref(null);
    let fullAccount;
    web3Tools.connectWeb3(function () {
      web3Tools.getWalletAccount().then((r) => {
        console.log("--lb", r);
        fullAccount = r[0];
        walletAccount.value = common.formatUserName(r[0], 12);
      });
    });

    const confirm = () => {
      //判断是否已登录
      if (store.state.userInfo && store.state.userInfo.uid) {
        hideThisUI();
        //取钱包的publickey，换取key后登录
        web3Tools.formwalletToKey([fullAccount], function (r) {
          //用解出来的key去绑定钱包
          store.commit("userHubStore/set_ui_model", 1);
          common.bindAccountToWallet(fullAccount, r, 0);
        });
      } else {
        store.commit("userHubStore/set_showUserHub", false);
        common.bindWalletFailFn("uid is null", "error");
      }
    };
    const cancel = () => {
      hideThisUI();
    };
    const hideThisUI = () => {
      store.commit("userHubStore/set_showUserHub", false);
    };
    return { confirm, cancel, hideThisUI, walletAccount };
  },
});
</script>
