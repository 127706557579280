<style lang="scss" scoped>
.game_enter.template {
  max-width: 19.2rem;
  margin: 0 auto;
  background-color: #19203e;
  position: relative;

  .layout {
    width: 13.97rem;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;

    .arrow {
      padding-right: 1rem;
      height: 0.7rem;
      display: flex;
      justify-content: right;
      align-items: center;

      span {
        width: 0.6rem;
        height: 0.3rem;
        border: 1px solid #38366c;
        border-radius: 0.05rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &.right {
          transform: rotate(180deg);
          margin-left: 0.2rem;
        }

        &.on {
          cursor: pointer;
          background-color: rgba(239, 79, 85, 0.9);
          border-color: rgba(239, 79, 85, 0.9);

          :deep(.icon_back) {
            cursor: pointer;

            svg {
              path {
                fill: #fff;
              }
            }
          }
        }

        .icon_back {
          width: 0.24rem;
          height: 0.24rem;
          border: 0;
          cursor: default;
        }

        :deep(.icon_back) {
          svg {
            width: 0.24rem;
            height: 0.24rem;

            path {
              fill: #38366c;
            }
          }
        }
      }
    }

    .box {
      width: 100%;
      height: auto;
      overflow-x: hidden;
      position: relative;

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 1rem;
        height: 100%;
        top: 0;
        right: 0;
        background: linear-gradient(90deg, rgba(26, 32, 63, 0.4) 0%, #1a203f 80%);
        z-index: 1;
      }

      &::before {
        transform: rotate(180deg);
        right: auto;
        left: 0;
      }
    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      margin-left: 1rem;
      position: relative;
      transition: all 0.5s;

      li {
        width: 4.19rem;
        height: 1.76rem;
        box-sizing: border-box;
        background: #1b203f url("https://cdn.blockwar.io/web_assets/img/game_enter/game_enter_bg.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 0.1rem;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 0.2rem;
        position: relative;

        .new {
          position: absolute;
          top: 0;
          right: 0;
        }

        &.low {
          background-image: url(../../assets/img/game_enter/bg_low.png);
        }

        &.high {
          background-image: url(../../assets/img/game_enter/bg_height.png);
        }

        &.petrun {
          background-image: url(../../assets/img/game_enter/bg_petrun.png);
        }

        &.dice {
          background-image: url(../../assets/img/game_enter/bg_dice.png);
        }

        &:last-child {
          margin: 0;
        }

        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          width: 1.3rem;
          height: 1.3rem;
          margin-right: 0.2rem;
          background: linear-gradient(177.65deg,
              rgba(53, 89, 202, 0) 43.45%,
              rgba(0, 18, 77, 0.96) 98.03%);
          border-radius: 0.2rem;

          img {
            width: 100%;
            height: 100%;
            opacity: 0.5;
          }
        }

        .content {
          width: 2.22rem;
          min-height: 1.3rem;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h2 {
            font-size: 0.24rem;
            color: #fff;
            margin: 0;
          }

          .btn {
            display: flex;
            justify-content: right;

            a {
              color: #fff;
              text-decoration: none;
            }

            span {
              cursor: pointer;
              width: 1.66rem;
              height: 0.48rem;
              border: 1px solid rgba(255, 255, 255, 0.5);
              color: rgba(255, 255, 255, 0.8);
              border-radius: 0.05rem;
              font-size: 0.18rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "mortina";
              text-transform: capitalize;
            }
          }
        }

        &:hover,
        &.on {
          .img {
            img {
              opacity: 1;
            }
          }

          .content {
            .btn {
              span {
                background-color: #ef4f55;
                border: 0;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .arrowmove {
    padding: 0.2rem;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    bottom: -0.72rem;
    left: 50%;
    margin-left: -0.31rem;
    z-index: 1;
    animation: arrowmove 10s linear infinite;

    svg {
      width: 0.22rem;
      height: auto;
    }
  }

  .hashgame {
    margin-left: 1rem;

    .layout {
      height: auto;
    }

    h3 {
      color: #C8C8DC;
      font-size: .24rem;
      margin: 0 0 .1rem 0;
    }

    .container {
      position: relative;
      width: 4.19rem;
      height: 1.76rem;
      border-radius: 10px;
      border: 1px solid #38366C;
      background: #1B203F;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(../../assets/img/bg_hasgame.png) no-repeat top right;
      background-size: auto 100%;

      .new {
        position: absolute;
        top: 0;
        right: 0;
      }

      &:hover {
        .btn {
          span {
            background-color: #ef4f55;
            border: 0;
            color: #fff;
          }
        }
      }

      .box_left {
        padding: .24rem;
        width: 1.3rem;
        height: 1.3rem;
        flex-shrink: 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .box_right {
        width: 2.2rem;
        flex-shrink: 0;
        padding: .2rem;
        padding-left: 0;
        color: #fff;

        h4 {
          margin: .25rem 0 0 0;
          font-size: .24rem;
        }

        p {
          min-height:
            .25rem;
        }

        .btn {
          display: flex;
          justify-content: right;

          a {
            color: #fff;
            text-decoration: none;
          }

          span {
            cursor: pointer;
            width: 1.66rem;
            height: 0.48rem;
            border: 1px solid rgba(255, 255, 255, 0.5);
            color: rgba(255, 255, 255, 0.8);
            border-radius: 0.05rem;
            font-size: 0.18rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "mortina";
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@keyframes arrowmove {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  10% {
    transform: translateY(100%);
    opacity: 0;
  }

  11% {
    transform: translateY(0);
    opacity: 0;
  }

  12% {
    transform: translateY(0);
    opacity: 1;
  }

  22% {
    transform: translateY(100%);
    opacity: 0;
  }

  23% {
    transform: translateY(0);
    opacity: 0;
  }

  24% {
    transform: translateY(0);
    opacity: 1;
  }

  34% {
    transform: translateY(100%);
    opacity: 0;
  }

  35% {
    transform: translateY(0);
    opacity: 0;
  }

  36% {
    transform: translateY(0);
    opacity: 1;
  }

  46% {
    transform: translateY(100%);
    opacity: 0;
  }

  47% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
<template>
  <div class="game_enter template">
    <div class="layout">
      <div class="arrow">
        <span :class="{ left: true, on: ulPosition != 0 }" @click="ulPosition != 0 ? ulPosition-- : (ulPosition = 0)">
          <icon_back />
        </span>
        <span :class="{ right: true, on: ulPosition < gameLen - 2 }"
          @click="ulPosition < gameLen - 2 ? ulPosition++ : (ulPosition = gameLen - 2)">
          <icon_back />
        </span>
      </div>
      <div class="box">
        <ul :style="'left:-' + ulPosition * (4.19 + 0.2) + 'rem'" class="disselect">
          <li class="high">
            <div class="img">
              <img :src="cdnUrl + '/web_assets/img/game_enter/logo_redflame_high.png'" alt="" />
            </div>
            <div class="content">
              <h2>RED FLAME</h2>
              <p>{{ lang == "en" ? "High roller (High)" : "高倍場 (High)" }}</p>
              <div class="btn">
                <router-link to="/blockwar?game_id=10002"><span>{{
                  lang == "en" ? "Enter game" : "進入遊戲"
                }}</span></router-link>
              </div>
            </div>
          </li>
          <li class="low">
            <div class="img">
              <img :src="cdnUrl + '/web_assets/img/game_enter/logo_redflame_low.png'" alt="" />
            </div>
            <div class="content">
              <h2>RED FLAME</h2>
              <p>{{ lang == "en" ? "Easy going (Low)" : "低倍場 (Low)" }}</p>
              <div class="btn">
                <router-link to="/blockwar?game_id=10001"><span>{{
                  lang == "en" ? "Enter game" : "進入遊戲"
                }}</span></router-link>
              </div>
            </div>
          </li>
          <li class="dice">
            <div class="img">
              <img :src="cdnUrl + '/web_assets/img/game_enter/logo_dice.png'" alt="" />
            </div>
            <div class="content">
              <h2>DICE</h2>
              <div class="btn">
                <router-link to="/dice"><span>{{
                  lang == "en" ? "Enter game" : "進入遊戲"
                }}</span></router-link>
              </div>
            </div>
            <icon_newgame class="new" />
          </li>
          <li class="petrun">
            <div class="img">
              <img :src="cdnUrl + '/web_assets/img/game_enter/logo_pet_run.png'" alt="" />
            </div>
            <div class="content">
              <h2>PET RUN</h2>
              <div class="btn">
                <router-link to="/blockwar?game_id=10003"><span>{{
                  lang == "en" ? "Enter game" : "進入遊戲"
                }}</span></router-link>
              </div>
            </div>
            <icon_newgame class="new" />
          </li>
          <li class="high">
            <div class="img">
              <img :src="cdnUrl + '/web_assets/img/game_enter/logo_redflame_high_onchain.png'
                " alt="" />
            </div>
            <div class="content">
              <h2>RED FLAME</h2>
              <p>
                {{
                  lang == "en"
                  ? "High roller (High), On-chain"
                  : "高倍場 (High)，區塊鏈遊戲"
                }}
              </p>
              <div class="btn">
                <router-link to="/blockwar?game_id=10011"><span>{{
                  lang == "en" ? "Enter game" : "進入遊戲"
                }}</span></router-link>
              </div>
            </div>
          </li>
          <li class="low">
            <div class="img">
              <img :src="cdnUrl + '/web_assets/img/game_enter/logo_redflame_low_onchain.png'" alt="" />
            </div>
            <div class="content">
              <h2>RED FLAME</h2>
              <p>
                {{
                  lang == "en" ? "Easy going (Low), On-chain" : "低倍場 (Low)，區塊鏈遊戲"
                }}
              </p>
              <div class="btn">
                <router-link to="/blockwar?game_id=10012"><span>{{
                  lang == "en" ? "Enter game" : "進入遊戲"
                }}</span></router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="arrowmove" @click="arrowDown">
      <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9583 8.54167L3.41667 0L0.5 3.125L11.9583 14.5833L23.4167 3.125L20.5 0L11.9583 8.54167ZM11.9583 21.25L3.41667 12.7083L0.5 15.625L11.9583 27.0833L23.4167 15.625L20.5 12.7083L11.9583 21.25Z"
          fill="white" fill-opacity="0.1" />
      </svg>
    </div>

    <div class="layout">
      <div class="hashgame">
        <h3>Hash Game</h3>
        <div class="container">
          <div class="box_left">
            <img src="../../assets/img/ico_hashgame.png" alt="">
          </div>
          <div class="box_right">
            <h4>Guessing</h4>
            <p></p>
            <div class="btn">
              <router-link to="/hashgame"><span>{{
                lang == "en" ? "Enter game" : "進入遊戲"
              }}</span></router-link>
            </div>
          </div>
          <icon_newgame class="new" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import icon_back from "@/components/ui/icon/icon_back.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import common from "@/utils/common.js";
import icon_newgame from "../ui/icon/icon_newgame.vue";

let store = useStore();
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});

let lang = computed(() => {
  return store.state.lang;
});

// 游戏列表位移步数
let gameLen = 5;
let ulPosition = ref(0);
watch(ulPosition, (newVal, oldVal) => {
  console.log(newVal);
});

const arrowDown = () => {
  window.scrollTo(0, document.body.offsetTop + 300);
};
</script>
