<template>
  <div class="ui_input">
    <input
      :style="{ width: width }"
      :type="dataType"
      :data-type="type"
      :value="val"
      :placeholder="placeholder"
      @blur="updateInputVal"
      @keyup.enter="updateInputVal"
      @focus="inputAndFoucs"
      @input="inputAndFoucs"
      :class="{ err: err && !focusTag }"
    />
    <div class="errtxt" v-show="val && !focusTag && err">{{ errtxt }}</div>
    <i class="err" v-show="val && !focusTag && err"
      ><svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
          fill="#EA3B42"
        />
        <path d="M5 9H15V11.5H5V9Z" fill="white" />
      </svg>
    </i>
    <i class="right" v-show="val && !err && !focusTag"
      ><svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
          fill="#37AB00"
        />
        <path
          d="M15.0963 5.16138L16.7743 6.80826L8.59204 14.8388L3.87109 10.2054L5.55058 8.55852L8.59352 11.545L15.0963 5.16138V5.16138Z"
          fill="white"
        />
      </svg>
    </i>
    <div class="ico" v-if="type == 'password'" @click="swithIco">
      <span class="hide" v-show="dataType != 'password'"
        ><img src="https://cdn.blockwar.io/web_assets/img/ico_hide.png" alt=""
      /></span>
      <span class="show" v-show="dataType == 'password'"
        ><img src="https://cdn.blockwar.io/web_assets/img/ico_show.png" alt=""
      /></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ui_input {
  position: relative;
  width: 100%;
  i {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    top: 0.14rem;
    right: 0.2rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .errtxt {
    text-align: left;
    font-size: 0.16rem;
    line-height: 1;
    color: #ea3b42 !important;
    margin-top: -0.1rem;
    margin-bottom: 0.24rem;
  }
  .ico {
    position: absolute;
    width: 0.24rem;
    height: 0.24rem;
    top: 50%;
    margin-top: -0.12rem;
    right: 0.2rem;
    cursor: pointer;
    img,
    span {
      width: 0.24rem;
      height: 0.24rem;
      display: inline-block;
      margin: 0;
    }
    span:hover {
      transform: scale(1.2);
    }
  }
}
input {
  display: block;
  outline: none;
  width: 5.64rem;
  border: 1px solid #34395b;
  background-color: #34395b;
  border-radius: 0.1rem;
  padding: 0.13rem 0.2rem;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.22rem;
  color: #fff;
  margin-bottom: 0.2rem;
  box-sizing: border-box;

  &:hover {
    border-color: #828282;
  }

  &:focus {
    background: #060a25;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  &::placeholder {
    color: #999;
  }

  &.err {
    border: 1px solid #ea3b42;
  }

  &.vc {
    width: 1.55rem;
  }
}
input[data-type="password"] {
  padding-right: 0.64rem;
}
</style>
<script>
import { defineComponent, ref } from "vue";
import common from "../../utils/common";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_input",
  props: {
    width: {
      default: "100%",
    },
    type: {
      default: "text",
    },
    placeholder: {
      default: "",
    },
    val: {
      required: true,
    },
    err: {
      default: false,
    },
    errtxt: {
      default: "",
    },
  },
  emits: ["update:val"],
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    let dataType = ref("password");
    dataType.value = props.type;
    console.log(props.type);
    let focusTag = ref(false);
    const updateInputVal = ($event) => {
      focusTag.value = false;
      ctx.emit("update:val", common.stringTrim($event.target.value));
    };
    const inputAndFoucs = () => {
      focusTag.value = true;
      // ctx.emit("update:val", "");
    };
    const swithIco = () => {
      if (dataType.value == "password") {
        dataType.value = "text";
      } else {
        dataType.value = "password";
      }
    };
    return { updateInputVal, inputAndFoucs, focusTag, dataType, swithIco };
  },
});
</script>
