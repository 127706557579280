<template>
    <span class="icon_close">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.3066 0.628998L11.6777 7.25793L5.04874 0.628998L0.630492 5.04724L7.25942 11.6746L0.630492 18.3051L5.04717 22.7249L11.6761 16.096L11.681 16.1009L16.1016 11.6803L16.0975 11.6762L22.7248 5.04724L18.3066 0.628998ZM17.2616 12.8403L12.8414 17.2605L18.3082 22.7233L22.7264 18.3051L17.2616 12.8403Z"
                fill="white" />
        </svg>

    </span>
</template>
<style lang="scss" scoped>
.icon_close{
    display: inline-block;
    width: 0.3rem;
    height: 0.3rem;
    cursor: pointer;
    padding: 0.05rem;
    border-radius: 0.1rem;
    cursor: pointer;
    img,svg{
        width: 100%;
        height: 100%;
    }
    &:hover{
        background-color: #565A70;
        border-radius: 0.1rem;
    }
    }
</style>